import { Control, Controller } from "react-hook-form";

import Input from "@/components/Atoms/InputsV2/EmailInput";
import ErrorUI from "@/components/Atoms/InputsV2/ErrorUI";

import { classNameGenerator } from "@/utils";

import styles from "./EmailInput.module.scss";

interface EmailInputProps {
  name: string;
  control: Control<any>;
  placeholder?: string;
  className?: string;
  onBlur?: (e: any) => void;
  setFormData?: any;
  formData?: any;
  removeAppliedPromoCode?: (e: any) => void
}

export const isValidEmail = (value) =>
  value && value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

const EmailInput: React.FC<EmailInputProps> = ({
  name,
  control,
  placeholder = "Enter your E-mail address",
  onBlur = () => {},
  setFormData = () => {},
  formData = {},
  removeAppliedPromoCode = () => {},
  ...rest
}) => {
  const cls = classNameGenerator(styles);

  return (
    <div className={cls("emailInput")}>
      <Controller
        name={name}
        control={control}
        shouldUnregister
        render={({
          field: { value, onBlur: handleBlur, onChange, ref },
          fieldState: { error },
        }) => (
          <>
            <Input
              ref={ref}
              placeholder={placeholder}
              name={name}
              value={value || formData?.email}
              onChange={(e) => {
                onChange(e)
                setFormData((prev) => {
                  return {...prev,[name]:e.target.value}
                })
                removeAppliedPromoCode(false)
              }}
              onBlur={(e) => {
                handleBlur();
                onBlur(e);
                removeAppliedPromoCode(false)
              }}
              {...rest}
            />
            <ErrorUI error={error?.message} />
          </>
        )}
      />
    </div>
  );
};

export default EmailInput;
