import styled from "styled-components";
import { space } from "styled-system";
import Typography from "../../../../foundation/Typography/V2";
import { Box, FlexDiv } from "../../Box";
import SVGSprite from "../../SVGSprite";

export const SearchIconV3 = styled(SVGSprite)`
  transition: all 0.2s ease-in-out;
  fill: ${({ iconFill }) => iconFill};
  pointer-events: none;
`;
export const InputWrapperV3 = styled(Box)`
  width: 100%;
  margin-bottom: 0;

  ${SearchIconV3} {
    z-index: 4;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }
`;
export const InputV3 = styled.input`
  ${space}
  padding: ${({ inputPadding }) => inputPadding};
  outline: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ inputBorderRadius }) => inputBorderRadius};
  border: ${({ theme, error }) =>
    `1px solid ${
      error ? theme.colorsV2.error.shade_300 : theme.colorsV2.gray.shade_300
    }`};
  transition: all 0.2s ease-in-out;
  width: 100%;
  color: ${({ inputColor }) => inputColor};
  font-size: ${({ inputFontSize }) => inputFontSize};
  line-height: ${({ inputLineHeight }) => inputLineHeight};
  ${({ focusable, error, theme }) =>
    focusable &&
    `
    &:focus{
      border-color: ${
        error
          ? theme.colorsV2.error.shade_300
          : theme.colorsV2.primary.shade_300
      };
      box-shadow: 0px 1px 2px rgba(${
        theme.colors.shadows.gray
      }, 0.05), 0px 0px 0px 4px ${
      error ? theme.colorsV2.error.shade_100 : theme.colorsV2.primary.shade_100
    };
    }
  `};
  ${({ isDropdown }) =>
    isDropdown &&
    `
  position: relative;
  z-index:3;
  `};
  font-family: ${({ fontFamily }) => fontFamily};
  &::placeholder {
    font-family: var(--man-500);
    color: ${({ theme }) => theme.colorsV2.gray.shade_500} !important;
  }
`;
export const StyledSpanV3 = styled(Typography)``;
export const DropdownWrapper = styled(FlexDiv)`
  position: ${({ isNotAbsolute }) => (isNotAbsolute ? "static" : "absolute")};
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.Border_1};
  border-radius: 6px;
  box-shadow: 2px 4px 4px
    rgba(${({ theme }) => theme.colors.shadows.typeDropdown}, 0.25);
  padding: 23px 0px 0px 0px;
  z-index: 1;
  top: 25px;
  cursor: pointer;
  overflow: hidden auto;
  max-height: 225px;
  ${StyledSpanV3} {
    padding: 0 12px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.Left_Panel_Bg};
    }
  }
`;
export const ErrorTypography = styled(Typography)`
  position: ${({ isNotAbsolute }) => (isNotAbsolute ? "static" : "absolute")};
  top: calc(100% + 4px);
  left: 0px;
  color: ${({ theme }) => theme.colorsV2.error.shade_500};
`;
export const ErrorIconV3 = styled(SVGSprite)`
  fill: ${({ theme }) => theme.colorsV2.error.shade_500};
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
`;
