import React from "react";
import styled from "styled-components";
import theme from "../../../foundation/Theme";
import Typography from "../../../foundation/Typography/V2";
import { FlexDiv, Label } from "../Box";

function CheckBox(props) {
  const {
    labelText,
    variant = "label2",
    m,
    id,
    color,
    top,
    left,
    isExtraBold = false,
    subtitleVariant = "paragraph3",
    subTitleColor,
    handleCheckClick = () => {},
    subtitleLabel,
    checkTopPos,
    checkLeftPos,
    isChecked,
    checkBoxColor,
    ...rest
  } = props || {};
  return (
    <Wrapper
      m={m}
      flex={1}
      topPos={top}
      leftPos={left}
      checkTopPos={checkTopPos}
      checkLeftPos={checkLeftPos}
      checkBoxColor={checkBoxColor}
    >
      <CheckBoxComponent
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={handleCheckClick}
        {...rest}
      />
      <TextLabel htmlFor={id}>
        <Typography
          color={color || theme.colorsV2.gray.shade_700}
          variant={variant}
          isExtraBold={isExtraBold}
        >
          {labelText}
        </Typography>
        {subtitleLabel && (
          <Typography
            variant={subtitleVariant}
            color={subTitleColor || theme.colorsV2.gray.shade_500}
          >
            {subtitleLabel}
          </Typography>
        )}
      </TextLabel>
    </Wrapper>
  );
}

export default CheckBox;

export const CheckBoxComponent = styled.input``;
const TextLabel = styled(Label)`
  padding-left: 32px;
  cursor: pointer;
  user-select: none;
  flex: 1;
  position: relative;
`;
const Wrapper = styled(FlexDiv)`
  position: relative;
  ${CheckBoxComponent} {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
  ${TextLabel} {
    &:before {
      content: "";
      position: absolute;
      border: 1px solid ${theme.colorsV2.gray.shade_400};
      height: 24px;
      width: 24px;
      border-radius: 8px;
      top: ${({ topPos }) => topPos || "50%"};
      ${({ topPos }) =>
        !topPos &&
        `
        transform: translateY(-50%);
        `}
      left: ${({ leftPos }) => leftPos || "0px"};
    }
  }
  ${CheckBoxComponent}:checked +${TextLabel} {
    &:before {
      background-color: ${({ checkBoxColor }) =>
        checkBoxColor || theme.colorsV2.shyft.purple_text};
      border-color: ${({ checkBoxColor }) =>
        checkBoxColor || theme.colorsV2.shyft.purple_text};
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: ${({ checkTopPos }) => checkTopPos || "50%"};
      ${({ checkTopPos }) =>
        !checkTopPos &&
        `
        transform: translateY(-50%);
        `}
      left: ${({ checkLeftPos }) => checkLeftPos || "5px"};
      background: url("/images/check.svg") center no-repeat;
      background-size: 14px;
      height: 14px;
      width: 14px;
    }
  }
`;
