import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import type { AppProps } from "next/app";
import ReactGA from "react-ga4";
import { useEffect } from "react";

import Intercom from "@/components/Atoms/Intercom";
import ComponentWrapper from "@/containers/ComponentWrapper";
import ErrorBoundary from "@/containers/ErrorPage";

import theme from "@/foundation/Theme";

import { wrapper } from "@/redux/Store";

// PACKAGES CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";

// GLOBAL CSS
import "@/styles/globals.scss";
import "@/styles/paymentSuccess.scss";
import "@/styles/paymentFailure.scss";

// SHYFT PASS CSS
import "@/containers/ShyftPassPage/shyft-pass.scss";
import "@/containers/ShyftPassBooking/shyft-pass-app-view.scss";
import "@/containers/ShyftPassPage/HeroSection/hero-section.scss";
import "@/containers/ShyftPassPage/HeroSection/BenefitsSection/benefits-section.scss";
import "@/components/Molecules/ShyftPassFooter/style.scss";
import "@/containers/ShyftPassPage/FAQSection/style.scss";
import "@/components/Organisms/ShyftPassHeader/shyft-pass-header.scss";
import "@/components/Molecules/MobileDrawer/ShyftPassMobileDrawer/style.scss";
import "@/containers/ShyftPassBooking/ScheduleSection/style.scss";
import "@/containers/JoinTrial/Failure/index.scss";

export default function App({ Component, ...rest }: AppProps) {
  const { store, props } = wrapper.useWrappedStore(rest);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID === "production") {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || "");
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* <Intercom appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}> */}
          <ErrorBoundary fromTopErrorBoundary={true}>
            <ComponentWrapper>
              <Component {...props.pageProps} />
            </ComponentWrapper>
          </ErrorBoundary>
        {/* </Intercom> */}
      </ThemeProvider>
    </Provider>
  );
}
