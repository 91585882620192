import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

import { setCookieStorage } from "@/utils";

const initialState: any = {
  isLoading: false,
  error: "",
  success: false,
  data: {},
  cardData: [],
  isCardLoading: false,
  packData: {},
  packDetail: {},
  isUpdateLoading: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.profile,
      }))
      // GET PROFILE DETAIL PAGE
      .addCase(actions.getProfileDetails.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(actions.getProfileDetails.fulfilled, (state, { payload }) => {
        const profilePicture =
          payload?.data?.profile_detail?.profile_pic || "null";
        setCookieStorage("__profilePicture", profilePicture);
        return {
          ...state,
          isLoading: false,
          success: true,
          data: payload?.data,
          cardData: payload?.data?.upcoming_class_card,
        };
      })
      .addCase(actions.getProfileDetails.rejected, (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload || true,
      }))
      // UPDATE PROFILE PAGE
      .addCase(actions.updateProfileDetails.pending, (state) => ({
        ...state,
        isUpdateLoading: true,
        success: false,
        error: "",
      }))
      .addCase(actions.updateProfileDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isUpdateLoading: false,
          success: true,
          packData: payload?.data,
          cardData: payload?.data?.upcoming_class_card,
        };
      })
      .addCase(actions.updateProfileDetails.rejected, (state, { payload }) => ({
        ...state,
        isUpdateLoading: false,
        error: payload || true,
      }))
      // GET PROFILE PACK PAGE
      .addCase(actions.getPacksDetails.pending, (state) => ({
        ...state,
        isCardLoading: true,
        success: false,
        error: "",
      }))
      .addCase(actions.getPacksDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isCardLoading: false,
          success: true,
          packDetail: payload?.data,
        };
      })
      .addCase(actions.getPacksDetails.rejected, (state, { payload }) => ({
        ...state,
        isCardLoading: false,
        error: payload || true,
      }));
  },
});
export {
  getProfileDetails,
  getPacksDetails,
  updateProfileDetails,
} from "./Actions";

export default profileSlice.reducer;
