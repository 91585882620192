export const appendHotJarScript = (isProduction: boolean) => {
  let head;
  let script;

  if (isProduction) {
    head = document.getElementsByTagName("head")[0];

    script = document.createElement("script");

    script.type = "text/javascript";

    const code = document.createTextNode(`
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3163495,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `);
    script.appendChild(code);

    head.appendChild(script);
  }

  return { head, script };
};

export const appendFreshChatScript = () => {
  if (typeof window !== "undefined") {
    const head = document.getElementsByTagName("head")[0];

    // Initialize Freshchat
    const initCode = `
      function initFreshChat() {
        window.fcWidget.init({
          token: "${process.env.NEXT_PUBLIC_FRESHCHAT_TOKEN}",
          host: "https://betheshyft.freshchat.com",
          "config": {
            "cssNames": {
               "widget": "custom_fc_frame"
            }
         }
        });
      }

      function initialize() {
        if (window.fcWidget) {
          initFreshChat();
        } else {
          setTimeout(initialize, 50);
        }
      }

      if (window.addEventListener) {
        initialize()
      } else if (window.attachEvent) {
        window.attachEvent("onload", initialize);
      }
    `;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.textContent = initCode;

    // Load Freshchat widget script
    const freshchatScript = document.createElement("script");
    freshchatScript.type = "text/javascript";
    freshchatScript.async = true;
    freshchatScript.src = "https://wchat.freshchat.com/js/widget.js";
    freshchatScript.onload = () => {
      head.appendChild(script);
    };

    head.appendChild(freshchatScript);
  }
};
