import React from "react";

import { Paragraph } from "../style";

interface LabelProps {
  children?: React.ReactNode;
  variant: string;
}

const renderLabel = ({ children, variant, ...rest }: LabelProps) => {
  const labelProps = { ...rest };

  return (
    <>
      {["label1", "label2", "label3", "label4"].includes(variant) && (
        <Paragraph {...labelProps}>{children}</Paragraph>
      )}
    </>
  );
};

export default renderLabel;
