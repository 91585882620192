import { LegacyRef, forwardRef } from "react";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./TextInput.module.scss";

interface TextInputProps {
  name: string;
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  type?: string;
}

const TextInput = forwardRef(
  (
    { className, type="text", ...rest }: TextInputProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const cls = classNameGenerator(styles);

    return (
      <input
        type={type}
        ref={ref}
        className={clsx(cls("textInput"), className)}
        {...rest}
      />
    );
  }
);

TextInput.displayName = "TextInput";

export default TextInput;
