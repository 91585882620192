import React, { useEffect, useState } from "react";
import Link from "next/link";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";

import SVGSprite from "../../../../Atoms/SVGSprite";

import { clsx, generateQueryArguments } from "@/utils";
import menu from "@/constants/HeaderMenu";

import styles from "./NavMenu.module.scss";

const CaretDownIcon = (props) => (
  <SVGSprite icon="icon-header-chevron-down" {...props} />
);

interface NavLinkProps {
  link: string;
  id: string;
  query: { [key: string]: string };
  category: string;
  isExternal?: boolean;
  isSpecialLink?: boolean;
  icon?: string;
}

const NavLink: React.FC<NavLinkProps> = ({
  link,
  id,
  query,
  category,
  isExternal,
  isSpecialLink,
  icon,
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);
  const routeLink = link?.endsWith("/") ? link : `${link}/`;

  const href = isClient
    ? `${routeLink}${generateQueryArguments(query)}`
    : routeLink;

  const linkCategory = isSpecialLink ? (
    <p className={styles.specialLink}>
      <p>
        <SVGSprite icon={icon} height="24px" width="24px" />
        {category}
      </p>
      <SVGSprite
        icon="icon-header-chevron-right"
        height="16px"
        width="16px"
        fill="#F79009"
      />
    </p>
  ) : (
    category
  );
  return (
    <li className={styles.subNavigationMenuItem}>
      <div id={id}>
        <NavigationMenu.Link asChild>
          {isExternal ? (
            <a
              className={clsx(
                styles.subNavigationMenuLink,
                isSpecialLink ? styles.isSpecialLinkWrapper : ""
              )}
              href={href}
            >
              {linkCategory}
            </a>
          ) : (
            <Link
              className={clsx(
                styles.subNavigationMenuLink,
                isSpecialLink ? styles.isSpecialLinkWrapper : ""
              )}
              href={href}
            >
              {linkCategory}
            </Link>
          )}
        </NavigationMenu.Link>
      </div>
    </li>
  );
};

interface NavItemProps {
  link?: string;
  id?: string;
  query: { [key: string]: string };
  category: string;
  subCategory?: NavLinkProps[];
  isExternal?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  link,
  id,
  query,
  category,
  subCategory,
  isExternal,
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);
  const routeLink = link?.endsWith("/") ? link : `${link}/`;

  const href = isClient
    ? `${routeLink}${generateQueryArguments(query)}`
    : routeLink;

  return subCategory?.length ? (
    <NavigationMenu.Item className={styles.navigationMenuItem}>
      <div id={id}>
        <NavigationMenu.Trigger className={styles.navigationMenuTrigger}>
          {link ? (
            isExternal ? (
              <a className={styles.navigationMenuLink} href={href}>
                {category}
                <CaretDownIcon className={styles.caretDown} aria-hidden />
              </a>
            ) : (
              <Link className={styles.navigationMenuLink} href={href}>
                {category}
                <CaretDownIcon className={styles.caretDown} aria-hidden />
              </Link>
            )
          ) : (
            <div className={styles.navigationMenuLink}>
              {category}
              <CaretDownIcon className={styles.caretDown} aria-hidden />
            </div>
          )}
        </NavigationMenu.Trigger>
      </div>
      <NavigationMenu.Content className={styles.navigationMenuContent}>
        <ul className={styles.subNavigationMenu}>
          {subCategory?.map((cat, index) => (
            <NavLink key={index} {...cat} />
          ))}
        </ul>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  ) : (
    <NavigationMenu.Item className={styles.navigationMenuItem}>
      <NavigationMenu.Trigger className={styles.navigationMenuTrigger}>
        <div id={id}>
          <NavigationMenu.Link className={styles.navigationMenuLink} asChild>
            {isExternal ? (
              <a
                className={clsx(styles.navigationMenuLink, styles.noChild)}
                href={href}
              >
                {category}
              </a>
            ) : (
              <Link
                className={clsx(styles.navigationMenuLink, styles.noChild)}
                href={href}
              >
                {category}
              </Link>
            )}
          </NavigationMenu.Link>
        </div>
      </NavigationMenu.Trigger>
    </NavigationMenu.Item>
  );
};

interface NavMenuProps {
  hideIndicator?: boolean;
  isDarkMode?: boolean;
}

const NavMenu: React.FC<NavMenuProps> = ({
  hideIndicator = true,
  isDarkMode = false,
}) => {
  return (
    <NavigationMenu.Root
      className={clsx(
        styles.navigationMenuRoot,
        styles.navMenu,
        isDarkMode ? styles.darkMode : ""
      )}
    >
      <NavigationMenu.List className={styles.navigationMenuList}>
        {menu?.map((category, index) => (
          <NavItem key={index} {...category} />
        ))}
        {!hideIndicator && (
          <NavigationMenu.Indicator className={styles.navigationMenuIndicator}>
            <div className={styles.arrow} />
          </NavigationMenu.Indicator>
        )}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};

export default NavMenu;
