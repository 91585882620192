import { Control, Controller } from "react-hook-form";

import ErrorUI from "@/components/Atoms/InputsV2/ErrorUI";
import Typography from "@/foundation/Typography/V2";

import { classNameGenerator, clsx, timezone } from "@/utils";

import styles from "./YogaTrialSelectSlot.module.scss";

interface YogaTrialSelectSlotProps {
  name: string;
  control: Control<any>;
  className?: string;
  onBlur?: (e: any) => void;
  availableSlots: {
    ist_time: string;
  }[];
}

const YogaTrialSelectSlot: React.FC<YogaTrialSelectSlotProps> = ({
  name,
  control,
  onBlur = () => {},
  availableSlots,
  ...rest
}) => {
  const cls = classNameGenerator(styles);

  return (
    <div className={cls("yogaTrialSelectSlot")} {...rest}>
      <Controller
        name={name}
        control={control}
        shouldUnregister
        render={({
          field: { value, onBlur: handleBlur, onChange, ref },
          fieldState: { error },
        }) => (
          <>
            <div className={cls("label")}>
              Select a time slot
              <span>
                {[
                  "US/Pacific",
                  "America/Vancouver",
                  "America/Los_Angeles",
                ]?.includes(timezone)
                  ? " (Showing time slots in PST)"
                  : " (Showing time slots in EST)"}
              </span>
            </div>
            <div className={cls("slotList")} ref={ref}>
              {availableSlots.map((s, index) => {
                const [time, meridiem] = s?.ist_time?.split(" ") || [];
                return (
                  <div
                    key={index}
                    className={clsx(
                      cls("slotChip"),
                      value === s?.ist_time ? cls("active") : ""
                    )}
                    onClick={() => {
                      handleBlur();
                      onChange(s?.ist_time);
                    }}
                  >
                    <Typography variant="paragraph1" className={cls("slot")}>
                      {time}
                      <Typography variant="span" className={cls("meridiem")}>
                        {meridiem}
                      </Typography>
                    </Typography>
                  </div>
                );
              })}
            </div>
            <ErrorUI error={error?.message} />
          </>
        )}
      />
    </div>
  );
};

export default YogaTrialSelectSlot;
