import React, { useEffect } from "react";

import ModalMotion, { AnimateDirection } from "../Modal";
import Typography from "../../../foundation/Typography/V2";
import theme from "../../../foundation/Theme";

import { CloseIcon, Icon } from "./style";

function Toast(props) {
  const {
    maxWidth,
    toastPadding = "20px 50px",
    onClose = () => {},
    type = "success",
    // iconFill = theme.colorsV2.success.shade_600,
    iconDimensions = "20px",
    text = "this is toast message",
    textVariant = "label4",
    textColor = theme.colors.white,
    autoDismiss = true,
    duration = 3000,
    ...rest
  } = props || {};

  useEffect(() => {
    autoDismiss &&
      setTimeout(() => {
        onClose();
      }, duration);
  });

  return (
    <ModalMotion
      overlayClosable
      width="330px"
      headerMaxWidth={maxWidth}
      animateDirection={AnimateDirection.BOTTOM}
      showShadow
      showOverlay={false}
      borderRadius="10px"
      bgCol={`rgba(${theme.colors.shadows.black},0.85)`}
      bottomYpos={"-32px"}
      bodyPadding={toastPadding || "12px 48px"}
    >
      <Icon
        icon={type === "success" ? "icon-checkmark" : "icon-danger"}
        height={iconDimensions}
        width={iconDimensions}
        fill={
          type === "success"
            ? theme.colorsV2.success.shade_600
            : theme.colorsV2.shyft.error
        }
      />
      {text && (
        <Typography variant={textVariant} color={textColor} {...rest}>
          {text}
        </Typography>
      )}
      <CloseIcon
        icon="icon-close"
        height="12px"
        width="12px"
        fill={theme.colors.white}
        onClick={onClose}
      />
    </ModalMotion>
  );
}

export default Toast;
