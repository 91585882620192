import { forwardRef } from "react";
import Select from "react-select";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./SelectInput.module.scss";

interface OptionI {
  label: string;
  value: string | number;
  [key: string]: any;
}

interface SelectInputProps {
  instanceId?: string;
  name: string;
  placeholder?: string;
  className?: string;
  classNamePrefix?: string;
  options: OptionI[];
  isSearchable?: boolean;
  value?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  components?: { Menu: any };
  formatOptionLabel?: any;
  maxMenuHeight?: number;
}

const SelectInput = forwardRef(
  (
    {
      instanceId = "select-input",
      className,
      options,
      value,
      onChange = () => {},
      ...rest
    }: SelectInputProps,
    ref: any
  ) => {
    const cls = classNameGenerator(styles);

    return (
      <Select
        instanceId={instanceId}
        ref={ref}
        options={options}
        value={
          options?.find((option: OptionI) => option.value === value) || null
        }
        onChange={({ value }) => onChange(value)}
        className={clsx(cls("selectInput"), className)}
        classNamePrefix="selectInput"
        {...rest}
        unstyled
      />
    );
  }
);

SelectInput.displayName = "SelectInput";

export default SelectInput;
