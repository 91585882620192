import React from "react";
import styled from "styled-components";
import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  system,
} from "styled-system";
import theme from "../../../foundation/Theme";
import Typography from "../../../foundation/Typography/V2";
import { FlexDiv } from "../Box";
function TextArea(props) {
  const {
    id = undefined,
    inputPadding = "",
    focusable = true,
    inputBorderRadius = "6px",
    width = "100%",
    resize = "none",
    value = "",
    placeholder = "",
    color = theme.colors.Text_2,
    fontSize = "14px",
    fontFamily = "",
    rows = "4",
    cols = "",
    error = "",
    onChange = () => {},
    ...rest
  } = props || {};
  return (
    <FlexDiv position={"relative"} flexDirection={"column"}>
      <TextAreaInput
        id={id}
        p={inputPadding}
        width={width}
        resize={resize}
        inputBorderRadius={inputBorderRadius}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        color={color}
        fontSize={fontSize}
        fontFamily={fontFamily ? fontFamily : "var(--font-500)"}
        value={value}
        onChange={onChange}
        focusable={focusable}
        error={error}
        {...rest}
      />
      {error && (
        <Typography
          fontSize={"10px"}
          variant={"span"}
          color={theme.colors.Action_1}
          mt={"5px"}
        >
          {error}
        </Typography>
      )}
    </FlexDiv>
  );
}

export default TextArea;
const TextAreaInput = styled.textarea`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  border-radius: ${({ inputBorderRadius }) => inputBorderRadius};
  border: 1px solid
    ${({ error }) => (error ? theme.colors.Action_1 : theme.colors.Border_1)};
  transition: all 0.2s ease-in-out;
  outline: 0;
  resize: ${({ resize }) => resize};
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${({ fontFamily }) => fontFamily};
  &::placeholder {
    color: ${theme.colors.Text_3};
  }
  ${({ focusable }) =>
    focusable &&
    `&:focus {
    border-color: ${({ error }) =>
      error ? theme.colors.Action_1 : theme.colors.Primary_CTA};
  }`};
`;
