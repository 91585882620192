import React from "react";

import { Paragraph } from "../style";

interface DisplayProps {
  children?: React.ReactNode;
  variant: string;
}

const renderDisplay = ({ children, variant, ...rest }: DisplayProps) => {
  const displayProps = { ...rest };

  return (
    ["display1", "display2", "display3", "display4", "header3"].includes(
      variant
    ) && <Paragraph {...displayProps}>{children}</Paragraph>
  );
};

export default renderDisplay;
