export const matchCountryRegex = (code, number) => {
  let countryCode = code ? code : "+91";
  if (countryCode === "+91") {
    return /^[6-9]\d{9}$/.test(number);
  } else if (countryCode === "+971") {
    return /^((5){1}([0-9]{8}))/.test(number);
  } else if (countryCode === "+1") {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      number
    );
  } else {
    return /^((6|8|9|1){1}([0-9]{7}))/.test(number);
  }
};

export const validateMobileNumberRegex = (code, number) => {
  let countryCode = code ? code : "+91";
  if (countryCode?.length === 3) {
    if (/^[6-9]\d{9}$/.test(number)) return true;
  } else if (countryCode?.length === 4) {
    if (/^((5){1}([0-9]{8}))/.test(number)) return true;
  } else if (countryCode?.length === 2) {
    if (
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(number)
    )
      return true;
  } else {
    if (/^((6|8|9){1}([0-9]{7}))/.test(number)) return true;
  }
  return false;
};

export const isValidName = (value) =>
  value &&
  (value.match(/^[a-zA-Z\s]*$/) || value === "") &&
  value.length <= 140;

export const isValidMobile = (value) =>
  value && (value.match(/^[\d -]+$/) || !value) && value.length <= 14;

export const isValidEmail = (value) =>
  value && value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

export const inputValidator = (inputs) => {
  const errors = {};

  const {
    name,
    countryCode,
    mobile,
    email,
    goal,
    service,
    cvid,
    dermaType,
    requestWebinar,
    yogaPackage,
    selectedPackage,
    companyName,
    startDate,
    selectedPackageDuration,
  } = inputs;

  const validateList = Object.keys(inputs);

  const validateInput = (key) => validateList.includes(key);

  if (validateInput("name")) {
    if (!name) {
      errors.nameError = "Please enter your name";
    }
    if (name && name.length < 3) {
      errors.nameError = "Please enter full name";
    }
  }
  if (validateInput("mobile")) {
    if (!matchCountryRegex(countryCode, mobile)) {
      errors.mobileError = "Please enter a valid phone number";
    }
    if (mobile && !matchCountryRegex(countryCode, mobile)) {
      errors.mobileError = "Please enter a valid number";
    }
  }
  if (validateInput("email")) {
    if (!isValidEmail(email) && email.length <= 254) {
      errors.emailError = "Please enter a valid email address";
    }
  }
  if (validateInput("service") && !service) {
    errors.serviceError = "Please select a service";
  }
  if (validateInput("goal") && !goal) {
    errors.goalError = "Please select a health goal";
  }
  if (validateInput("requestWebinar") && !requestWebinar) {
    errors.requestWebinarError = "Please enter Webinar Name";
  }
  if (validateInput("cvid") && !cvid) {
    errors.cvidError = "Please enter your Club Vistara ID";
  }
  if (validateInput("yogaPackage") && !yogaPackage) {
    errors.yogaPackageError = "Please select a yoga package";
  }
  if (validateInput("selectedPackage") && !selectedPackage) {
    errors.selectedPackageError = "Please select a package";
  }
  if (validateInput("selectedPackageDuration") && !selectedPackageDuration) {
    errors.selectedPackageDurationError = "Please select a package duration";
  }
  if (validateInput("companyName") && !companyName) {
    errors.companyNameError = "Please enter Company Name";
  }
  if (validateInput("startDate") && !startDate) {
    errors.startDateError = "Please select a Start Date";
  }
  if (validateInput("service") && !service) {
    errors.serviceError = "Please select a service";
  }
  if (validateInput("dermaType") && !dermaType) {
    errors.dermaTypeError = "Please select a type";
  }

  const errorKeys = Object.keys(errors);

  const errorArr = errorKeys.map((key) => ({ key, value: errors[key] }));

  const isError = errorKeys.length > 0;

  return { errors, errorArr, isError };
};
