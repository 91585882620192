import { LegacyRef, forwardRef } from "react";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./CheckboxInput.module.scss";

interface CheckboxInputProps {
  id: string;
  name: string;
  label?: string;
  className?: string;
  value?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

const CheckboxInput = forwardRef(
  (
    { className, id, label, value, onChange, ...rest }: CheckboxInputProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const cls = classNameGenerator(styles);

    return (
      <>
        <input
          ref={ref}
          id={id}
          type="checkbox"
          className={cls("input")}
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          {...rest}
        />
        <label htmlFor={id} className={clsx(cls("checkbox"), className)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className={cls("icon")}
          >
            <rect x="3" y="3" width="18" height="18" rx="4" fill="#E4E7EC" />
            <path
              d="M16.1562 9.18164L10.6562 14.6816L8.15625 12.1816"
              stroke="#D0D5DD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {label && <span className={cls("label")}>{label}</span>}
        </label>
      </>
    );
  }
);

CheckboxInput.displayName = "CheckboxInput";

export default CheckboxInput;
