import styled from "styled-components";

import Button from "@/components/Atoms/Button";

export const ProfileButton = styled(Button)`
  background-color: #fff;
  height: 40px;
  width: 40px;
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  padding: 0;
  flex-shrink: 0;
  &:hover {
    background-color: #fff;
  }
  svg {
    cursor: pointer;
    height: 28px;
    width: 28px;
  }
  img {
    cursor: pointer;
    height: 40px;
    width: 40px;
    object-fit: cover;
  }
  @media only screen and (max-width: 767px) {
    height: 32px;
    width: 32px;
    svg {
      height: 22.4px;
      width: 22.4px;
    }
    img {
      height: 32px;
      width: 32px;
    }
  }
`;

export const NavigationLink = styled.li`
  &.navigationMenuItem {
    width: 100%;
    .navigationMenuLink {
      display: block;
      width: 100%;
      padding: 16px;
      font-family: var(--man-700);
      font-size: 14px;
      line-height: 16px;
      color: #293056;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        background-color: #ebe9fe;
        color: #6e55d3;
      }
    }
  }
`;
