import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { useLayoutEffect, useRef } from "react";

import { isMobile } from "../../utils";
/**
 * Hook for disabling the body scrolls when priority elements
 * like modals, nav-menus etc are opened
 */
const useLockBodyScroll = (props) => {
  const { disableOnAllDevices = !isMobile ? true : false } = props || {};
  const bodyElement = useRef(document.body).current;

  useLayoutEffect(() => {
    if (typeof window === "undefined") return;
    if (disableOnAllDevices) {
      disableBodyScroll(bodyElement, { reserveScrollBarGap: true });

      return () => clearAllBodyScrollLocks();
    }
  }, []);
};

export default useLockBodyScroll;
