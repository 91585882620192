import React from "react";

import { H1, H2, H3, H4, H5, H6 } from "./style";

interface HeadingProps {
  children?: React.ReactNode;
  variant: string;
}

const renderHeading = ({ children, variant, ...rest }: HeadingProps) => {
  const headingProps = { ...rest };

  return (
    <>
      {["heading1"].includes(variant) && <H1 {...headingProps}>{children}</H1>}
      {["heading2"].includes(variant) && <H2 {...headingProps}>{children}</H2>}
      {["heading3", "title1"].includes(variant) && (
        <H3 {...headingProps}>{children}</H3>
      )}
      {["heading4", "title2"].includes(variant) && (
        <H4 {...headingProps}>{children}</H4>
      )}
      {["heading5", "title4"].includes(variant) && (
        <H5 {...headingProps}>{children}</H5>
      )}
      {["heading6", "title3"].includes(variant) && (
        <H6 {...headingProps}>{children}</H6>
      )}
    </>
  );
};

export default renderHeading;
