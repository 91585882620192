import React from "react";
import usePortal from "react-cool-portal";

import { FlexDiv } from "@/components/Atoms/Box";

import { useLockBodyScroll } from "@/hooks";

import Typography from "@/foundation/Typography/V2";
import theme from "@/foundation/Theme";

import { Body, CloseIcon, ModalContainer, ModalOverlay } from "./style";

export const AnimateDirection = {
  RIGHT: "right",
  BOTTOM: "bottom",
  CENTER: "center",
  LEFT: "left",
};

const getAnimateValue = ({ animateDirection, bottomYpos, initialYpos }) => {
  const { RIGHT, BOTTOM, CENTER, LEFT } = AnimateDirection;
  switch (animateDirection) {
    case RIGHT:
      return {
        initial: { opacity: 0, x: "50%" },
        animate: { opacity: 1, x: "0" },
        exit: { opacity: 0, x: "50%" },
      };
    case LEFT:
      return {
        initial: { opacity: 0, x: "-50%" },
        animate: { opacity: 1, x: "0" },
        exit: { opacity: 0, x: "-50%" },
      };

    case BOTTOM:
      return {
        initial: { opacity: 0, y: "50%", x: "-50%" },
        animate: { opacity: 1, y: bottomYpos, x: "-50%" },
        exit: { opacity: 0, y: "50%", x: "-50%" },
      };
    case CENTER:
      return {
        initial: { opacity: 0, y: initialYpos, x: "50%" },
        animate: { opacity: 1, y: "-50%", x: "50%" },
        exit: { opacity: 0, y: initialYpos, x: "50%" },
      };
    default:
      return {
        initial: { opacity: 0, x: "50%" },
        animate: { opacity: 1, x: "0" },
        exit: { opacity: 0, x: "50%" },
      };
  }
};

function ModalMotion({
  onClose,
  showClose = false,
  closeIcon = "icon-close",
  iconDimension = "13px",
  iconFill = theme.colors.gray_100,
  iconPadding = "0px",
  iconTopPosition = "54px",
  iconRightPosition = "54px",
  bodyPadding = "35px",
  bodyDirection = "column",
  closeBtnPadding = "5px",
  children,
  borderRadius = "0px",
  overlayClosable = false,
  width = "383px",
  zIndex = 1050,
  headerStyle = {},
  bodyStyle = {},
  onEditClick,
  onDeleteClick,
  headerActions = null,
  showHeader = true,
  headerVariant = "h1",
  headerText = "",
  headerPadding = "65px 0 0 0",
  headerMaxWidth = "100%",
  headerMargin = "0 auto",
  portalContainerId = undefined,
  portal = true,
  closeButtonRound = false,
  verticalWidth = "12px",
  animateDirection = AnimateDirection.LEFT,
  height = "auto",
  overlayBgColor = theme.colors.layOvers.black,
  overlayOpacity = "0.3",
  showOverlay = true,
  showShadow = false,
  boxShadow = `0 0 8px rgba(${theme.colors.layOvers.black},0.2)`,
  mobilewidth = "100%",
  bottomYpos = "-78px",
  initialYpos = "-50%",
  bgCol = theme.colors.white,
  ...rest
}) {
  const { initial, animate, exit } = getAnimateValue({
    animateDirection,
    bottomYpos,
    initialYpos,
  });
  useLockBodyScroll();
  const { Portal } = usePortal({
    clickOutsideToHide: false,
    escToHide: false,
    containerId: portalContainerId,
  });

  const content = (
    <>
      {showOverlay && (
        <ModalOverlay
          onClick={
            overlayClosable && onClose
              ? (e) => {
                  onClose(e);
                }
              : undefined
          }
          zindex={zIndex}
          overlayBgColor={overlayBgColor}
          overlayOpacity={overlayOpacity}
        ></ModalOverlay>
      )}
      <ModalContainer
        animatedirection={animateDirection}
        key="modal"
        initial={initial}
        animate={animate}
        exit={exit}
        transition={{
          ease: "easeInOut",
          duration: 0.2,
        }}
        width={width}
        height={height}
        zindex={zIndex}
        borderradius={borderRadius}
        showshadow={showShadow ? 1 : undefined}
        shadow={boxShadow}
        mobilewidth={mobilewidth}
        bgcol={bgCol || undefined}
        {...rest}
      >
        {showClose && (
          <CloseIcon
            icon={closeIcon}
            height={iconDimension}
            width={iconDimension}
            onClick={onClose}
            fill={iconFill}
            iconpadding={iconPadding}
            icontopposition={iconTopPosition}
            iconrightposition={iconRightPosition}
          />
        )}
        {showHeader && headerText && (
          <FlexDiv
            maxWidth={headerMaxWidth}
            m={headerMargin}
            width={"100%"}
            flexDirection={"column"}
            p={headerPadding}
            position="relative"
          >
            <Typography variant={headerVariant}>{headerText}</Typography>
          </FlexDiv>
        )}
        <Body
          bodyPadding={bodyPadding}
          style={bodyStyle || {}}
          height={height}
          flexDirection={bodyDirection}
          animatedirection={animateDirection}
          className="body-wrapper"
        >
          {children}
        </Body>
      </ModalContainer>
    </>
  );

  return portal ? <Portal>{content}</Portal> : content;
}

export default ModalMotion;
