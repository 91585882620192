export const setCookieStorage = (key, value, exVal = "", expType = "") => {
  if (exVal && expType) {
    let d = new Date();
    if (expType === "days")
      d.setTime(d.getTime() + exVal * 24 * 60 * 60 * 1000);
    else if (expType === "minutes") d.setTime(d.getTime() + exVal * 60 * 1000);
    else d.setTime(d.getTime() + exVal * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = key + "=" + value + ";" + expires + ";path=/;";
  } else {
    document.cookie = key + "=" + value + ";path=/;";
  }
};

export const getCookieStorage = (key) => {
  let returnVal = "";
  if (typeof window !== "undefined") {
    let allCookieArray = decodeURIComponent(document.cookie).split(";");
    for (let i = 0; i < allCookieArray.length; i++) {
      let temp = allCookieArray[i].trim();
      if (temp.indexOf(key) === 0) {
        returnVal = temp.substring(key.length + 1, temp.length);
        break;
      }
    }
  }
  return returnVal;
};

export const removeCookieStorage = (key) => {
  document.cookie = key + "= ;expires = Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
};

export const clearCookieStorage = () => {
  let allCookieArray = document.cookie.split(";");
  for (var i = 0; i < allCookieArray.length; i++) {
    var cookie = allCookieArray[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
  }
};

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const getSessionStorage = (key) => {
  return sessionStorage.getItem(key);
};

export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};
