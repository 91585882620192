import React from "react";
import styled from "styled-components";
import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
} from "styled-system";

export default function Image(props) {
  const {
    maxWidth = "100%",
    imageName = "new-logo.svg",
    maxHeight = "",
    alt = "",
    ...rest
  } = props || {};
  return (
    <Img
      src={`/images/${imageName}`}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      alt={alt}
      {...rest}
    />
  );
}
const Img = styled.img`
  ${color}
  ${space}
  ${flexbox}
  ${layout}
    ${position}
    ${background}
    ${border}
    ${shadow}
  max-height: ${({ maxHeight }) => maxHeight};
`;
