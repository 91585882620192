import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios, timezone as tz } from "@/utils";
const _timezone = tz || "Asia/Calcutta";

export const getDiagnosticPageData = createAsyncThunk(
  "getDiagnosticPageData",
  ({ slug, ref_code = "" }) => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/health-program/landingpage/${slug}/?timezone=${_timezone}`;
    if (ref_code) url += "&ref_code=" + ref_code;
    return axios.get(url);
  }
);

export const getPartnerUrl = createAsyncThunk(
  "getPartnerUrl",
  ({ slug, ref_code = "", mapping_slug }) => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/health-program/landingpage/${slug}/?timezone=${_timezone}&mapping_slug=${mapping_slug}`;

    if (ref_code) url += "&ref_code=" + ref_code;

    return axios.get(url);
  }
);

export const sendDiagnosticFormData = createAsyncThunk(
  "sendDiagnosticFormData",
  ({ data }) => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/health-program/packageform/submission/?timezone=${_timezone}`;
    return axios.post(url, data);
  }
);
