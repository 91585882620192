// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";
import { DEFAULT_API_ERROR_MESSAGE } from "@/utils/Constants";

export const singlePack = {
  title: "Shyftpass Single Pack",
  duration: 1,
  price: 499,
  strikeoutPrice: 499,
  slug: "shyft-single-class-pass-package",
  currencyCode: "INR",
  currencySymbol: "INR",
};

const initialState = {
  isLoading: false,
  success: false,
  error: null,
  shyftPassScheduleData: {},
  selectedDate: "",
  isClassDataLoading: true,
  classDataSuccess: false,
  classDataError: null,
  classData: {},
  isClassListLoading: true,
  classListSuccess: false,
  classListError: null,
  classList: {},
  isBookingLoading: false,
  bookingSuccess: false,
  bookingError: null,
  packages: [singlePack],
  isWaitListLoading: false,
  waitListSuccess: false,
  waitListError: null,
  shyftPassZoneList: null,
  shyftPassZoneLoading: false,
  shyftPassZoneLoadingError: "",
  selectedShyftPassZoneId: "",
};

const shyftPassSlice = createSlice({
  name: "shyftPass",
  initialState,
  reducers: {
    setSelectedDate(state, action) {
      return {
        ...state,
        selectedDate: action.payload,
      };
    },
    resetBookingError(state, action) {
      return {
        ...state,
        bookingError: null,
      };
    },
    resetBookingSuccess(state, action) {
      return {
        ...state,
        bookingSuccess: false,
      };
    },
    setShyftPassZoneId(state, action) {
      return {
        ...state,
        selectedShyftPassZoneId: action.payload?.zoneId,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.shyftPass,
      }))
      // GET SHYFT PASS SCHEDULE DATA
      .addCase(actions.getShyftPassScheduleData.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: null,
      }))
      .addCase(
        actions.getShyftPassScheduleData.fulfilled,
        (state, { payload, meta }) => {
          if (!payload?.status) {
            return {
              ...state,
              isLoading: false,
              error: payload?.message || true,
            };
          }

          if (
            meta?.args?.encodedId &&
            !payload?.data?.has_purchased_shyft_pass
          ) {
            window.location.href = "/shyftpass/";
          }

          return {
            ...state,
            isLoading: false,
            success: true,
            selectedShyftPassZoneId: payload?.data?.zone_details?.find(
              (item) => item?.is_selected
            )?.id,
            selectedDate: payload?.data?.event_dates?.find(
              (date) => date?.is_selected
            )?.date,
            shyftPassScheduleData: payload?.data,
            shyftPassZoneList: payload?.data?.zone_details,
          };
        }
      )
      .addCase(
        actions.getShyftPassScheduleData.rejected,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            error: payload || true,
          };
        }
      )
      // GET SHYFT PASS SCHEDULE DATA BY ID
      .addCase(actions.getShyftPassScheduleDataById.pending, (state) => ({
        ...state,
        isClassDataLoading: true,
      }))
      .addCase(
        actions.getShyftPassScheduleDataById.fulfilled,
        (state, { payload, meta }) => {
          if (!payload?.status) {
            return {
              ...state,
              isClassDataLoading: false,
              classDataSuccess: false,
              classDataError: payload?.message || true,
            };
          }

          const _price = payload?.data?.meta?.base_price || 499;

          const _singlePack = {
            ...singlePack,
            price: _price,
            strikeoutPrice: _price,
          };

          return {
            ...state,
            isClassDataLoading: false,
            classDataSuccess: true,
            classDataError: null,
            classData: payload?.data,
            packages: [_singlePack],
          };
        }
      )
      .addCase(
        actions.getShyftPassScheduleDataById.rejected,
        (state, { payload }) => {
          return {
            ...state,
            isClassDataLoading: false,
            classDataSuccess: false,
            classDataError: payload || true,
          };
        }
      )
      // GET SHYFT PASS SCHEDULE CLASS DATA BY SLUG
      .addCase(actions.getShyftPassScheduleByClassSlug.pending, (state) => ({
        ...state,
        isClassDataLoading: true,
        classDataSuccess: false,
        classDataError: null,
      }))
      .addCase(
        actions.getShyftPassScheduleByClassSlug.fulfilled,
        (state, { payload, meta }) => {
          if (!payload?.status) {
            return {
              ...state,
              isClassListLoading: false,
              classListError: payload?.message || true,
            };
          }

          return {
            ...state,
            isClassListLoading: false,
            classListSuccess: true,
            classList: payload?.data,
          };
        }
      )
      .addCase(
        actions.getShyftPassScheduleByClassSlug.rejected,
        (state, { payload }) => {
          return {
            ...state,
            isClassListLoading: false,
            classListError: payload || true,
          };
        }
      )
      // BOOK CLASS
      .addCase(actions.bookClass.pending, (state) => ({
        ...state,
        isBookingLoading: true,
        bookingSuccess: false,
        bookingError: "",
      }))
      .addCase(actions.bookClass.fulfilled, (state, { payload, meta }) => {
        if (!payload?.status) {
          return {
            ...state,
            isBookingLoading: false,
            bookingError: payload?.message,
          };
        }

        return {
          ...state,
          isBookingLoading: false,
          bookingSuccess: true,
          shyftPassScheduleData: {
            ...state?.shyftPassScheduleData,
            center_details: state?.shyftPassScheduleData?.center_details?.map(
              (center) => {
                if (center.id === payload?.data?.center_timing) {
                  return {
                    ...center,
                    booking_id: payload?.data?.id,
                    has_user_booked: true,
                  };
                }
                return center;
              }
            ),
          },
        };
      })
      .addCase(actions.bookClass.rejected, (state, { error }) => {
        return {
          ...state,
          isBookingLoading: false,
          bookingError: error,
        };
      })
      // CANCEL CLASS
      .addCase(actions.cancelClass.pending, (state) => ({
        ...state,
        isCancelLoading: true,
        cancelSuccess: false,
        cancelError: "",
      }))
      .addCase(actions.cancelClass.fulfilled, (state, { payload, meta }) => {
        if (!payload?.status) {
          return {
            ...state,
            isCancelLoading: false,
            cancelError: payload?.message,
          };
        }

        return {
          ...state,
          isCancelLoading: false,
          cancelSuccess: true,
          shyftPassScheduleData: {
            ...state?.shyftPassScheduleData,
            center_details: state?.shyftPassScheduleData?.center_details?.map(
              (center) => {
                if (center.id === payload?.data?.center_timing) {
                  return {
                    ...center,
                    booking_id: null,
                    has_user_booked: false,
                  };
                }
                return center;
              }
            ),
          },
        };
      })
      .addCase(actions.cancelClass.rejected, (state, { error }) => {
        return {
          ...state,
          isCancelLoading: false,
          cancelError: error,
        };
      })
      // ADD TO WAITLIST
      .addCase(actions.addToWaitList.pending, (state) => ({
        ...state,
        isWaitListLoading: true,
        waitListSuccess: false,
        waitListError: "",
      }))
      .addCase(actions.addToWaitList.fulfilled, (state, { payload, meta }) => {
        if (!payload?.status) {
          return {
            ...state,
            isWaitListLoading: false,
            waitListError: payload?.message,
          };
        }
        return {
          ...state,
          isWaitListLoading: false,
          waitListSuccess: true,
          shyftPassScheduleData: {
            ...state?.shyftPassScheduleData,
            center_details: state?.shyftPassScheduleData?.center_details?.map(
              (center) => {
                if (center.id === payload?.data?.center_timing) {
                  return {
                    ...center,
                    is_waitlist_joined: true,
                  };
                }
                return center;
              }
            ),
          },
          classList: {
            ...state?.classList,
            center_timings: state?.classList?.center_timings?.map((center) => {
              if (center.id === payload?.data?.center_timing) {
                return {
                  ...center,
                  is_waitlist_joined: true,
                };
              }
              return center;
            }),
          },
        };
      })
      .addCase(actions.addToWaitList.rejected, (state, { error }) => {
        return {
          ...state,
          isWaitListLoading: false,
          waitListError: error,
        };
      });
  },
});

export {
  getShyftPassScheduleData,
  getShyftPassScheduleDataById,
  getShyftPassScheduleByClassSlug,
  bookClass,
  cancelClass,
  addToWaitList,
} from "./Actions";

export const {
  setSelectedDate,
  resetBookingError,
  resetBookingSuccess,
  setShyftPassZoneId,
} = shyftPassSlice.actions;

export default shyftPassSlice.reducer;
