import styled from "styled-components";

import { Box } from "../Box";

export const StyledBackdrop = styled(Box)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000;
  opacity: 0.2;
  cursor: pointer;
  z-index: ${({ zIndex }) => zIndex || 5};
`;
