import { useState, useEffect } from "react";

import theme from "@/foundation/Theme";

import { addKey, appendRazorpayScript } from "@/utils";

import { DEFAULT_API_ERROR_MESSAGE, SCRIPT_LOAD_DELAY } from "@/constants";

import logo from "../../static/logo-mh-razorpay.png";

interface openPaymentModalProps {
  name?: string;
  phone_prefix?: string;
  mobile?: string;
  email?: string;
  amount: number;
  currency: string;
  description: string;
  page_type?: string;
  package_slug?: string;
  purchased_plan_slug?: string;
  package_start_date?: string;
  urls_arguments?: string;
  timezone?: string;
  onDismiss: () => void;
  onSuccess: (any) => void;
  callback?: () => void;
}

function useRazorpay() {
  const [razorPayError, setRazorPayError] = useState("");

  useEffect(() => {
    const timer = setTimeout(appendRazorpayScript, SCRIPT_LOAD_DELAY);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const initiateRazorPayPayment = (razorPayOptions) => {
    const onLoad = () => {
      let rzp = new (window as any).Razorpay(razorPayOptions);
      rzp.open();
    };
    if (!(window as any).Razorpay) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.defer = true;
      document.body.appendChild(script);
      script.onload = onLoad;

      script.onerror = () => {
        setRazorPayError(DEFAULT_API_ERROR_MESSAGE);
      };
    } else {
      onLoad();
    }
  };

  const openPaymentModal = ({
    name = "",
    phone_prefix = "",
    mobile = "",
    email = "",
    amount = 1,
    currency = "INR",
    description = "",
    page_type,
    package_slug,
    purchased_plan_slug,
    package_start_date,
    urls_arguments,
    timezone = "",
    onDismiss = () => {},
    onSuccess = () => {},
    callback = () => {},
    ...rest
  }: openPaymentModalProps) => {
    callback();

    const razorPayOptions = {
      key: process.env.NEXT_PUBLIC_RAZOR_KEY_ID,
      name,
      amount: amount * 100,
      currency,
      description,
      image: logo,
      handler: (response) => {
        if (response.razorpay_payment_id)
          onSuccess({ ...response, amount: amount });
      },
      modal: {
        ondismiss: async () => await onDismiss(),
      },
      theme: {
        color: theme.colorsV2.shyft.purple,
      },
      prefill: {
        name,
        contact: `${phone_prefix}${mobile}`,
        email,
      },
      notes: {
        name,
        primary_contact: `${phone_prefix}${mobile}`,
        ...addKey("email", email),
        package_slug,
        urls_arguments,
        purchased_plan_slug,
        package_start_date,
        page_type,
        timezone,
        ...rest,
      },
      ...rest,
    };

    initiateRazorPayPayment(razorPayOptions);
  };

  return { openPaymentModal, razorPayError };
}

export default useRazorpay;
