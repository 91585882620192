import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/react";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";

import * as actions from "@/redux/AuthReducer";

interface RequestOTPProps {
  countryCode: string;
  mobileNo: string;
  setScreen: (screen: string) => void;
  setError: (error: string) => void;
  setShowToast: (
    toast: { type: string; message: string; duration?: number } | boolean
  ) => void;
}

const useRequestOTP = ({
  countryCode,
  mobileNo,
  setScreen,
  setError,
  setShowToast,
}: RequestOTPProps) => {
  const dispatch = useAppDispatch();

  const requestOTPData = useCallback(
    (data: { mobile: string }) => dispatch(actions.requestOTP({ data })),
    [dispatch]
  );

  const requestOTP = () => {
    setError("");
    if (mobileNo) {
      if (mobileNo?.length >= 7 && mobileNo?.length <= 11) {
        requestOTPData({
          mobile: `${countryCode}${mobileNo}`,
        })
          .then((res) => {
            if (res?.error?.message) {
              const message = !res?.error?.message.startsWith("<!DOCTYPE html>")
                ? res?.error?.message
                : "Something went wrong";
              setError(message || "Something went wrong");
            } else {
              if (res?.payload?.data?.success) {
                setShowToast({
                  type: "success",
                  message: res?.payload?.data?.data?.detail || "OTP Sent",
                  duration: 5000,
                });
                setScreen("verification");
              } else {
                Sentry.captureException(res?.payload?.data);
                setError("Something went wrong");
              }
            }
          })
          .catch((err) => {
            Sentry.captureException(err);
            setError(err?.error?.message || "Something went wrong");
          });
      } else {
        setError("Enter a valid mobile number");
      }
    }
  };

  return {
    requestOTP,
  };
};

interface ValidateOTPProps {
  countryCode: string;
  mobileNo: string;
  otp: string;
  onClose: () => void;
  setLoginToken: (token: string | boolean) => void;
  setError: (error: string) => void;
  setShowToast: (
    toast: { type: string; message: string; duration?: number } | boolean
  ) => void;
}

const useValidateOTP = ({
  otp,
  countryCode,
  mobileNo,
  setLoginToken,
  onClose,
  setError,
  setShowToast,
}: ValidateOTPProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const verifyOtpTokenRequest = useCallback(
    (data: { otp: string; mobile: string }) =>
      dispatch(actions.login({ data })),
    [dispatch]
  );

  const validateOTP = () => {
    setError("");
    verifyOtpTokenRequest({
      otp,
      mobile: `${countryCode}${mobileNo}`,
    })
      .then((res) => {
        if (res?.error) {
          setError(res?.error?.message);
        } else {
          setShowToast({
            type: "success",
            message: "Login Successful",
          });
          localStorage.setItem(
            "LOGIN_TOAST_DATA",
            JSON.stringify({
              type: "success",
              message: "Login Successful",
            })
          );
          setLoginToken(true);
          router.push("/profile/");
          onClose();
        }
      })
      .catch((error) => setError(error?.response?.data?.message)); // User couldn't sign in (bad verification code?)
  };

  return {
    validateOTP,
  };
};

interface LoginProps {
  onClose: () => void;
  setLoginToken: (token: string | boolean) => void;
  setShowToast: (
    toast: { type: string; message: string; duration?: number } | boolean
  ) => void;
}

const useLogin = ({ setShowToast, setLoginToken, onClose }: LoginProps) => {
  const { countryCodeData } = useAppSelector((state) => state.dashboard);
  const [countryCode, setCountryCode] = useState(
    countryCodeData?.default?.code || "+91"
  );
  const [mobileNo, setMobileNo] = useState("");

  const [otp, setOTP] = useState("");

  const [screen, setScreen] = useState("login");

  const [timer, setTimer] = useState(9);
  const [seconds, setSeconds] = useState(60);

  const [resendBtnDisabled, setResendBtnDisabled] = useState(true);

  const [error, setError] = useState("");

  const { requestOTP } = useRequestOTP({
    countryCode,
    mobileNo,
    setScreen,
    setError,
    setShowToast,
  });

  const { validateOTP } = useValidateOTP({
    otp,
    countryCode,
    mobileNo,
    setLoginToken,
    onClose,
    setError,
    setShowToast,
  });

  const handleResendOTP = () => {
    requestOTP();
    setScreen("resendOTP");
    setResendBtnDisabled(true);
    setOTP("");
  };

  const handleOTPChange = (value: string, name: string) => setOTP(value);

  useEffect(() => {
    if (otp?.length === 6) validateOTP();
  }, [otp]);

  useEffect(() => {
    setError("");
  }, [otp, mobileNo]);

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    if (screen === "verification") {
      interval = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    } else if (timer <= 0) {
      setTimer(9);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [screen]);

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    if (resendBtnDisabled && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (resendBtnDisabled && seconds <= 0) {
      setResendBtnDisabled(false);
      setSeconds(60);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [resendBtnDisabled, seconds]);

  return {
    error,
    setError,
    requestOTP,
    validateOTP,
    countryCode,
    setCountryCode,
    mobileNo,
    setMobileNo,
    otp,
    handleOTPChange,
    screen,
    seconds,
    timer,
    handleResendOTP,
    resendBtnDisabled,
  };
};

export default useLogin;
