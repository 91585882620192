import { useState } from "react";
import { useAppDispatch } from "@/hooks";
import {
  promoCodeCheckRequest,
  removePromoCode as removePromoCode,
  resetNutritionCallbackValues as resetLandingPageData,
} from "@/redux/LandingPageReducer";

const usePromoCode = ({
  initialState,
  inputs,
  validateInput,
  isPartnershipPackagePage,
  isValid,
  timezone,
}) => {
  const dispatch = useAppDispatch();

  const [promoCodeButtonText, setPromoCodeButtonText] = useState(
    initialState?.promoCodeButtonText || "APPLY"
  );
  const [promoCode, setPromoCode] = useState(initialState?.promoCode || "");
  const [promoCodeError, setPromoCodeError] = useState(
    initialState?.promoCodeError || ""
  );

  const {
    countryCode,
    mobile,
    goal,
    yogaPackage,
    selectedPackage,
    package_choice,
    package_slug,
    nutrition_goal_id,
  } = inputs;

  const selectedPackSlug = yogaPackage || selectedPackage;

  const applyPromoCodeHelper = () => {
    if (isValid || (validateInput && validateInput())) {
      const apiData = {
        coupon_code: promoCode?.toUpperCase(),
        slug:
          selectedPackSlug && !isPartnershipPackagePage
            ? selectedPackSlug
            : goal,
        mobile: mobile,
        phone_prefix: countryCode,
        is_hero_package: true,
        ...(package_slug && { package_slug }),
        ...(package_choice && { package_choice }),
        ...(nutrition_goal_id && { nutrition_goal_id }),
        timezone,
      };

      dispatch(promoCodeCheckRequest(apiData)).then((payload) => {
        if (payload?.error) {
          setPromoCodeError(payload.error);
        } else {
          setPromoCodeButtonText("REMOVE");
        }
      });
    }
  };

  const applyPromoCode = () => {
    setPromoCodeError("");
    if (!promoCode) {
      setPromoCodeError("Please enter promo code");
      return;
    }
    applyPromoCodeHelper();
  };

  const resetPromoCodeAppliedState = () => {
    setPromoCode("");
    setPromoCodeError("");
    setPromoCodeButtonText("APPLY");
    dispatch(removePromoCode());
  };

  const removeAppliedPromoCode = () => {
    const slugUrl = goal;
    resetPromoCodeAppliedState();
    dispatch(resetLandingPageData(slugUrl, true));
  };

  const handlePromoCodeChange = (event) => {
    removeAppliedPromoCode();
    setPromoCodeButtonText("APPLY");
    setPromoCode(event.target.value);
  };

  return {
    promoCode,
    promoCodeError,
    promoCodeButtonText,
    applyPromoCode,
    removeAppliedPromoCode,
    resetPromoCodeAppliedState,
    handlePromoCodeChange,
  };
};

export default usePromoCode;
