import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

interface DashboardInitialState {
  isSidebarOpen: boolean;
  sidebarData: { [key: string]: any } | null;
  isModalOpen: boolean;
  modalData: { [key: string]: any } | null;
}

const initialState: DashboardInitialState = {
  isSidebarOpen: false,
  sidebarData: null,
  isModalOpen: false,
  modalData: null,
  isCountryCodeLoading: false,
  countryCodeSuccess: false,
  countryCodeError: "",
  countryCodeData: null,
  isRibbonDataLoading: false,
  ribbonDataSuccess: false,
  ribbonDataError: false,
  ribbonData: null,
  routeChangeLoading: false,
  headerHeight: 135,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setIsSidebarOpen(state, action) {
      return {
        ...state,
        isSidebarOpen: action?.payload.value,
        ...(action?.payload.data ? { sidebarData: action?.payload.data } : {}),
      };
    },
    setIsModalOpen(state, action) {
      return {
        ...state,
        isModalOpen: action?.payload.value,
        ...(action?.payload.data ? { modalData: action?.payload.data } : {}),
      };
    },
    setRouteChangeLoading(state, action) {
      return {
        ...state,
        routeChangeLoading: action?.payload,
      };
    },
    setHeaderHeight(state, action) {
      return {
        ...state,
        headerHeight: action?.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => {
        const {
          isRibbonDataLoading,
          ribbonDataSuccess,
          ribbonDataError,
          ribbonData,
        } = payload.dashboard || {};
        return {
          ...state,
          isRibbonDataLoading,
          ribbonDataSuccess,
          ribbonDataError,
          ribbonData,
        };
      })
      // GET COUNTRY CODE DATA
      .addCase(actions.getCountryCodes.pending, (state) => ({
        ...state,
        isCountryCodeLoading: true,
        countryCodeSuccess: false,
        countryCodeError: "",
      }))
      .addCase(actions.getCountryCodes.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isCountryCodeLoading: false,
          countryCodeSuccess: true,
          countryCodeData: payload?.data,
        };
      })
      .addCase(actions.getCountryCodes.rejected, (state, { payload }) => ({
        ...state,
        isCountryCodeLoading: false,
        countryCodeError: payload,
      }))
      // GET RIBBON DATA
      .addCase(actions.getRibbonData.pending, (state) => ({
        ...state,
        isRibbonDatLoading: false,
        ribbonDataSuccess: false,
        ribbonDatError: "",
      }))
      .addCase(actions.getRibbonData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isRibbonDatLoading: false,
          ribbonDataSuccess: true,
          ribbonData: payload?.data?.meta,
        };
      })
      .addCase(actions.getRibbonData.rejected, (state, { payload }) => ({
        ...state,
        isRibbonDatLoading: false,
        ribbonDatError: payload || null,
      }));
  },
});

export const {
  setIsSidebarOpen,
  setIsModalOpen,
  setRouteChangeLoading,
  setHeaderHeight,
} = dashboardSlice.actions;

export { getCountryCodes, getRibbonData } from "./Actions";

export default dashboardSlice.reducer;
