import Typography from "@/foundation/Typography/V2";

import SVGSprite from "../../SVGSprite";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./ErrorUI.module.scss";

interface ErrorUIProps {
  className?: string;
  error?: string;
}

const ErrorUI: React.FC<ErrorUIProps> = ({ className, error = "" }) => {
  const cls = classNameGenerator(styles);

  return error ? (
    <div className={clsx(cls("error"), className)}>
      <SVGSprite className={cls("icon")} icon="icon-info-circle" />
      <Typography variant="paragraph1" className={cls("errorMessage")}>
        {error}
      </Typography>
    </div>
  ) : (
    <></>
  );
};

export default ErrorUI;
