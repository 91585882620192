import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios, timezone as tz } from "@/utils";

const _timezone = tz || "Asia/Calcutta";

export const getYogaProgramPage = createAsyncThunk(
  "getYogaProgramPage",
  ({ slug, ignoreTimezone, sendYogaSlug = false }) => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}landingpage/${slug}/`;
    if (!ignoreTimezone) url = `${url}?timezone=${_timezone}`;
    if (sendYogaSlug) {
      url += "&form_services_slug_type=yoga_plan_slug";
    }
    return axios.get(url);
  }
);
