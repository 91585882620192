import Typography from "@/foundation/Typography/V2";

import { classNameGenerator } from "@/utils";

import styles from "./InfoText.module.scss";

interface InfoTextProps {}

const InfoText: React.FC<InfoTextProps> = (props) => {
  const cls = classNameGenerator(styles);

  return <Typography variant="paragraph1" className={cls("info")} {...props} />;
};

export default InfoText;
