import React, { useEffect, useState, createRef } from "react";
import OtpInput from "react-otp-input";
import Captcha from "react-google-recaptcha";

import { FlexDiv } from "@/components/Atoms/Box";
import SVGSprite from "@/components/Atoms/SVGSprite";
import ErrorUI from "@/components/Atoms/ErrorUI";
import ModalMotion, {
  AnimateDirection,
} from "@/components/Molecules/ModalMotion";
import PhoneInput from "./PhoneInput";
import useLogin from "../useLogin";

import { isMobile, timezone } from "@/utils";
import { useAppSelector } from "@/hooks";

import Typography from "@/foundation/Typography/V2";
import theme from "@/foundation/Theme";

import {
  ResendOTP,
  LoginWrapper,
  InnerWrapper,
  HeadingTitle,
  Subtitle,
  TimerCountdown,
  ShadowBtn,
  OTPBox,
  Caption,
} from "./style";

function Login({ onClose = () => {}, setLoginToken, setShowToast }) {
  const reCaptchaRef = createRef();
  const isUnrestrictedUsers =
    timezone === "Asia/Calcutta" ||
    timezone === "Asia/Kolkata" ||
    timezone === "Asia/Dubai";

  const [isScriptLoaded, setIsScriptLoaded] = useState(isUnrestrictedUsers);

  const [btnPos, setBtnPos] = useState({
    top: "84px",
    right: "86px",
  });

  const { isOTPLoading, isLoading } = useAppSelector((state) => state.auth);

  const {
    error,
    setError,
    requestOTP,
    validateOTP,
    countryCode,
    setCountryCode,
    mobileNo,
    setMobileNo,
    otp,
    handleOTPChange,
    screen,
    seconds,
    timer,
    handleResendOTP,
    resendBtnDisabled,
  } = useLogin({
    setShowToast,
    setLoginToken,
    onClose,
  });

  const showPhoneInput = screen === "login";

  const isOTPSent = screen === "resendOTP" || screen === "verification";

  const title = isOTPSent
    ? "OTP Verification"
    : "Login using your phone number";

  const secondaryTitle = isOTPSent && "OTP Sent!";

  const subtitle = isOTPSent
    ? `We've sent an OTP to ${countryCode} ${mobileNo}, please enter it below to process`
    : "We'll use the details you enter below to create your Shyft account";

  const onSubmit = async (event) => {
    event.preventDefault();
    let token = "";
    if (!isUnrestrictedUsers) {
      reCaptchaRef?.current?.reset();
      try {
        token = await reCaptchaRef?.current?.executeAsync();
      } catch (error) {
        console.log("[log-catch]", error);
      }
    }
    if (isUnrestrictedUsers || token) {
      screen === "login" ? requestOTP() : validateOTP();
    } else {
      setError("Multiple Failed attempts, please refresh the page!");
    }
  };

  const getIsButtonDisabled = () => {
    let isButtonDisabled = false;
    if (showPhoneInput && (mobileNo.length > 11 || mobileNo.length < 7)) {
      isButtonDisabled = true;
    } else if (isOTPSent) {
      isButtonDisabled = true;
    }
    return isButtonDisabled;
  };

  const getButtonText = () => {
    let buttonText = false;

    if (showPhoneInput) {
      if (mobileNo.length <= 11 && mobileNo.length >= 7) {
        buttonText = "Request OTP";
      } else {
        buttonText = "Login";
      }
    } else if (isOTPSent) {
      if (otp.length === 6 && error === "") {
        buttonText = "Submitting...";
      } else {
        buttonText = "Submit";
      }
    }
    return buttonText;
  };

  useEffect(() => {
    let btnEl = document.getElementById("login-btn");
    let btn = btnEl?.getBoundingClientRect();
    let btnTopPos = btn?.top + btn?.height + 16;

    if (btnEl != undefined) {
      setBtnPos({
        top: `${btnTopPos}px`,
        right: `calc(100% - ${btn?.right}px)`,
      });
    }
  }, []);

  const resendOTP = isOTPSent && (
    <FlexDiv mt="18px" alignItems="center">
      {resendBtnDisabled ? (
        <TimerCountdown
          ml="5px"
          variant="span"
        >{`You can resend otp in ${seconds} second${
          seconds >= 1 ? "s" : ""
        }`}</TimerCountdown>
      ) : (
        <ResendOTP
          onClick={() => handleResendOTP()}
          TextColor={theme.colors.link2Color}
          variant="link"
          fontFamily="var(--man-700)"
          btnPadding="0px"
          disabled={resendBtnDisabled}
          fontSize={["14px", "15px", "16px"]}
          lineHeight={["20px", "20px", "24px"]}
        >
          Resend OTP
        </ResendOTP>
      )}
    </FlexDiv>
  );

  const phoneInput = showPhoneInput && (
    <PhoneInput
      countryCode={countryCode}
      setCountryCode={setCountryCode}
      mobileNo={mobileNo}
      setMobileNo={setMobileNo}
      onSubmit={onSubmit}
      error={error}
    />
  );

  const otpInput = isOTPSent && (
    <OTPBox mb="32px" error={error}>
      <OtpInput
        containerStyle="otpWrapper"
        value={otp}
        onChange={(otp) => handleOTPChange(otp, "otp")}
        numInputs={6}
        isInputNum
        inputStyle="input-box"
        shouldAutoFocus
      />
      {error && <ErrorUI error={error} />}
    </OTPBox>
  );

  return (
    <ModalMotion
      overlayClosable={screen === "login"}
      onClose={onClose}
      width="423px"
      animateDirection={
        isMobile ? AnimateDirection.CENTER : AnimateDirection.RIGHT_TOP
      }
      borderRadius="10px"
      showClose
      iconTopPosition="20px"
      iconRightPosition="20px"
      btnPos={btnPos}
      bodyPadding={"40px"}
    >
      <LoginWrapper>
        <InnerWrapper maxWidth={screen === "login" ? "320px" : "336px"}>
          <HeadingTitle variant="heading3">
            {screen === "verification" && timer >= 0 ? (
              <>
                <SVGSprite
                  icon="icon-success-check"
                  height="20px"
                  width="20px"
                  fill={theme.colors.green}
                  mr="7px"
                />
                {secondaryTitle}
              </>
            ) : (
              title
            )}
          </HeadingTitle>
          <Subtitle mb={screen === "login" ? "48px" : "32px"} variant="label1">
            {subtitle}
          </Subtitle>
          {phoneInput}
          {otpInput}
          <ShadowBtn
            btnPadding="14px 40px"
            variant="primary1"
            disabled={!isScriptLoaded || getIsButtonDisabled()}
            onClick={onSubmit}
            isLoading={isOTPLoading || isLoading}
            minHeight="56px"
            bgColor={theme.colorsV2.shyft.purple_text}
            hoverColor={theme.colorsV2.shyft.purple_text}
            fontSize={["14px", "15px", "16px"]}
            fontFamily={"var(--man-700)"}
            minWidth={"123px"}
          >
            {getButtonText()}
          </ShadowBtn>
          {resendOTP}
          {!isUnrestrictedUsers && (
            <>
              <Captcha
                style={{ visibility: "hidden" }}
                ref={reCaptchaRef}
                size="invisible"
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                asyncScriptOnLoad={() => setIsScriptLoaded(true)}
              />
              <Caption variant="paragraph1">
                This site is protected by reCAPTCHA and the Google{" "}
                <Typography
                  variant="link2"
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >
                  Privacy Policy
                </Typography>{" "}
                and{" "}
                <Typography
                  variant="link2"
                  href="https://policies.google.com/terms"
                  target="_blank"
                >
                  Terms of Service
                </Typography>{" "}
                apply.
              </Caption>
            </>
          )}
        </InnerWrapper>
      </LoginWrapper>
    </ModalMotion>
  );
}

export default Login;
