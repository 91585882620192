import { useCallback, useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";

import { FlexDiv } from "@/components/Atoms/Box";

import { useAppDispatch } from "@/hooks";
import {
  appendClarityScript,
  appendGoogleAnalyticsScript,
  appendGoogleTagManagerScript,
  getCanonicalURL,
} from "@/utils";
import { FACEBOOK_PIXEL_ID, GOOGLE_ANALYTICS_ID } from "@/config";
import {
  localBusinessData,
  organizationData,
} from "@/constants/OrganizationData";

import * as dashboardActions from "@/redux/DashboardReducer";

import fontsClassName, { fontsClassNameList } from "@/styles/fonts";

const ComponentWrapper = ({ children }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    appendGoogleAnalyticsScript();
    appendGoogleTagManagerScript();
    appendClarityScript()
  }, []);

  const getCountryCodes = useCallback(
    () => dispatch(dashboardActions.getCountryCodes()),
    [dispatch]
  );

  const setRouteChangeLoading = useCallback(
    (val) => dispatch(dashboardActions.setRouteChangeLoading(val)),
    [dispatch]
  );

  useEffect(() => {
    getCountryCodes();
  }, [getCountryCodes]);

  useEffect(() => {
    const handleStart = (url) =>
      url !== router.asPath && setRouteChangeLoading(true);
    const handleComplete = (url) => setRouteChangeLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  });

  useEffect(() => {
    document.body.classList.add(...fontsClassNameList);
  });

  useEffect(() => {
    import("react-facebook-pixel")
      .then((comp) => comp.default)
      .then((ReactPixel) => {
        ReactPixel.init(
          FACEBOOK_PIXEL_ID,
          {},
          {
            autoConfig: true,
            debug: true,
          }
        );
      });
  }, []);

  const canonicalURL = getCanonicalURL();

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>Shyft - Health & Wellness Platform | Shyft Health</title>
        <meta
          name="description"
          content="Shyft provides personalized wellness solutions for specific health conditions like pregnancy care, chronic ailments, etc. through nutrition, yoga & meditation."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#56369c" />
        <meta
          name="facebook-domain-verification"
          content="gbewuc47z09ff7h7nkgpedyaaipdwp"
        />
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" href="/favicon.ico" />
        {typeof window !== "undefined" && (
          <>
            <link rel="canonical" href={canonicalURL} key="canonical" />
            <meta property="og:url" key="og:url" content={canonicalURL} />
          </>
        )}
        {/* TODO: */}
        {false && <meta name="robots" content="noindex, nofollow" />}
      </Head>
      <Script
        id="organizationData"
        defer
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationData) }}
      />
      <Script
        id="localBusinessData"
        defer
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(localBusinessData),
        }}
      />

      {/* <!-- For Component and Client View --> */}
      <Script src="https://source.zoom.us/2.18.2/lib/vendor/react.min.js" />
      <Script src="https://source.zoom.us/2.18.2/lib/vendor/react-dom.min.js" />
      <Script src="https://source.zoom.us/2.18.2/lib/vendor/redux.min.js" />
      <Script src="https://source.zoom.us/2.18.2/lib/vendor/redux-thunk.min.js" />
      <Script src="https://source.zoom.us/2.18.2/lib/vendor/lodash.min.js" />

      {/* <!-- For Client View --> */}
      {/* <Script src="https://source.zoom.us/zoom-meeting-2.18.2.min.js" /> */}
      <FlexDiv
        className={["main-wrapper", fontsClassName].join(" ")}
        flexDirection="column"
      >
        {children}
      </FlexDiv>
    </>
  );
};

export default ComponentWrapper;
