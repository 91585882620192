import { useMemo } from "react";
import { useRouter } from "next/router";

import { generateUrlArguments, timezone } from "@/utils";

import useForm from "@/hooks/useHookForm";
import useFormPayment from "@/hooks/useFormPayment";

import { useAppSelector } from "@/hooks/useRedux";

interface useCallbackFormProps {
  packData?: any;
  setIsFormSubmitted?: (isFormSubmitted: boolean) => void;
}

const useCallbackForm = ({
  packData,
  setIsFormSubmitted = () => {},
}: useCallbackFormProps) => {
  const router = useRouter();

  const { query } = router || {};

  let slug =
    typeof query?.slug === "string" ? query?.slug : query?.slug?.[0] || "";

  const { countryCodeData } = useAppSelector((state) => state.dashboard);
  const { data } = useAppSelector((state) => state.bootCamp);

  const isILAPage = slug === "indians-living-abroad";

  let countryCodeDefault = useMemo(
    () => countryCodeData?.default?.code || "+91",
    [countryCodeData]
  );

  let _timezone;
  if (isILAPage) {
    _timezone = timezone === "Asia/Dubai" ? timezone : "US/Central";
    countryCodeDefault =
      countryCodeDefault === "+91" ? "+1" : countryCodeDefault;
  }

  const { nutritionGoalID } = packData || {};

  const defaultValues = useMemo(
    () => ({
      name: "",
      mobileCountryCode: countryCodeDefault,
      mobile: "",
      ...(nutritionGoalID ? { nutritionGoalID } : {}),
      joinCommunity: true,
    }),
    [countryCodeDefault, nutritionGoalID]
  );

  const urlsArguments = generateUrlArguments();

  const { methods, resetData } = useForm({
    defaultValues,
  });

  const { isLoading, onBlur, onRequestCallback } = useFormPayment({
    methods,
    packData,
    urlsArguments,
    timezone,
  });

  const { handleSubmit } = methods;

  const requestCallbackHandler = handleSubmit((formValues) => {
    onRequestCallback(formValues).then(() => {
      setIsFormSubmitted(true);
    });
  });

  return {
    methods,
    isLoading,
    onBlur,
    requestCallbackHandler,
  };
};

export default useCallbackForm;
