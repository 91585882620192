import React from "react";

import { StyledBackdrop } from "./style";

interface BackdropProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  [rest: string]: any;
}

const Backdrop: React.FC<BackdropProps> = ({ onClick, ...rest }) => {
  return <StyledBackdrop onClick={onClick} {...rest} />;
};

export default Backdrop;
