import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios, timezone as tz } from "@/utils";

import webRibbonData from "./webRibbonData";
import countryCodeData from "./countryCodeData";

const _timezone = tz || "Asia/Calcutta";

export const getCountryCodes = createAsyncThunk("getCountryCodes", () => {
  let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/country_code_list/?timezone=${_timezone}`;
  axios.defaults.headers.get["CustomerTimezone"] = _timezone;
  return axios.get(url);
});

export const getRibbonData = createAsyncThunk<any, { pathname?: string }>(
  "getRibbonData",
  ({ pathname }) => {
    // return Promise.resolve(webRibbonData);
    let url = `${
      process.env.NEXT_PUBLIC_LOCAL_API_URL
    }api/v1/module-header/web-ribbon/${
      pathname ? `?pathname=${pathname}` : ""
    }`;
    return axios.get(url);
  }
);
