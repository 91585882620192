import React, { useState } from "react";

import { DesktopContainer, MobileContainer } from "@/components/Atoms/Box";
import Image from "@/components/Atoms/Image";

import { isMobile } from "@/utils";

import { StyledRibbon, Text } from "./style";
import CallbackModal from "@/containers/BootCampPages/Components/CallbackModal";

export interface RibbonPropsI {
  ribbon_background?: String;
  text_color?: String;
  content?: String;
  content_mobile?: String;
  icon_url?: String;
  icon_mobile_url?: String;
  icons_height?: String;
  icon_width?: String;
  icon_position?: String;
}

const Ribbon: React.FC<RibbonPropsI> = ({
  ribbon_background: backgroundColor,
  text_color: textColor,
  content,
  content_mobile: contentMobile,
  icon_url: iconSrc,
  icon_mobile_url: iconMobileSrc,
  icons_height: iconHeight,
  icon_width: iconWidth,
  icon_position
}) => {
  const [showCallbackModal, setShowCallbackModal] = useState(false);

  const formTitle = "Speak to our team!";
  const formSubtitle = "You'll get a call back in 24 hours!";
  const showMobileImage = isMobile && iconMobileSrc;
  const showMobileContent = isMobile && contentMobile;

  const iconImage = (iconSrc || iconMobileSrc) && (
    <Image
      alt="icon-image"
      src={showMobileImage ? iconMobileSrc : iconSrc}
      height={iconHeight}
      width={iconWidth}
    />
  );
  
  return (
    <>
      <StyledRibbon backgroundColor={backgroundColor}>
        {iconImage}
        <Text
          onClick={() => setShowCallbackModal(true)}
          variant="display1"
          fontFamily="var(--man-600)"
          fontSize={["12px", "12px", "14px"]}
          lineHeight={["16px", "20px", "24px"]}
          py={isMobile ? "16px" : "12px"}
          color={textColor}
          dangerouslySetInnerHTML={{
            __html: showMobileContent ? contentMobile : content,
          }}
        />
        {iconImage && <MobileContainer>{iconImage}</MobileContainer>}
        {icon_position === "BOTH_SIDE" && (
          <DesktopContainer>{iconImage}</DesktopContainer>
        )}
      </StyledRibbon>
      <CallbackModal
        title={formTitle}
        subtitle={formSubtitle}
        showModal={showCallbackModal}
        setShowModal={setShowCallbackModal}
      />
    </>
  );
};

export default Ribbon;
