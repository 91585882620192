import { useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm as useReactHookForm } from "react-hook-form";
import * as yup from "yup";

export const formSchema = (defaultValues = {}) => {
  const keys = Object.keys(defaultValues);

  return yup
    .object({
      ...(keys?.includes("name") && {
        name: yup
          .string()
          .required("Please enter your name")
          .min(3, "Please enter full name"),
      }),
      ...(keys?.includes("mobile") && {
        mobile: yup
          .string()
          .required("Please enter a valid phone number")
          .min(8, "Number should be minimum 8 characters")
          .max(14, "Number should be maximum 14 characters"),
      }),
      ...(keys?.includes("email") && {
        email: yup
          .string()
          .email("Please enter a valid email address")
          .required("Please enter a valid email address"),
      }),
      ...(keys?.includes("healthCondition") && {
        healthCondition: yup
          .string()
          .required("Please select a health condition"),
      }),
      ...(keys?.includes("selectedPack") && {
        selectedPack: yup.string().required("Please select a service"),
      }),
      ...(keys?.includes("mhGoal") && {
        mhGoal: yup.string().required("Please select one option"),
      }),
      // ...(keys?.includes("nutritionGoalID") && {
      //   nutritionGoalID: yup.string().required("Please select focus area"),
      // }),
      ...(keys?.includes("promoCode") && {
        promoCode: yup.string(),
      }),
    })
    .required();
};

const useHookForm = ({ defaultValues }) => {
  const resolver = useMemo(
    () => yupResolver(formSchema(defaultValues)),
    [defaultValues]
  );

  const methods = useReactHookForm({
    resolver,
    defaultValues,
    mode: "onBlur",
  });

  const { reset } = methods;

  useEffect(() => {
    // reset(defaultValues);
  }, [reset, defaultValues]);

  const resetData = () => reset(defaultValues);

  return {
    methods,
    resetData,
  };
};

export default useHookForm;
