import { Control, Controller } from "react-hook-form";

import Input from "@/components/Atoms/InputsV2/RadioInput";
import ErrorUI from "@/components/Atoms/InputsV2/ErrorUI";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./RadioGroup.module.scss";

interface RadioGroupProps {
  name: string;
  control: Control<any>;
  placeholder?: string;
  className?: string;
  onBlur?: (e: any) => void;
  fields?: { value: string; label: string }[];
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  control,
  onBlur = () => {},
  fields,
  className,
  ...rest
}) => {
  const cls = classNameGenerator(styles);

  return (
    <div className={clsx(cls("radioGroup"), className)}>
      <Controller
        name={name}
        control={control}
        shouldUnregister
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            {fields?.map((val, index) => (
              <Input
                key={index}
                id={val?.value}
                label={val?.label}
                checked={value === val?.value}
                onChange={(e) => onChange(val?.value)}
                {...rest}
              />
            ))}
            <ErrorUI error={error?.message} />
          </>
        )}
      />
    </div>
  );
};

export default RadioGroup;
