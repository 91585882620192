import React from "react";

import SVGSprite from "@/components/Atoms/SVGSprite";

import Typography from "@/foundation/Typography/V2";
import theme from "@/foundation/Theme";

import { ErrorDiv } from "./style";

const ErrorUI = ({
  error = "",
  color = theme.colorsV2.shyft.error,
  variant = "label4",
  icon = "icon-info-circle-2",
  iconDimension = "16px",
  mr = "8px",
  hasBottomBorder = false,
  isNotAbsolute = false,
  fontSize,
  lineHeight,
  ...rest
}) => (
  <ErrorDiv
    hasBottomBorder={hasBottomBorder}
    alignItems="center"
    position={isNotAbsolute ? "static" : "absolute"}
    className="form-input-error"
    {...rest}
  >
    <SVGSprite
      icon={icon}
      height={iconDimension}
      width={iconDimension}
      fill={color}
      mr={mr}
    />
    <Typography
      variant={variant}
      color={color}
      flex={1}
      dangerouslySetInnerHTML={{ __html: error }}
      fontSize={fontSize}
      lineHeight={lineHeight}
    />
  </ErrorDiv>
);

export default ErrorUI;
