import React from "react";
import styled from "styled-components";

import { FlexContainer, FlexDiv } from "@/components/Atoms/Box";
import SVGSprite from "@/components/Atoms/SVGSprite";
import Button from "@/components/Atoms/Button";
import Header from "@/components/Organisms/Navbar";

import theme from "@/foundation/Theme";
import store from "@/redux/Store";

function ErrorPageFallbackView(props) {
  const { history, fromTopErrorBoundary, hideHeader } = props || {};

  const state = store?.getState();
  const API_ERROR = state?.auth?.error;

  const showRedirectionButton =
    !fromTopErrorBoundary && !hideHeader && history?.length > 1;

  return (
    <FlexDiv height="100vh" maxWidth="100vw" flexDirection="column">
      {!hideHeader && !fromTopErrorBoundary && <Header {...props} />}

      <FlexContainer height="100%" flexDirection="column">
        {fromTopErrorBoundary && (
          <FlexDiv alignItems={"center"} py="16px" px="25px">
            <SVGSprite
              icon={"icon-brand-logo-light"}
              fill={theme.colors.brand_logo}
              width={"66px"}
              height={"28px"}
            />
          </FlexDiv>
        )}
        <FlexDiv justifyContent="center" height="100%" flexDirection="column">
          <FlexDiv flexDirection="column" alignItems="center">
            <SVGSprite
              icon={"icon-maintenance"}
              fill={theme.colorsV2.indigo.shade_400}
              height="80px"
              width="80px"
            />
            <FlexDiv flexDirection="column" alignItems="center">
              <Heading>Oops</Heading>

              <SubHeading mb={["20px", "25px", "25px"]}>
                {API_ERROR
                  ? "You have hit the wall"
                  : "Temporarily down for maintenance."}
              </SubHeading>

              <Content>
                {API_ERROR
                  ? "Sorry for inconvenience"
                  : "We should be back online shortly."}
              </Content>
              {showRedirectionButton && (
                <Button
                  variant="outline"
                  borderRadius="50px"
                  fontSize={["14px", "18px", "20px"]}
                  borderColor={theme.colors.Text_1}
                  TextColor={theme.colors.Text_1}
                  fontFamily="var(--man-500)"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Go to last Page
                </Button>
              )}
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
      </FlexContainer>
    </FlexDiv>
  );
}

export default ErrorPageFallbackView;

const Heading = styled.h2`
  font-size: 14px;
  line-height: 32px;
  font-family: var(--man-700);
  color: ${theme.colors.Text_1};
  @media only screen and (min-width: 601px) {
    font-size: 18px;
    line-height: 36px;
  }

  @media only screen and (min-width: 1025px) {
    font-size: 20px;
    line-height: 40px;
  }
`;

const SubHeading = styled.h4`
  font-size: 16px;
  line-height: 24px;
  font-family: var(--man-700);
  color: ${theme.colors.Text_1};

  @media only screen and (min-width: 601px) {
    font-size: 20px;
    line-height: 32px;
  }

  @media only screen and (min-width: 1025px) {
    font-size: 24px;
    line-height: 40px;
  }
`;

const Content = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-family: var(--man-700);
  text-align: center;
  color: ${theme.colors.Text_1};

  @media only screen and (min-width: 601px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media only screen and (min-width: 1025px) {
    font-size: 20px;
    line-height: 32px;
  }
`;
