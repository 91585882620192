import { useCallback } from "react";

import ModalMotion, {
    AnimateDirection,
} from "@/components/Molecules/ModalMotion";

import Typography from "@/foundation/Typography/V2";

import { classNameGenerator } from "@/utils";

import { useAppDispatch, useAppSelector } from "@/hooks";
import * as actions from "@/redux/BootCampReducer";

import styles from "./TncModal.module.scss";
import styled from "styled-components"

interface TncModalProps {
    title: string;
    description: string;
    points?: any;
}

const TncModal: React.FC<TncModalProps> = ({ title, description, points }) => {
    const dispatch = useAppDispatch();

    const cls = classNameGenerator(styles);

    const { showTncModal } = useAppSelector((state) => state.bootCamp);

    const setShowTncModal = useCallback(
        (value: any) => {
            dispatch(actions.setShowTncModal(value));
        },
        [dispatch]
    );

    return showTncModal ? (
        <ModalMotion
            overlayClosable
            onClose={() => setShowTncModal(false)}
            width="520px"
            animateDirection={AnimateDirection.CENTER}
            borderRadius="10px"
            showClose
            iconTopPosition="20px"
            iconRightPosition="20px"
            className={cls("tncModal")}
        >
            <Typography
                variant="paragraph1"
                className={cls("title")}
                dangerouslySetInnerHTML={{ __html: title }}
            />
            <Typography
                variant="paragraph1"
                className={cls("description")}
                dangerouslySetInnerHTML={{ __html: description }}
            />
            <List>
                {
                    points.map((_val) => {
                        return (
                            <Item>
                                {_val}
                            </Item>
                        )
                    })
                }
            </List>
        </ModalMotion>
    ) : (
        <></>
    );
};

const List = styled.ol`
    padding: 16px;
    padding-bottom: 0px;
    list-style-type: decimal; 
    margin: 0;
    margin-left: 8px;
`
const Item = styled.li`
    font-size: 16px;
    line-height: 32px;
    font-family: var(--man-600)
`

export default TncModal;
