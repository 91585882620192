import React, { useEffect, useState } from "react";
import styled from "styled-components";
import dynamic from "next/dynamic";

const LottieLoader = dynamic(() =>
  import("@/components/Molecules/FullScreenLoader").then(
    (module) => module.LottieLoader
  ),
  { ssr: false }
);

import {
  border,
  color,
  flexbox,
  layout,
  shadow,
  space,
  typography,
} from "styled-system";

import { Box } from "../Box";
import SVGSprite from "../SVGSprite";

import theme from "@/foundation/Theme";
import { isMobile } from "@/utils";
import { useAppSelector } from "@/hooks";

const noop = () => {};

export default function Button(props) {
  const {
    onClick = noop,
    isLoading = false,
    version = "v1",
    children,
    style = {},
    fontFamily = "",
    variant = "primary",
    bgColor = theme.colors.link2Color,
    borderColor = variant === "outline"
      ? theme.colors.black
      : theme.colors.link2Color,
    borderRadius = "76px",
    hoverColor = theme.colors.link2Color,
    type = undefined,
    disabled = false,
    className,
    btnPadding = variant === "outline" ? "1.75px 12px" : "11px 20px",
    width = variant === "icon1" ? "39px" : "",
    icon = "",
    iconDimension = "13px",
    iconFill = theme.colors.white,
    gradientColor = `radial-gradient(${theme.colors.radial_gradient2})`,
    btnTextStyles = {},
    id = undefined,
    TextColor = variant === "outline" || variant === "link"
      ? theme.colors.black
      : theme.colors.white,
    textBold = false,
    fontSize = variant === "outline" ? "13px" : "15px",
    iconbtnpadding = "0 7.5px 0 0",
    loaderHeight = "64px",
    loaderWidth = "64px",
    primaryBorderColor = "transparent",
    ...rest
  } = props || {};
  
  const [isPageLoaded,setIsPageLoaded] = useState(true)
  const { isLoading: dataLoading }  = useAppSelector((state) => state.bootCamp);

  useEffect(() => {
    setIsPageLoaded(dataLoading)
  },[dataLoading])

  return (
    <>
      {variant === "primary" && (
        <ButtonPrimary
          variant={variant}
          onClick={onClick}
          style={{ ...style }}
          type={type}
          disabled={isPageLoaded || disabled}
          className={className}
          width={width}
          id={id}
          bgColor={bgColor}
          borderColor={"transparent"}
          borderRadius={borderRadius}
          hoverColor={hoverColor}
          btnPadding={btnPadding}
          fontSize={fontSize}
          fontFamily={fontFamily}
          version={version}
          {...rest}
        >
          {isLoading && (
            <LottieLoader
              showWhiteLoader
              loaderHeight={loaderHeight}
              loaderWidth={loaderWidth || "50px"}
              isClickToPauseDisabled={true}
            />
          )}
          {!isLoading && children && (
            <StyledText
              color={TextColor}
              textBold={textBold}
              {...btnTextStyles}
            >
              {children}
            </StyledText>
          )}
        </ButtonPrimary>
      )}
      {variant === "primary1" && (
        <ButtonPrimary1
          variant={variant}
          onClick={onClick}
          style={{ ...style }}
          type={type}
          disabled={isPageLoaded || disabled}
          className={className}
          width={width}
          id={id}
          bgColor={bgColor}
          borderColor={primaryBorderColor}
          borderRadius={borderRadius}
          hoverColor={hoverColor}
          btnPadding={btnPadding}
          fontSize={fontSize}
          fontFamily={fontFamily}
          version={version}
          {...rest}
        >
          {isLoading && (
            <LottieLoader
              showWhiteLoader
              loaderHeight={loaderHeight}
              loaderWidth={loaderWidth || "50px"}
              isClickToPauseDisabled={true}
            />
          )}
          {!isLoading && children && (
            <StyledText
              color={TextColor}
              textBold={textBold}
              {...btnTextStyles}
            >
              {children}
            </StyledText>
          )}
        </ButtonPrimary1>
      )}
      {variant === "icon1" && (
        <Icon1
          variant={variant}
          onClick={onClick}
          style={{ ...style }}
          type={type}
          disabled={isPageLoaded || disabled}
          className={className}
          id={id}
          bgColor={bgColor}
          borderColor={borderColor}
          borderRadius={borderRadius}
          hoverColor={hoverColor}
          btnPadding={btnPadding}
          fontSize={fontSize}
          fontFamily={fontFamily}
          iconDimension={iconDimension}
          iconbtnpadding={iconbtnpadding}
          isMobile={isMobile}
          version={version}
          {...rest}
        >
          <Box position="relative" className="icon-wrapper">
            <SVGSprite
              icon={icon}
              height={iconDimension}
              width={iconDimension}
              fill={iconFill}
            />
          </Box>
          {!isMobile && children && (
            <IconText
              color={TextColor}
              textBold={textBold}
              gradientColor={gradientColor}
              variant={variant}
              {...btnTextStyles}
            >
              {children}
            </IconText>
          )}
        </Icon1>
      )}
      {variant === "outline" && (
        <ButtonOutline
          variant={variant}
          onClick={onClick}
          style={{ ...style }}
          type={type}
          disabled={isPageLoaded || disabled}
          className={className}
          width={width}
          id={id}
          borderColor={borderColor}
          borderRadius={borderRadius}
          hoverColor={hoverColor}
          btnPadding={btnPadding}
          fontSize={fontSize}
          fontFamily={fontFamily}
          version={version}
          {...rest}
        >
          {children && (
            <OutlineText
              color={TextColor}
              textBold={textBold}
              {...btnTextStyles}
            >
              {children}
            </OutlineText>
          )}
        </ButtonOutline>
      )}
      {variant === "link" && (
        <ButtonPrimary
          variant={variant}
          onClick={onClick}
          style={{ ...style }}
          type={type}
          disabled={isPageLoaded || disabled}
          className={className}
          width={width}
          id={id}
          bgColor={bgColor}
          borderColor={borderColor}
          borderRadius={borderRadius}
          hoverColor={hoverColor}
          btnPadding={btnPadding}
          fontSize={fontSize}
          fontFamily={fontFamily}
          version={version}
          {...rest}
        >
          {children && (
            <StyledText
              color={TextColor}
              textBold={textBold}
              {...btnTextStyles}
            >
              {children}
            </StyledText>
          )}
        </ButtonPrimary>
      )}
    </>
  );
}
const Buttons = styled.button`
  ${color}
  ${border}
    ${flexbox}
    ${layout}
    ${space}
    ${shadow}
    ${typography}
    font-family:${({ fontFamily }) => fontFamily};
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  [role="button"] {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
const ButtonOutline = styled(Buttons)`
  background-color: transparent;
  border: 2px solid;
  padding: ${({ btnPadding }) => btnPadding};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "0px")};
  &:hover {
    background-color: transparent;
    border-color: ${({ borderColor }) => borderColor};
  }
  &:disabled {
      opacity: 0.3;
  }
`;
const Icon1 = styled(Buttons)`
  background-color: ${theme.colors.white};
  border: 6px solid ${theme.colors.white};
  box-shadow: 0px 10px 20px rgba(${theme.colors.shadows.black}, 0.04),
    0px 2px 6px rgba(${theme.colors.shadows.black}, 0.04),
    0px 0px 1px rgba(${theme.colors.shadows.black}, 0.04);
  padding: ${({ iconbtnpadding }) => iconbtnpadding};
  display: flex;
  align-items: center;
  .icon-wrapper {
    .icon {
      position: relative;
      z-index: 1;
    }
    position: relative;
    z-index: 1;

    border-radius: 50%;
    background-color: ${({ version }) =>
      version === "v2"
        ? theme.colorsV2.shyft.purple_text
        : theme.colors.link2Color};
    ${({ version }) =>
      version !== "v2" &&
      `
        background-image: radial-gradient(${theme.colors.radial_gradient});
      `}
    height: calc(
      ${({ iconDimension }) => iconDimension} +
        ${({ version }) => (version === "v2" ? "8px" : "12px")}
    );
    width: calc(
      ${({ iconDimension }) => iconDimension} +
        ${({ version }) => (version === "v2" ? "8px" : "12px")}
    );
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
  @media only screen and (max-width: 767px) {
    p {
      display: none;
    }
    padding: 0;
  }
  ${({ isMobile }) =>
    isMobile &&
    `
    p {
      display: none;
    }
    padding: 0;
  `};
`;
const ButtonPrimary = styled(Buttons)`
  ${color}
  ${border}
  ${flexbox}
  background-color: ${({ variant, bgColor }) =>
    variant === "link" ? "transparent" : bgColor};
  ${({ variant }) =>
    variant === "primary" &&
    `
      background-image: radial-gradient(${theme.colors.radial_gradient_text});
    `};
  ${({ variant, borderColor, hoverColor }) =>
    variant !== "link" &&
    `
    border: ${variant === "primary" ? "0px" : `1px solid ${borderColor}`};
    &:hover {
      background-color: ${hoverColor};
    }
    `};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ btnPadding }) => btnPadding};
  &:disabled {
    cursor: default;
    [role="button"] {
      cursor: default;
    }
      p{
        color: ${theme.colorsV2.gray.shade_300} !important;
      }

    background: ${({ variant, version, bgColor }) =>
      variant === "link"
        ? "transparent"
        : version === "v2"
        ? bgColor
        : theme.colorsV2.gray.shade_100} !important;
    border-color: ${({ variant, version, bgColor }) =>
      variant === "link"
        ? "transparent"
        : version === "v2"
        ? bgColor
        : theme.colors.quote} !important;
  }
  p {
    ${({ variant }) =>
      variant === "primary" &&
      `
     font-family: var(--font-700);
    `};
    ${({ fontFamily }) => `font-family: ${fontFamily}`};
  }
`;
const ButtonPrimary1 = styled(ButtonPrimary)``;
const StyledText = styled.p`
  color: ${({ color }) => color};
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  ${({ textBold }) =>
    textBold &&
    `
        font-weight: bold;
    `};
`;
const OutlineText = styled(StyledText)`
  color: ${({ TextColor }) => TextColor};
`;
const IconText = styled(StyledText)`
  ${({ gradientColor }) =>
    gradientColor &&
    `
  background: ${gradientColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  `}
  ${({ variant }) =>
    variant === "icon1" &&
    `
  font-family: var(--font-700);
  `}
`;
