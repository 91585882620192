import { Control, Controller } from "react-hook-form";

import ErrorUI from "@/components/Atoms/InputsV2/ErrorUI";
import Typography from "@/foundation/Typography/V2";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./YogaTrialSelectDate.module.scss";

interface YogaTrialSelectDateProps {
  name: string;
  control: Control<any>;
  className?: string;
  onBlur?: (e: any) => void;
  availableDates: { date: string; month: string; value: string }[];
}

const YogaTrialSelectDate: React.FC<YogaTrialSelectDateProps> = ({
  name,
  control,
  onBlur = () => {},
  availableDates,
  ...rest
}) => {
  const cls = classNameGenerator(styles);

  return (
    <div className={cls("yogaTrialSelectDate")} {...rest}>
      <Controller
        name={name}
        control={control}
        shouldUnregister
        render={({
          field: { value, onBlur: handleBlur, onChange, ref },
          fieldState: { error },
        }) => (
          <>
            <div className={cls("label")}>Select date</div>
            <div className={cls("dateList")} ref={ref}>
              {availableDates.map((d, index) => {
                const [date, month] = d?.display_date?.split(" ") || [];
                return (
                  <div
                    key={index}
                    className={clsx(
                      cls("dateChip"),
                      value === d.date ? cls("active") : ""
                    )}
                    onClick={() => {
                      handleBlur();
                      onChange(d.date);
                    }}
                  >
                    <Typography variant="paragraph1" className={cls("date")}>
                      {date}
                    </Typography>
                    <Typography variant="paragraph1" className={cls("month")}>
                      {month}
                    </Typography>
                  </div>
                );
              })}
            </div>
            <ErrorUI error={error?.message} />
          </>
        )}
      />
    </div>
  );
};

export default YogaTrialSelectDate;
