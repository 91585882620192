import { LegacyRef, forwardRef } from "react";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./ToggleInput.module.scss";

interface ToggleInputProps {
  id?: string;
  name: string;
  className?: string;
  labelClassName?: string;
  label?: string;
  value?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

const ToggleInput = forwardRef(
  (
    {
      className,
      labelClassName,
      value,
      onChange = () => {},
      label,
      id = "toggle",
      ...rest
    }: ToggleInputProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const cls = classNameGenerator(styles);

    return (
      <div className={clsx(cls("toggleInput"), className)}>
        <input
          ref={ref}
          id={id}
          type="checkbox"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          {...rest}
        />
        <label htmlFor={id} className={clsx(cls("label"), labelClassName)}>
          <div className={cls("content")}>{label}</div>
          <div className={cls("toggle")} />
        </label>
      </div>
    );
  }
);

ToggleInput.displayName = "ToggleInput";

export default ToggleInput;
