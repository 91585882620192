import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "@/utils";

export const unlimitedYogaEmailerDataSubmitAction = createAsyncThunk(
  "unlimitedYogaEmailerDataSubmitAction",
  (data) => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}yoga_revamp/input/`;
    return axios.post(url, data);
  }
);
