import { createAsyncThunk } from "@reduxjs/toolkit";

import { appendArguments, axios, timezone as tz } from "@/utils";

const _timezone = tz || "Asia/Calcutta";

export const getAttributeAndCategories = createAsyncThunk(
  "getAttributeAndCategories",
  () => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/recipe/initial/ `;
    return axios.get(url);
  }
);

export const getRecipeThread = createAsyncThunk(
  "getRecipeThread",
  ({
    limit,
    offset,
    attributeIDs,
    categoryIDs,
    ignoreTimezone,
    timezone,
  }) => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/recipe/public-recipe/`;
    if (limit) url = appendArguments(url, `limit=${limit}`);
    if (offset) url = appendArguments(url, `offset=${offset}`);
    if (attributeIDs)
      url = appendArguments(url, `attribute_ids=${attributeIDs}`);
    if (categoryIDs)
      url = appendArguments(url, `category_ids=${categoryIDs}`);
    if (!ignoreTimezone)
      url = appendArguments(url, `timezone=${timezone || _timezone}`);

    return axios.get(url);
  }
);

export const getRecipeThreadByID = createAsyncThunk(
  "getRecipeThreadByID",
  ({ id, ignoreTimezone, timezone }) => {
    let url = `${
      process.env.NEXT_PUBLIC_LOCAL_API_URL
    }api/v1/recipe/public-recipe/${id}/${
      ignoreTimezone ? "" : `?timezone=${timezone || _timezone}`
    }`;

    return axios.get(url);
  }
);

export const getRecipeList = createAsyncThunk(
  "getRecipeList",
  ({ limit = 20, offset = 0 }) => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/recipe/recipe/?&limit=${limit}&offset=${offset}`;
    return axios.get(url);
  }
);

// GET RECIPE GROUPED BY CATEGORY
export const getRecipeByCategorySlug = createAsyncThunk<
  any,
  {
    id: string;
    ignoreTimezone: boolean;
    timezone: string;
    limit?: number | `${number}`;
  }
>("getRecipeByCategorySlug", ({ id, ignoreTimezone, timezone, limit = 25 }) => {
  let url = `${
    process.env.NEXT_PUBLIC_LOCAL_API_URL
  }api/v1/recipe/public-recipe/?collection__category__slug=${id}&${
    ignoreTimezone ? "" : `timezone=${timezone || _timezone}`
  }&limit=${limit}&offset=${0}`;
  return axios.get(url);
});

// GET RECIPE GROUPED BY ATTRIBUTE
export const getRecipeByAttributeSlug = createAsyncThunk<
  any,
  {
    id: string;
    ignoreTimezone: boolean;
    timezone: string;
    limit?: number | `${number}`;
  }
>(
  "getRecipeByAttributeSlug",
  ({ id, ignoreTimezone, timezone, limit = 100 }) => {
    let url = `${
      process.env.NEXT_PUBLIC_LOCAL_API_URL
    }api/v1/recipe/public-recipe/?attributes__slug=${id}&${
      ignoreTimezone ? "" : `timezone=${timezone || _timezone}`
    }&limit=${limit}&offset=${0}`;
    return axios.get(url);
  }
);

// GET RECIPE GROUPED BY COLLECTION
export const getRecipeByCollectionSlug = createAsyncThunk<
  any,
  {
    id: string;
    ignoreTimezone: boolean;
    timezone: string;
    limit?: number | `${number}`;
  }
>(
  "getRecipeByCollectionSlug",
  ({ id, ignoreTimezone, timezone, limit = 100 }) => {
    let url = `${
      process.env.NEXT_PUBLIC_LOCAL_API_URL
    }api/v1/recipe/public-recipe/?collections__slug=${id}&${
      ignoreTimezone ? "" : `timezone=${timezone || _timezone}`
    }&limit=${limit}&offset=${0}`;
    return axios.get(url);
  }
);
