import { forwardRef } from "react";
import { components } from "react-select";

import SVGSprite from "@/components/Atoms/SVGSprite";
import SelectInput from "@/components/Atoms/InputsV2/SelectInput";
import Typography from "@/foundation/Typography/V2";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./SelectHealthConditions.module.scss";

interface OptionI {
  label: string;
  value: string | number;
  [key: string]: any;
}

interface SelectHealthConditionsProps {
  name: string;
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  options: OptionI[];
}

const SelectHealthConditions = forwardRef(
  ({ className, ...rest }: SelectHealthConditionsProps, ref: any) => {
    const cls = classNameGenerator(styles);

    const HealthConditionsDropdown = (props: any) => {
      return (
        <components.Menu {...props}>
          <div className={cls("healthConditionsDropdown")}>
            {props.options.map((option: OptionI) => (
              <div
                key={option.value}
                className={cls("option")}
                onClick={() => props.setValue(option)}
              >
                {option.icon && (
                  <SVGSprite icon={option.icon} height="32px" width="32px" />
                )}
                <Typography className={cls("optionText")} variant="paragraph1">
                  {option.label}
                </Typography>
              </div>
            ))}
          </div>
        </components.Menu>
      );
    };

    const formatOptionLabel = (option: OptionI) => {
      return (
        <div className={cls("option")}>
          {option.icon && (
            <SVGSprite icon={option.icon} height="34px" width="34px" />
          )}
          <Typography className={cls("optionText")} variant="paragraph1">
            {option.label}
          </Typography>
        </div>
      );
    };

    return (
      <SelectInput
        ref={ref}
        className={clsx(cls("healthConditions"), className)}
        classNamePrefix="healthConditions"
        components={{ Menu: HealthConditionsDropdown }}
        formatOptionLabel={formatOptionLabel}
        {...rest}
      />
    );
  }
);

SelectHealthConditions.displayName = "SelectHealthConditions";

export default SelectHealthConditions;
