import React from "react";

import TypographyComponent from "@/components/Typography";
import theme from "@/foundation/Theme";

import {
  DisplayI,
  HeadingI,
  LabelI,
  LinkI,
  ParagraphI,
  SpanI,
  TypographyProps,
} from "./TypographyV1.map";

export const Typography: React.FC<TypographyProps> = ({
  variant,
  fontFamily,
  isExtraBold,
  fontSize,
  lineHeight,
  letterSpacing,
  link1Color,
  link2Color,
  mb = "0px",
  ...rest
}) => {
  const headingProps = {
    variant,
    fontFamily:
      fontFamily || (isExtraBold ? "var(--man-800)" : "var(--man-700)"),
    letterSpacing,
    mb: mb,
  };

  const headingTypography: HeadingI = {
    heading1: {
      ...headingProps,
      fontSize: fontSize || ["30px", "34px", "40px"],
      lineHeight: lineHeight || ["40px", "48px", "56px"],
    },
    heading2: {
      ...headingProps,
      fontSize: fontSize || ["26px", "30px", "36px"],
      lineHeight: lineHeight || ["32px", "38px", "48px"],
    },
    heading3: {
      ...headingProps,
      fontSize: fontSize || ["24px", "28px", "32px"],
      lineHeight: lineHeight || ["32px", "36px", "40px"],
    },
    heading4: {
      ...headingProps,
      fontSize: fontSize || ["20px", "24px", "28px"],
      lineHeight: lineHeight || ["24px", "32px", "40px"],
    },
    heading5: {
      ...headingProps,
      fontSize: fontSize || ["18px", "21px", "24px"],
      lineHeight: lineHeight || ["24px", "28px", "32px"],
    },
    heading6: {
      ...headingProps,
      fontSize: fontSize || ["16px", "18px", "20px"],
      lineHeight: lineHeight || ["24px", "24px", "24px"],
    },
  };

  const paragraphProps = {
    fontFamily:
      fontFamily || (isExtraBold ? "var(--man-800)" : "var(--man-500)"),
    variant,
    mb: mb,
  };

  const paragraphTypography: ParagraphI = {
    paragraph1: {
      ...paragraphProps,
      fontSize: fontSize || ["16px", "17px", "18px"],
      lineHeight: lineHeight || ["24px", "24px", "24px"],
      letterSpacing: letterSpacing || ["0.4px", "0.6px", "0.6px"],
    },
    paragraph2: {
      ...paragraphProps,
      fontSize: fontSize || ["14px", "15px", "16px"],
      lineHeight: lineHeight || ["24px", "24px", "24px"],
      letterSpacing: letterSpacing || ["0.4px", "0.6px", "0.6px"],
    },
    paragraph3: {
      ...paragraphProps,
      fontSize: fontSize || ["12px", "13px", "14px"],
      lineHeight: lineHeight || ["24px", "24px", "24px"],
      letterSpacing: letterSpacing || ["0.4px", "0.4px", "0.4px"],
    },
    paragraph4: {
      ...paragraphProps,
      fontSize: fontSize || ["10px", "11px", "12px"],
      lineHeight: lineHeight || ["16px", "16px", "16px"],
      letterSpacing: letterSpacing || ["0.4px", "0.4px", "0.4px"],
    },
  };

  const displayProps = {
    fontFamily:
      fontFamily || (isExtraBold ? "var(--man-800)" : "var(--man-600)"),
    variant,
    letterSpacing,
    mb: mb,
  };

  const displayTypography: DisplayI = {
    display1: {
      ...displayProps,
      fontSize: fontSize || ["76px", "86px", "96px"],
      lineHeight: lineHeight || ["80px", "90px", "112px"],
    },
    display2: {
      ...displayProps,
      fontSize: fontSize || ["32px", "42px", "52px"],
      lineHeight: lineHeight || ["44px", "54px", "64px"],
    },
    display3: {
      ...displayProps,
      fontSize: fontSize || ["34px", "40px", "44px"],
      lineHeight: lineHeight || ["40px", "44px", "56px"],
    },
    display4: {
      ...displayProps,
      fontSize: fontSize || ["24px", "32px", "36px"],
      lineHeight: lineHeight || ["32px", "40px", "48px"],
    },
  };

  const labelProps = {
    fontFamily:
      fontFamily || (isExtraBold ? "var(--man-800)" : "var(--man-700)"),
    variant,
    letterSpacing,
    mb: mb,
  };

  const labelTypography: LabelI = {
    label1: {
      ...labelProps,
      fontSize: fontSize || ["16px", "17px", "18px"],
      lineHeight: lineHeight || ["24px", "24px", "24px"],
    },
    label2: {
      ...labelProps,
      fontSize: fontSize || ["14px", "15px", "16px"],
      lineHeight: lineHeight || ["20px", "20px", "24px"],
    },
    label3: {
      ...labelProps,
      fontSize: fontSize || ["12px", "13px", "14px"],
      lineHeight: lineHeight || ["16px", "16px", "16px"],
    },
    label4: {
      ...labelProps,
      ...rest,
      fontSize: fontSize || ["10px", "10px", "12px"],
      lineHeight: lineHeight || ["16px", "16px", "16px"],
    },
  };

  const spanProps = {
    fontFamily:
      fontFamily || (isExtraBold ? "var(--man-800)" : "var(--man-600)"),
    variant,
    letterSpacing,
    lineHeight,
    mb: mb,
  };

  const spanTypography: SpanI = {
    span: {
      ...spanProps,
      fontSize: fontSize || "12px",
    },
  };

  const linkProps = {
    fontFamily:
      fontFamily || (isExtraBold ? "var(--man-800)" : "var(--man-700)"),
    variant,
    lineHeight,
    letterSpacing,
    mb: mb,
  };

  const linkTypography: LinkI = {
    link1: {
      ...linkProps,
      fontSize: fontSize || ["14px", "15px", "16px"],
      lineHeight: lineHeight || ["20px", "20px", "24px"],
      color: link1Color || theme.colors.gray_90,
    },
    link2: {
      ...linkProps,
      fontSize: fontSize || ["10px", "10px", "14px"],
      color: link2Color || theme.colors.link2Color,
      ...(lineHeight ? { lineHeight } : {}),
    },
    routelink1: {
      ...linkProps,
      fontSize: fontSize || ["10px", "10px", "16px"],
      color: link1Color || theme.colors.gray_90,
    },
    routelink2: {
      ...linkProps,
      fontSize: fontSize || ["10px", "10px", "14px"],
      color: link2Color || theme.colors.link2Color,
    },
  };

  const typographyV2 = {
    ...headingTypography,
    ...paragraphTypography,
    ...displayTypography,
    ...labelTypography,
    ...spanTypography,
    ...linkTypography,
  };

  const typographyProps = {
    ...rest,
    ...typographyV2[variant as keyof typeof typographyV2],
  };

  return <TypographyComponent {...typographyProps} />;
};

export default Typography;
