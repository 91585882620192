import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";
import { DEFAULT_API_ERROR_MESSAGE } from "@/utils/Constants";

interface DoctorLandingPageInitialState {
  isPageLoading: boolean;
  data: any;
  error: string;
  success: boolean;
}

const initialState: DoctorLandingPageInitialState = {
  isPageLoading: false,
  data: null,
  success: false,
  error: "",
};

const doctorPageSlice = createSlice({
  name: "doctorsLandingPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.doctorsLandingPage,
      }))
      // GET DOCTOR PAGE DATA
      .addCase(actions.getDoctorLandingPageData.pending, (state) => ({
        ...state,
        isPageLoading: true,
      }))
      .addCase(
        actions.getDoctorLandingPageData.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isPageLoading: false,
            data: payload?.data,
            success: true,
          };
        }
      )
      .addCase(
        actions.getDoctorLandingPageData.rejected,
        (state, { payload }) => {
          return {
            ...state,
            isPageLoading: false,
            success: false,
            error: payload?.message || DEFAULT_API_ERROR_MESSAGE,
          };
        }
      );
  },
});

export { getDoctorLandingPageData } from "./Actions";

export default doctorPageSlice.reducer;
