import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

const initialState: any = {
  isLoading: false,
  error: "",
  success: false,
  data: {},
};

const healthSurveySlice = createSlice({
  name: "healthSurvey",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.healthSurvey,
      }))
      // GET HEALTH SURVEY DATA
      .addCase(actions.getHealthSurveyPageData.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(
        actions.getHealthSurveyPageData.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            success: true,
            data: payload?.data,
          };
        }
      )
      .addCase(
        actions.getHealthSurveyPageData.rejected,
        (state, { payload }) => ({
          ...state,
          isLoading: false,
          error: payload || true,
        })
      );
  },
});

export { getHealthSurveyPageData } from "./Actions";

export default healthSurveySlice.reducer;
