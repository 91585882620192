import React, { useEffect, useRef, forwardRef, useState } from "react";
import styled from "styled-components";
import { space } from "styled-system";
import theme from "../../../foundation/Theme";
import Typography from "../../../foundation/Typography/V2";
import { Box, FlexDiv } from "../Box";
import SVGSprite from "../SVGSprite";
import {
  InputWrapperV3,
  SearchIconV3,
  InputV3,
  StyledSpanV3,
  ErrorTypography,
  ErrorIconV3,
} from "./InputStyle/inputStyle";

// eslint-disable-next-line react/display-name
const InputField = forwardRef((props, ref) => {
  const {
    variant = "v1",
    id = undefined,
    zIndex = "1",
    type = "text",
    onFocus = () => {},
    focusable = false,
    onBlur = () => {},
    onChange = () => {},
    placeholder = "",
    value = "",
    disabled = false,
    showClearOnValue = false,
    wrapperClassName = "",
    children,
    dropDownData = [],
    inputColor = variant === "v2"
      ? theme.colorsV2.gray.shade_800
      : variant === "v3"
      ? theme.colorsV2.gray.shade_900
      : theme.colors.gray_90,
    inputBorderRadius = "10px",
    noWrapper = false,
    onClearText = () => {},
    wrapperWidth = "",
    wrapperMaxWidth = "",
    inputFontSize = variant === "v2" || variant === "v3" ? "16px" : "14px",
    inputLineHeight = variant === "v3" ? "24px" : "28px",
    onDropDownClick = () => {},
    error = "",
    mb = 0,
    icon = "",
    iconPlacement = "left",
    iconDimension = "16px",
    iconFill = variant === "v2"
      ? theme.colorsV2.gray.shade_700
      : theme.colors.Usual_Icons,
    setFocus = false,
    clearSearchIcon = "icon-close",
    clearIconFillColor = theme.colors.Usual_Icons,
    inputPadding = variant === "v2"
      ? "3px 16px 3px 32px"
      : variant === "v3"
      ? `10px 38px 10px ${icon ? "48px" : "14px"}`
      : "5px 34px",
    fontFamily = variant === "v2"
      ? "var(--man-700)"
      : variant === "v3"
      ? "var(--man-500)"
      : "var(--font-500)",
    isNotAbsolute,
    ...rest
  } = props || {};
  const inputEL = useRef(null);
  useEffect(() => {
    const inputRef = inputEL.current;
    setFocus && inputRef.focus();
    return undefined;
  }, [setFocus]);

  const [inputWrapFocus, setInputWrapFocus] = useState(false);
  return (
    <>
      {noWrapper && (
        <>
          <Input
            id={id}
            type={type}
            value={value}
            focusable={focusable}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            ref={inputEL}
            inputBorderRadius={inputBorderRadius}
            inputPadding={inputPadding}
            inputColor={inputColor}
            inputFontSize={inputFontSize}
            inputLineHeight={inputLineHeight}
            variant={variant}
            fontFamily={fontFamily}
            {...rest}
          />
          {showClearOnValue && value !== "" && (
            <ClearIcon
              onClick={onClearText}
              icon={clearSearchIcon}
              height={"20px"}
              width={"20px"}
              fill={clearIconFillColor}
            />
          )}
        </>
      )}
      {!noWrapper && variant !== "v3" && (
        <InputWrapper
          className={wrapperClassName}
          position={"relative"}
          mb={mb}
          width={wrapperWidth}
          maxWidth={wrapperMaxWidth}
          iconPlacement={iconPlacement}
          zIndex={zIndex}
          variant={variant}
          onFocus={() => {
            setInputWrapFocus(true);
          }}
          onBlur={() => {
            setInputWrapFocus(false);
          }}
          isFocusable={inputWrapFocus}
        >
          <Input
            id={id}
            type={type}
            value={value}
            onFocus={onFocus}
            focusable={focusable}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            ref={inputEL}
            icon={icon !== ""}
            inputPadding={inputPadding}
            inputBorderRadius={inputBorderRadius}
            inputColor={inputColor}
            inputFontSize={inputFontSize}
            inputLineHeight={inputLineHeight}
            isDropdown={dropDownData.length > 0}
            error={error}
            variant={variant}
            fontFamily={fontFamily}
            isNotAbsolute={isNotAbsolute}
            {...rest}
          />
          {error && (
            <Typography
              fontSize={"10px"}
              variant={"span"}
              color={theme.colors.red}
              className="form-input-error"
            >
              {error}
            </Typography>
          )}
          {icon !== "" && (
            <SearchIcon
              icon={icon}
              height={iconDimension}
              width={iconDimension}
              fill={iconFill}
            />
          )}
          {children}
          {showClearOnValue && value !== "" && (
            <ClearIcon
              onClick={onClearText}
              icon={clearSearchIcon}
              height={"20px"}
              width={"20px"}
              fill={clearIconFillColor}
            />
          )}
          {dropDownData.length > 0 && (
            <DropdownWrapper flexDirection="column">
              {dropDownData?.map((item, index) => {
                return (
                  <StyledSpan
                    lineHeight={"28px"}
                    color={theme.colors.Usual_Icons}
                    key={index}
                    variant="span"
                    onClick={() => onDropDownClick(item)}
                  >
                    {typeof item === "object" && item !== null
                      ? item.label
                      : item}
                  </StyledSpan>
                );
              })}
            </DropdownWrapper>
          )}
        </InputWrapper>
      )}
      {!noWrapper && variant === "v3" && (
        <>
          <InputWrapperV3
            className={wrapperClassName}
            position={"relative"}
            mb={mb}
            width={wrapperWidth}
            maxWidth={wrapperMaxWidth}
            iconPlacement={iconPlacement}
            zIndex={zIndex}
            variant={variant}
            onFocus={() => {
              setInputWrapFocus(true);
            }}
            onBlur={() => {
              setInputWrapFocus(false);
            }}
            isFocusable={inputWrapFocus}
          >
            <InputV3
              id={id}
              type={type}
              value={value}
              onFocus={onFocus}
              focusable={focusable}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              disabled={disabled}
              ref={inputEL}
              icon={icon !== ""}
              inputPadding={inputPadding}
              inputBorderRadius={inputBorderRadius}
              inputColor={inputColor}
              inputFontSize={inputFontSize}
              inputLineHeight={inputLineHeight}
              isDropdown={dropDownData.length > 0}
              error={error}
              isNotAbsolute={isNotAbsolute}
              variant={variant}
              fontFamily={fontFamily}
              {...rest}
            />
            {error && (
              <ErrorIconV3
                icon="icon-info-circle-2"
                isNotAbsolute={isNotAbsolute}
              />
            )}
            {icon !== "" && (
              <SearchIconV3
                icon={icon}
                height={iconDimension}
                width={iconDimension}
                fill={iconFill}
              />
            )}
            {children}
            {showClearOnValue && value !== "" && (
              <ClearIcon
                onClick={onClearText}
                icon={clearSearchIcon}
                height={"20px"}
                width={"20px"}
                fill={clearIconFillColor}
              />
            )}
            {dropDownData.length > 0 && (
              <DropdownWrapper flexDirection="column">
                {dropDownData?.map((item, index) => {
                  return (
                    <StyledSpanV3
                      lineHeight={"28px"}
                      color={theme.colors.Usual_Icons}
                      key={index}
                      variant="span"
                      onClick={() => onDropDownClick(item)}
                    >
                      {typeof item === "object" && item !== null
                        ? item.label
                        : item}
                    </StyledSpanV3>
                  );
                })}
              </DropdownWrapper>
            )}
          </InputWrapperV3>
          {error && (
            <ErrorTypography
              variant={"paragraph3"}
              className="form-input-error"
              isNotAbsolute={isNotAbsolute}
              ml={isNotAbsolute && "8px"}
            >
              {error}
            </ErrorTypography>
          )}
        </>
      )}
    </>
  );
});

export default InputField;

const ClearIcon = styled(SVGSprite)`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  padding: 12px 10px;
  font-size: 14px;
  font-weight: bold;
  z-index: 3;
  padding: 5px;
`;
const SearchIcon = styled(SVGSprite)`
  transition: all 0.2s ease-in-out;
  fill: ${({ iconFill }) => iconFill};
  pointer-events: none;
`;
const InputWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 0;
  ${({ variant, isFocusable }) =>
    variant === "v2" &&
    `
      &:before{
        content: "";
        height:1px;
        width: 100%;
        position: absolute;
        bottom: -7px;
        left: 0;
        background-color: ${theme.colorsV2.gray.shade_300};
        z-index: 0;
      }
      &:after{
        z-index: 1;
        transition: all 0.3s ease-in-out;
        content: "";
        height:2px;
        width: 100%;
        max-width: ${isFocusable ? "100%" : "0px"};
        position: absolute;
        bottom: -7px;
        left: 0;
        background-color: ${theme.colorsV2.shyft.purple};
      }
  `}
  ${SearchIcon} {
    z-index: 4;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${({ iconPlacement, variant }) =>
      iconPlacement === "left" &&
      `
        left: ${variant === "v2" ? "0px" : "12px"};
    `};
    ${({ iconPlacement, variant }) =>
      iconPlacement === "right" &&
      `
        right: ${variant === "v2" ? "0px" : "12px"};
    `};
  }
`;
const Input = styled.input`
  ${space}
  padding: ${({ inputPadding }) => inputPadding};
  outline: 0;
  background-color: transparent;
  border-radius: ${({ inputBorderRadius }) => inputBorderRadius};

  border: ${({ variant, error, borderColor, showBorderBottom }) =>
    showBorderBottom || variant === "v2"
      ? "none"
      : `1px solid ${
          error
            ? variant === "v2"
              ? theme.colorsV2.shyft.error
              : theme.colors.red
            : borderColor || theme.colors.quote
        }`};
  border-bottom: ${({ showBorderBottom }) =>
    showBorderBottom && `1px solid ${theme.colors.quote}`};
  transition: all 0.2s ease-in-out;
  width: 100%;
  color: ${({ inputColor }) => inputColor};
  font-size: ${({ inputFontSize }) => inputFontSize};
  line-height: ${({ inputLineHeight }) => inputLineHeight};
  ${({ focusable }) =>
    focusable &&
    `
    &:focus{
      border-color: ${({ error }) =>
        error ? theme.colors.red : theme.colors.Primary_CTA};
    }
  `};
  ${({ isDropdown }) =>
    isDropdown &&
    `
  position: relative;
  z-index:3;
  `};
  font-family: ${({ fontFamily }) => fontFamily};
  &::placeholder {
    ${({ variant }) =>
      variant === "v2" &&
      `
      font-family: var(--man-500);
    `}
    color: ${({ variant }) =>
      variant === "v2"
        ? theme.colorsV2.gray.shade_400
        : theme.colors.quote} !important;
  }
  ${({ hideCalenderIcon }) =>
    hideCalenderIcon &&
    `
    &[type="date"]::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;
      height: auto;
      width: auto;
    }
  `};
`;
const StyledSpan = styled(Typography)``;
const DropdownWrapper = styled(FlexDiv)`
  position: absolute;
  background-color: ${theme.colors.white};
  width: 100%;
  border: 1px solid ${theme.colors.Border_1};
  border-radius: 6px;
  box-shadow: 2px 4px 4px rgba(${theme.colors.shadows.typeDropdown}, 0.25);
  padding: 23px 0px 0px 0px;
  z-index: 1;
  top: 25px;
  cursor: pointer;
  overflow: hidden auto;
  max-height: 225px;
  ${StyledSpan} {
    padding: 0 12px;
    &:hover {
      background-color: ${theme.colors.Left_Panel_Bg};
    }
  }
`;
