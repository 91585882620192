import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";
import { DEFAULT_API_ERROR_MESSAGE } from "@/utils/Constants";

const initialState: any = {
  isLoading: false,
  error: "",
  success: false,
  data: {},

  isFormSubmitting: false,
  formSubmitError: "",
  formSubmitSuccess: false,
  formData: {},

  isPartnerUrlLoading: false,
  partnerUrlError: "",
  partnerUrlSuccess: false,
  partnerUrlData: {},
};

const diagnosticSlice = createSlice({
  name: "diagnosticPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.diagnosticPage,
      }))

      // GET DIAGNOSTIC PAGE DATA
      .addCase(actions.getDiagnosticPageData.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(
        actions.getDiagnosticPageData.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            success: true,
            data: payload?.data,
          };
        }
      )
      .addCase(
        actions.getDiagnosticPageData.rejected,
        (state, { payload }) => ({
          ...state,
          isLoading: false,
          success: false,
          error: payload.message || DEFAULT_API_ERROR_MESSAGE,
        })
      )

      // GET PARTNER BUTTON URL
      .addCase(actions.getPartnerUrl.pending, (state) => ({
        ...state,
        isPartnerUrlLoading: true,
        partnerUrlSuccess: false,
        partnerUrlError: "",
      }))
      .addCase(actions.getPartnerUrl.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isPartnerUrlLoading: false,
          partnerUrlSuccess: true,
          partnerUrlData: payload?.data,
        };
      })
      .addCase(actions.getPartnerUrl.rejected, (state, { payload }) => ({
        ...state,
        isPartnerUrlLoading: false,
        partnerUrlSuccess: false,
        partnerUrlError: payload.message || DEFAULT_API_ERROR_MESSAGE,
      }))

      // GET DIAGNOSTIC PAGE FORM DATA
      .addCase(actions.sendDiagnosticFormData.pending, (state) => ({
        ...state,
        isFormSubmitting: true,
        formSubmitSuccess: false,
        formSubmitError: "",
      }))
      .addCase(
        actions.sendDiagnosticFormData.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isFormSubmitting: false,
            formSubmitSuccess: true,
            formData: payload?.data,
          };
        }
      )
      .addCase(actions.sendDiagnosticFormData.rejected, (state, action) => {
        return {
          ...state,
          isFormSubmitting: false,
          formSubmitSuccess: false,
          formSubmitError: action.error || DEFAULT_API_ERROR_MESSAGE,
        };
      });
  },
});
export {
  sendDiagnosticFormData,
  getDiagnosticPageData,
  getPartnerUrl,
} from "./Actions";

export default diagnosticSlice.reducer;
