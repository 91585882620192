import styled from "styled-components";
import { space, color, typography, layout, flexbox } from "styled-system";

export const Paragraph = styled.p<{ textTransform?: string }>`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${typography}
  text-transform: ${({ textTransform }) => textTransform};
  word-wrap: break-word;
`;
