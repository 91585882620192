import { useEffect, useState } from "react";
import { useRouter } from "next/router";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { getCookieStorage, setCookieStorage } from "@/utils/CookieStorage";

import * as actions from "@/redux/AuthReducer";

const useAuthFlow = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const loginData = useAppSelector((state) => state.auth) || {};

  const status = loginData?.isLoggingIn;

  const [login, setLogin] = useState(false);
  const [token, setToken] = useState(null);
  const [showToast, setShowToast] = useState<any>(false);
  const [loginModal, setLoginModal] = useState(false);

  useEffect(() => {
    setShowToast(
      JSON.parse(
        localStorage.getItem("LOGIN_TOAST_DATA") || JSON.parse("false")
      )
    );
  }, []);

  useEffect(() => {
    if (token || (loginData?.data?.access && loginData?.data?.refresh)) {
      setCookieStorage(
        "__authAccessToken",
        loginData?.data?.access,
        30,
        "days"
      );
      setCookieStorage(
        "__authRefreshToken",
        loginData?.data?.refresh,
        90,
        "days"
      );
      if (loginData?.data?.primaryContact)
        setCookieStorage(
          "__primaryContact",
          loginData?.data?.primaryContact,
          90
        );
    }
  }, [status]);

  useEffect(() => {
    if (
      getCookieStorage("__authAccessToken") !== "" &&
      getCookieStorage("__authRefreshToken") !== ""
    ) {
      setToken(true);
      setLogin(true);
    } else {
      setLogin(false);
      setToken(false);
    }
  }, [status]);

  const logout = () => {
    setLogin(false);
    setToken(false);
    dispatch(actions.logout());
    setShowToast({
      type: "success",
      message: "Logout Successful",
    });
    localStorage.setItem(
      "LOGIN_TOAST_DATA",
      JSON.stringify({
        type: "success",
        message: "Logout Successful",
      })
    );
    router.push("/");
  };

  return {
    login,
    token,
    setToken,
    loginModal,
    setLoginModal,
    showToast,
    setShowToast,
    logout,
  };
};

export default useAuthFlow;
