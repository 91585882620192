export const menu = [
  {
    category: "Health Programs",
    link: "/health/",
    subCategory: [
      {
        category: "PCOS Symptoms Reversal",
        link: "/health-for-pcos/",
      },
      {
        category: "Managing Hypertension",
        link: "/health-for-hypertension/",
      },
      {
        category: "Managing Thyroid Conditions",
        link: "/health-for-thyroid/",
      },
      {
        category: "Prenatal Care",
        link: "/health-for-prenatal/",
      },
      {
        category: "Postnatal Care",
        link: "/health-for-postnatal/",
      },
      {
        category: "Managing Diabetes",
        link: "/health-for-diabetes/",
      },
      // {
      //   category: "Corporate Plans",
      //   link: "/corporate-partnerships/",
      //   icon: "icon-corporate-page-star",
      //   isSpecialLink: true,
      // },
    ],
  },
  {
    category: "Yoga",
    link: "/plans/yoga/",
    subCategory: [
      {
        category: "Yoga for PCOS",
        link: "/plans/yoga/pcos/",
      },
      {
        category: "Yoga for Thyroid",
        link: "/yoga-for-thyroid/",
      },
      {
        category: "Yoga for Diabetes",
        link: "/yoga-for-diabetes/",
      },
      // {
      //   category: "Yoga for Digestion",
      //   link: "/yoga-for-good-digestion/",
      // },
      {
        category: "Yoga for High BP, Hypertension",
        link: "/yoga-for-high-blood-pressure/",
      },
      {
        category: "Yoga for Back Strengthening",
        link: "/plans/yoga/back-strengthening/",
      },
      {
        category: "Yoga for Strength & Flexibility",
        link: "/plans/yoga/strength-and-flexibility/",
      },
      {
        category: "Yoga for Prenatal",
        link: "/plans/yoga/prenatal-care/",
      },
      {
        category: "Yoga for Postnatal",
        link: "/plans/yoga/postnatal-care/",
      },
      {
        category: "Yoga for Wellness",
        link: "/plans/yoga/wellness/",
      },
      {
        category: "Yoga for Weight Management",
        link: "/yoga-for-weight-management/",
      },
      // {
      //   category: "Yoga for Weight Loss",
      //   link: "/plans/yoga/weight-loss/",
      // },
    ],
  },
  {
    category: "Nutrition",
    subCategory: [
      {
        category: "Nutrition for Adults",
        link: "/plans/nutrition/",
      },
      {
        category: "Nutrition for Kids",
        link: "/nutrition-for-kids/",
      },
      {
        category: "Nutrition for Period Pains",
        link: "/plans/nutrition/period-pain/",
      },
      {
        category: "Nutrition for Gut Health",
        link: "/plans/nutrition/gut-health/",
      },
      {
        category: "Nutrition for Detox Plan",
        link: "/plans/nutrition/full-body-detox/",
      },
      {
        category: "Nutrition for Weightloss",
        link: "/plans/nutrition/weight-loss/",
      },
      {
        category: "Nutrition for Acne",
        link: "/plans/nutrition/acne/",
      },
      {
        category: "Nutrition for High Cholestrol",
        link: "/plans/nutrition/high-cholesterol/",
      },
      {
        category: "Nutrition for high uric acid",
        link: "/plans/nutrition/high-uric-acid/",
      },
      {
        category: "Nutrition for thyroid",
        link: "/plans/nutrition/thyroid/",
      },
      {
        category: "High Protein Diet",
        link: "/plans/nutrition/high-protein/",
      },
      {
        category: "Nutrition Plan for High BP",
        link: "/plans/nutrition/high-blood-pressure/",
      },
      {
        category: "Nutrition Plan for High Blood Sugar",
        link: "/plans/nutrition/high-blood-sugar/",
      },
    ],
  },
  // {
  //   category: "Dermatology",
  //   link: "/dermatology/",
  // },
  {
    category: "Healthwatch Blog",
    link: "https://www.betheshyft.com/healthwatch/",
    id: "header-healthwatch-blog-link",
    query: {
      utm_source: "shyft_website",
      utm_medium: "shyft_website_link",
      adset_name: "website_link",
    },
    isExternal: true,
  },
  {
    category: "Corporate Plans",
    link: "/corporate-partnerships/",
  },
];

export const ShyftPassPageMenu = [
  {
    title: "Home",
    link: "shyft-pass-home",
    id: "1",
  },
  {
    title: "How it works",
    link: "shyft-pass-how-it-works",
    id: "2",
  },
  {
    title: "Schedule",
    link: "shyft-pass-workout-schedule",
    id: "3",
  },
  {
    title: "FAQ",
    link: "shyft-pass-faq",
    id: "4",
  },
];

export const profileMenu = [
  {
    category: "Profile",
    link: "/profile/",
    isExternal: true,
  },
  {
    category: "Contact us",
    link: "/contact-us/",
  },
  {
    category: "Logout",
    isLogoutLink: true,
  },
];

export default menu;
