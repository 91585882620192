import SVGSprite from "@/components/Atoms/SVGSprite";

import { classNameGenerator } from "@/utils";

import styles from "./CallbackSuccess.module.scss";
import Typography from "@/foundation/Typography/V2";

interface CallbackSuccessProps {}

const CallbackSuccess: React.FC<CallbackSuccessProps> = () => {
  const cls = classNameGenerator(styles);

  return (
    <div className={cls("callbackSuccess")}>
      <SVGSprite icon="icon-payment-success" className={cls("icon")} />
      <Typography variant="heading4" className={cls("title")}>
        {"Details submitted"}
      </Typography>
      <Typography variant="paragraph1" className={cls("description")}>
        {"You'll get a call back in 24 hours!"}
      </Typography>
    </div>
  );
};

export default CallbackSuccess;
