import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios } from "@/utils";

export const validateTrialClass = createAsyncThunk<
  any,
  { trialClassId: string }
>("validateTrialClass", ({ trialClassId }) => {
  let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}trial_class/check_valid/`;

  return axios.post(url, {
    trial_class_id: trialClassId,
  });
});
