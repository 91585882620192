const theme = {
  breakpoints: ["640px", "960px", "1600px"],
  colors: {
    Left_Panel_Bg: "#F5F1FD",
    gray_bg: "#F3F4EF",
    gray_100: "#343333",
    Text_1: "#5c5c6e",
    Text_2: "#5A5863",
    Border_1: "#E6E6F0",

    gray_90: "#646464",
    gray_80: "#8D8D8D",
    gray_70: "#5D5C6E",
    gray_60: "#7B7D8A",
    gray_50: "#727382",
    green: "#07c748",
    red: "#FF0000",
    greenFade: "#D2FFED",
    green1: "#81C185",
    dotsColor: "#8c7bd1",
    lightBlue: "#F8FBFF",
    lightBlue1: "#F7F9FF",
    link2Color: "#5B42BD",
    purpleHover: "#5C44BF",
    border1: "#EAE9EB",
    arrow: "#7B7B7B",
    quote: "#D4C9FF",
    buttonFill: "#A292E4",
    dropdownColor: "#c4c4c4",
    border2: "#EDEDED",
    yellow: "#E9B137",
    purple: "#715AC7",
    yellow2: "#EBC481",
    textColor1: "#313131",
    redGradient: "linear-gradient(98.65deg, #F9AA7E -5.88%, #FF0000 130.2%)",
    staticRedGradient: "#FF0000",
    scroll: {
      thumbColor: "#BEBCC8",
      trackColor: "#E9E9ED",
    },
    layOvers: {
      black: "0,0,0",
    },
    Usual_Icons: "#868295",

    white: "#fff",
    black: "#000",
    Text_3: "#BCBBCF",

    shadows: {
      black: "0,0,0",
      white: "255,255,255",
      banner: "91, 66, 189",
      purple: "91,66,189",
      purple_2: "141,123,209",
      purple_3: "89, 37, 220",
      gray: "16, 24, 40",

      typeDropdown: "230, 230, 240",
      typeShadow1: "218, 210, 210",
      typeToast: "187, 191, 233",
    },
    radial_gradient: "111.39% 119.64% at 75% 110.71%, #D4C9FF 0%, #330EC0 100%",
    radial_gradient_text:
      "55.17% 189.92% at 82.07% 177.06%,#D4C9FF 0%,#330EC0 100%",
    radial_gradient2:
      "182.17% 459.92% at 82.07% 177.06%, #D4C9FF 0%, #330EC0 100%",
    btn_main_gradient:
      "182.17% 459.92% at 82.07% 177.06%, #D4C9FF 0%, #330EC0 100%",
    linear_gradient:
      "linear-gradient(177.54deg, #F7F5FF 2.06%, rgba(255, 255, 255, 0) 97.99%);",
    iconGrad: {
      type1: "linear-gradient(131deg,#227AFF 39.21%,#8FC9FF 68.45%)",
      type2:
        "radial-gradient(76.09% 76.09% at 56.61% 81.44%, #D4C9FF 0%, #330EC0 99.48%)",
      type3:
        "radial-gradient(62.3% 62.3% at 77.87% 86.89%, #C7F7D7 0%, #01935E 100%)",
      type4:
        "radial-gradient(82.79% 82.79% at 71.31% 95.9%, #FFDAD8 12.5%, #E57E79 100%)",
      type5:
        "radial-gradient(76.09% 76.09% at 56.61% 81.44%, #C5E3FF 0.52%, #189AE8 97.4%)",
    },
    linearGradBlue:
      "linear-gradient(90.01deg, #5B42BD 30.07%, rgba(0, 0, 0, 0) 100%)",
    linearGradWhite:
      "linear-gradient(90deg, #F3F3EF 36.24%, rgba(243, 243, 239, 0) 100%)",
    linearGradRed: "linear-gradient(90.65deg, #FFFFFF -7.71%, #FFE7E2 100.36%)",
    shadeRed: "#D8837C",
    textGradient: {
      type1:
        "linear-gradient(90deg, rgba(34,122,255,1) 0%, rgba(143,201,255,1) 100%)",
      type1static: "#227aff",
      type2:
        "linear-gradient(90deg, rgba(91,66,189,1) 0%, rgba(169,147,255,1) 100%)",
      type2static: "#5b42bd",
      type3:
        "linear-gradient(90deg, rgba(0,146,93,1) 0%, rgba(140,204,144,1) 100%)",
      type3static: "#00925D",
      type4:
        "linear-gradient(90deg, rgba(229,126,121,1) 0%, rgba(255,180,176,1) 100%)",
      type4static: "##E57E79",
      type5:
        "linear-gradient(90deg, rgba(24,154,232,1) 0%, rgba(176,217,255,1) 100%)",
      type5static: "#189AE8",
    },
    cardGradient: "linear-gradient(117.6deg, #5B42BD 2.52%, #6C72FE 100%)",
    cardFallbackBg: "#5b42bd",
    blueCardGradient: "linear-gradient(117.6deg, #6CABFE 2.52%, #71D1FB 100%)",
    blueCardFallback: "#6CABFE",
    greenCardGradient:
      "linear-gradient(110.47deg, #00925D 4.85%, #D9FFE1 106.81%)",
    greenCardFallback: "#00925D",
    back_26: "#262626",
    yellowDark: "#C39C58",
    border3: "#ececec",
    border4: "#E1E9F4",
    placeholderGrey: "#808080",
    cardTime: "#afbace",
    cardBlack: "#5d5c6d",
    light_purple: "#F9F8FF",
    missed: "#F9AA7E",
    purple_2: "#6D5CB4",
    hoverPurple: "#735fc7",
    pink: "#ffdcdd",
    purple_3: "#9a7bcc",
    purple_4: "#8471d1",
    dark_purple: "#483876",
    pink_2: "#e7a4a6",
    dark_purple_2: "#6e51a9",
    brand_logo: "#6E4EA3",
    gray_shade_110: "#D9D9D9",
    link_color: "#0000ee",
  },
  colorsV2: {
    shyft: {
      purple: "#8C7BD1",
      gold: "#EBC481",
      cream: "#F3F3EF",
      orange: "#EBA368",
      blue: "#6DADD3",
      pink: "#E57D78",
      green: "#81C185",
      teal: "#80D0CF",
      purple_text: "#6E55D3",
      gold_text: "#D4A656",
      gradient_main: "linear-gradient(180deg, #5B3EBF 0%, #6E4EA3 100%)",
      logo_purple: "#6E4EA3",
      logo_secondary: "#E5C588",
      error: "#CF3232",
      gold_2: "#FDF0DF",
    },
    gray: {
      shade_25: "#FCFCFD",
      shade_50: "#F9FAFB",
      shade_100: "#F2F4F7",
      shade_200: "#E4E7EC",
      shade_300: "#D0D5DD",
      shade_400: "#98A2B3",
      shade_500: "#667085",
      shade_600: "#475467",
      shade_700: "#344054",
      shade_800: "#1D2939",
      shade_900: "#101828",
    },
    primary: {
      shade_25: "#FCFAFF",
      shade_50: "#F9F5FF",
      shade_100: "#F4EBFF",
      shade_200: "#E9D7FE",
      shade_300: "#D6BBFB",
      shade_400: "#B692F6",
      shade_500: "#9E77ED",
      shade_600: "#7F56D9",
      shade_700: "#6941C6",
      shade_800: "#53389E",
      shade_900: "#42307D",
    },
    error: {
      shade_25: "#FFFBFA",
      shade_50: "#FEF3F2",
      shade_100: "#FEE4E2",
      shade_200: "#FECDCA",
      shade_300: "#FDA29B",
      shade_400: "#F97066",
      shade_500: "#F04438",
      shade_600: "#D92D20",
      shade_700: "#B42318",
      shade_800: "#912018",
      shade_900: "#7A271A",
    },
    warning: {
      shade_25: "#FFFCF5",
      shade_50: "#FFFAEB",
      shade_100: "#FEF0C7",
      shade_200: "#FEDF89",
      shade_300: "#FEC84B",
      shade_400: "#FDB022",
      shade_500: "#F79009",
      shade_600: "#DC6803",
      shade_700: "#B54708",
      shade_800: "#93370D",
      shade_900: "#7A2E0E",
    },
    success: {
      shade_25: "#F6FEF9",
      shade_50: "#ECFDF3",
      shade_100: "#D1FADF",
      shade_200: "#A6F4C5",
      shade_300: "#6CE9A6",
      shade_400: "#32D583",
      shade_500: "#12B76A",
      shade_600: "#039855",
      shade_700: "#027A48",
      shade_800: "#05603A",
      shade_900: "#054F31",
    },
    blueGray: {
      shade_25: "#FCFCFD",
      shade_50: "#F8F9FC",
      shade_100: "#EAECF5",
      shade_200: "#C8CCE5",
      shade_300: "#9EA5D1",
      shade_400: "#717BBC",
      shade_500: "#4E5BA6",
      shade_600: "#3E4784",
      shade_700: "#363F72",
      shade_800: "#293056",
      shade_900: "#101323",
    },
    blueLight: {
      shade_25: "#F5FBFF",
      shade_50: "#F0F9FF",
      shade_100: "#E0F2FE",
      shade_200: "#B9E6FE",
      shade_300: "#7CD4FD",
      shade_400: "#36BFFA",
      shade_500: "#0BA5EC",
      shade_600: "#0086C9",
      shade_700: "#026AA2",
      shade_800: "#065986",
      shade_900: "#0B4A6F",
    },
    blue: {
      shade_25: "#F5FAFF",
      shade_50: "#EFF8FF",
      shade_100: "#D1E9FF",
      shade_200: "#B2DDFF",
      shade_300: "#84CAFF",
      shade_400: "#53B1FD",
      shade_500: "#2E90FA",
      shade_600: "#1570EF",
      shade_700: "#175CD3",
      shade_800: "#1849A9",
      shade_900: "#194185",
    },
    indigo: {
      shade_25: "#F5F8FF",
      shade_50: "#EEF4FF",
      shade_100: "#E0EAFF",
      shade_200: "#C7D7FE",
      shade_300: "#A4BCFD",
      shade_400: "#8098F9",
      shade_500: "#6172F3",
      shade_600: "#444CE7",
      shade_700: "#3538CD",
      shade_800: "#2D31A6",
      shade_900: "#2D3282",
    },
    purple: {
      shade_25: "#FAFAFF",
      shade_50: "#F4F3FF",
      shade_100: "#EBE9FE",
      shade_200: "#D9D6FE",
      shade_300: "#BDB4FE",
      shade_400: "#9B8AFB",
      shade_500: "#7A5AF8",
      shade_600: "#6938EF",
      shade_700: "#5925DC",
      shade_800: "#4A1FB8",
      shade_900: "#3E1C96",
    },
    pink: {
      shade_25: "#FEF6FB",
      shade_50: "#FDF2FA",
      shade_100: "#FCE7F6",
      shade_200: "#FCCEEE",
      shade_300: "#FAA7E0",
      shade_400: "#F670C7",
      shade_500: "#EE46BC",
      shade_600: "#DD2590",
      shade_700: "#C11574",
      shade_800: "#9E165F",
      shade_900: "#851651",
    },
    rose: {
      shade_25: "#FFF5F6",
      shade_50: "#FFF1F3",
      shade_100: "#FFE4E8",
      shade_200: "#FECDD6",
      shade_300: "#FEA3B4",
      shade_400: "#FD6F8E",
      shade_500: "#F63D68",
      shade_600: "#E31B54",
      shade_700: "#C01048",
      shade_800: "#A11043",
      shade_900: "#89123E",
    },
    orange: {
      shade_25: "#FFFAF5",
      shade_50: "#FFF6ED",
      shade_100: "#FFEAD5",
      shade_200: "#FDDCAB",
      shade_300: "#FEB273",
      shade_400: "#FD853A",
      shade_500: "#FB6514",
      shade_600: "#EC4A0A",
      shade_700: "#C4320A",
      shade_800: "#9C2A10",
      shade_900: "#7E2410",
    },
  },
};

export default theme;
