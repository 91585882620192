import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";
import SVGSprite from "@/components/Atoms/SVGSprite";
import Image from "@/components/Atoms/Image";
import Button from "@/components/Atoms/Button";

import Typography from "@/foundation/Typography/V2";

export const DropDown = styled(FlexDiv)`
  opacity: ${({ showCountryDropDown }) => (showCountryDropDown ? "1" : "0")};
  visibility: ${({ showCountryDropDown }) =>
    showCountryDropDown ? "visible" : "hidden"};
`;

export const InputWrap = styled(FlexDiv)`
  position: relative;
  width: 100%;
  .shyft__placeholder {
    right: 0;
    color: ${({ theme }) => theme.colors.placeholderGrey};
  }
  .shyft__value-container {
    position: relative;
    padding: 0px !important;
    .shyft__input input {
      max-width: 34px;
    }
    .icon-phone-2 {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.shyft__value-container--has-value {
      .icon {
        display: none;
      }
    }
  }
  ${DropDown} {
    .shyftList {
      min-width: 100px;
      position: absolute;
      top: 50%;
      width: 100%;
      max-width: 100px;
      transform: translateY(-50%);
      z-index: 3;
      left: 0px;
      border: 1px solid ${({ theme }) => theme.colorsV2.gray.shade_300};
      box-shadow: 2px 4px 4px 0
        rgba(${({ theme }) => theme.colors.shadows.banner}, 0.1);
      background-color: ${({ theme }) => theme.colors.white};
      padding-bottom: 8px;
      border-radius: 8px;
      overflow: hidden;
      > div {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .shyft__menu {
        position: relative;
        transform: none;
        order: 0;
        border: 0px;
        box-shadow: none;
        border-radius: 0px;
        margin-bottom: 12px;
        .shyft__option {
          padding: 4px 8px;
          &.shyft__option--is-selected.shyft__option--is-focused,
          &.shyft__option--is-selected {
            p {
              color: ${({ theme }) => theme.colors.white}!important;
            }
          }
        }
      }
      .shyft__control {
        order: 1;
        width: 100%;
        max-width: 84px;
        margin: 0 auto;
        min-height: 32px;
        max-height: 32px;
        .shyft__indicators {
          display: none;
        }
        &.shyft__control--is-focused {
          border-color: ${({ theme }) => theme.colorsV2.gray.shade_700};
        }
        .shyft__value-container {
          padding: 4px 6px !important;
          height: 100%;
          justify-content: flex-start;
          > div:not(.shyft__placeholder),
          .shyft__placeholder + div {
            margin: 0 2px !important;
            padding: 0 !important;
            height: 100%;
            .shyft__input {
              height: 100%;
              font-size: 12px !important;
              display: flex !important;
              align-items: center;
              color: ${({ theme }) => theme.colorsV2.gray.shade_700} !important;
              input {
                height: 100%;
                width: 100%;
                max-width: 56px !important;
                margin: 0 auto !important;
              }
            }
          }
          .shyft__single-value {
            left: 4px;
            height: 100%;
            padding: 0 4px;
            &:before {
              content: "Search";
              font-size: 12px;
            }
            > div {
              opacity: 0;
            }
          }
        }
        .shyft__placeholder {
          color: ${({ theme }) => theme.colorsV2.gray.shade_400};
          font-size: 12px;
          font-family: var(--man-700) !important;
          right: unset;
        }
      }
    }
  }
`;

export const OTPBox = styled(FlexDiv)`
  width: 100%;
  padding: 8px 8px 0;
  position: relative;
  flex-direction: column;
  .otpWrapper {
    width: 100%;
    justify-content: space-between;
    > div {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
    .input-box {
      width: 100% !important;
      max-width: 46px;
      outline: 0;
      transition: all 0.1s ease-in-out;
      border: 1px solid
        ${({ theme, error }) =>
          error ? theme.colorsV2.shyft.error : theme.colorsV2.gray.shade_400};
      padding: 13px 8px;
      border-radius: 8px;
      color: ${({ theme }) => theme.colorsV2.gray.shade_800};
      font-family: var(--man-700);
      font-size: 24px;
    }
    & + div {
      left: 8px;
    }
  }
`;

export const ResendOTP = styled(Button)`
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

export const PhoneIcon = styled(SVGSprite)`
  fill: ${({ theme }) => theme.colorsV2.gray.shade_700};
`;

export const ArrowDown = styled(SVGSprite)`
  fill: ${({ theme }) => theme.colorsV2.gray.shade_700};
`;
export const PhoneFlag = styled(FlexDiv)``;
export const ImageHolder = styled(Image)``;
export const NumberPlaceholder = styled(FlexDiv)`
  width: 100%;
  padding: 0px 24px 0px 23px;
  align-items: center;
  cursor: pointer;
  ${PhoneIcon} {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  ${PhoneFlag} {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  ${ArrowDown} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 7px;
  }
`;

export const CountryPlaceholder = styled(FlexDiv)`
  position: absolute;
  height: calc(100% - 14px);
  top: 50%;
  transform: translateY(-50%);
  z-index: ${({ showCountryDropDown }) => (showCountryDropDown ? "3" : "2")};
  left: 0px;
  width: 88px;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  border-radius: 0px;
  border-right: 1px solid ${({ theme }) => theme.colorsV2.gray.shade_200};
  max-height: 20px;
`;
export const LoginWrapper = styled(FlexDiv)`
  flex-direction: column;
  align-items: center;
`;
export const InnerWrapper = styled(FlexDiv)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const HeadingTitle = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.shyft.purple_text};
  text-align: center;
  margin-bottom: 16px;
  align-items: center;
  display: flex;
`;
export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
  text-align: center;
`;
export const CountryCode = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
`;
export const TimerCountdown = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray_80};
`;
export const ShadowBtn = styled(Button)`
  box-shadow: 0px 10px 20px
      rgba(${({ theme }) => theme.colors.shadows.purple_3}, 0.18),
    0px 2px 6px rgba(${({ theme }) => theme.colors.shadows.purple_3}, 0.12),
    0px 0px 1px rgba(${({ theme }) => theme.colors.shadows.black}, 0.04);
`;

export const Caption = styled(Typography)`
  margin-top: 16px;
  text-align: center;
  font-size: 12px;
  a {
    font-size: 12px;
  }
`;
