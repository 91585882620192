import { LegacyRef, forwardRef } from "react";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./EmailInput.module.scss";

interface EmailInputProps {
  name: string;
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

const EmailInput = forwardRef(
  (
    { className, ...rest }: EmailInputProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const cls = classNameGenerator(styles);

    return (
      <input
        type="email"
        ref={ref}
        className={clsx(cls("emailInput"), className)}
        {...rest}
      />
    );
  }
);

EmailInput.displayName = "EmailInput";

export default EmailInput;
