import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

interface InitialState {
  isLoading: boolean;
  error: any;
  success: boolean;
  data: any;
  isSlotsLoading: boolean;
  slotsError: any;
  slotsSuccess: boolean;
  slotsData: any;
  showBookSlotModal: any;
  showBookTrialClassModal: any;
  showBookYogaNutritionTrialModal: any;
  showOneOnOneTrialCallbackModal: boolean;
  showTncModal: any;
}

const initialState: InitialState = {
  isLoading: false,
  error: "",
  success: false,
  data: {},
  isSlotsLoading: false,
  slotsError: "",
  slotsSuccess: false,
  slotsData: {},
  showBookSlotModal: false,
  showBookTrialClassModal: false,
  showBookYogaNutritionTrialModal: false,
  showOneOnOneTrialCallbackModal: false,
  showTncModal: false
};

const bootCampSlice = createSlice({
  name: "bootCamp",
  initialState,
  reducers: {
    setShowBookSlotModal(state, { payload }) {
      return {
        ...state,
        showBookSlotModal: payload,
      };
    },
    setShowBookTrialClassModal(state, { payload }) {
      return {
        ...state,
        showBookTrialClassModal: payload,
      };
    },
    setShowBookYogaNutritionTrialModal(state, { payload }) {
      return {
        ...state,
        showBookYogaNutritionTrialModal: payload,
      };
    },
    setShowOneOnOneTrialCallbackModal(state, { payload }) {
      return {
        ...state,
        showOneOnOneTrialCallbackModal: payload,
      };
    },
    setShowTncModal(state, { payload }) {
      return {
        ...state,
        showTncModal: payload,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }: AnyAction) => ({
        ...state,
        ...payload.bootCamp,
      }))
      // GET BOOTCAMP LANDING PAGE
      .addCase(actions.getBootCampPageData.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(actions.getBootCampPageData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          success: true,
          data: payload,
        };
      })
      .addCase(actions.getBootCampPageData.rejected, (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload,
      }))
      // GET ONE ON ONE YOGA SLOTS
      .addCase(actions.getOneOnOneYogaSlots.pending, (state) => ({
        ...state,
        isSlotsLoading: true,
        slotsSuccess: false,
        slotsError: "",
      }))
      .addCase(actions.getOneOnOneYogaSlots.fulfilled, (state, { payload }) => {
        const slotsData = payload?.data?.meta?.data?.available_slots_dates;

        const today = new Date();

        function getAbbreviatedDayName(date) {
          const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          return days[date.getDay()];
        }

        function getNextWeekDates() {
          const nextWeekDates = [];
          for (let i = 0; i < 7; i++) {
            const nextDay = new Date(today);
            nextDay.setDate(today.getDate() + i);
            nextWeekDates.push({
              display_date: `${nextDay.getDate()} ${nextDay
                .toLocaleString("en-us", { month: "short" })
                .substring(0, 3)}`,
              date: nextDay.toISOString().split("T")[0],
              day: nextDay
                .toLocaleString("en-us", { weekday: "short" })
                .substring(0, 3),
            });
          }

          return nextWeekDates;
        }

        const todayAbbreviated = getAbbreviatedDayName(today);
        const nextWeekDates = getNextWeekDates();

        const todayIndex = slotsData?.findIndex(
          ({ day }) => day?.toLowerCase() === todayAbbreviated.toLowerCase()
        );

        const rearrangedDays = slotsData
          .slice(todayIndex)
          .concat(slotsData.slice(0, todayIndex))
          ?.map((d) => {
            const _date =
              nextWeekDates?.find(
                ({ day }) => day?.toLowerCase() === d?.day?.toLowerCase()
              ) || {};

            return {
              ...d,
              ..._date,
            };
          });

        return {
          ...state,
          isSlotsLoading: false,
          slotsSuccess: true,
          slotsData: rearrangedDays,
        };
      })
      .addCase(actions.getOneOnOneYogaSlots.rejected, (state, { payload }) => ({
        ...state,
        isSlotsLoading: false,
        slotsError: payload || "",
      }));
  },
});

export { getBootCampPageData, getOneOnOneYogaSlots } from "./Actions";

export const {
  setShowBookSlotModal,
  setShowBookTrialClassModal,
  setShowBookYogaNutritionTrialModal,
  setShowOneOnOneTrialCallbackModal,
  setShowTncModal
} = bootCampSlice.actions;

export default bootCampSlice.reducer;
