import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { IntercomProvider, useIntercom } from "react-use-intercom";

function Intercom({ children }) {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return <>{children}</>;
}

function IntercomWrapper({ appId, children }) {
  const router = useRouter();
  const INTERCOM_INITIALIZE_DELAY = router.pathname === "/faqs" ? 10 : 5000;
  return (
    <IntercomProvider appId={appId} initializeDelay={INTERCOM_INITIALIZE_DELAY}>
      <Intercom>{children}</Intercom>
    </IntercomProvider>
  );
}

export default IntercomWrapper;
