import React, { Fragment, useState } from "react";
import { FlexDiv } from "../Box";
import SelectInput from "./Select";
import { components } from "react-select";
import InputField from "./Input";
import styled from "styled-components";
import SVGSprite from "../SVGSprite";
import theme from "../../../foundation/Theme";

const Menu = (props) => {
  const [country, setCountry] = useState("");
  const setValue = (value) => {
    if (value !== " " && value !== country) setCountry(value);
  };
  return (
    <Fragment>
      <components.Menu {...props}>
        <>
          {props.children}
          <FlexDiv p={"12px"}>
            <InputField
              placeholder={"Search"}
              inputPadding={"4px 7px"}
              inputLineHeight={"16.8px"}
              value={country}
              onChange={setValue(props?.selectProps?.inputValue)}
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </FlexDiv>
        </>
      </components.Menu>
    </Fragment>
  );
};

export const CaretDownIcon = () => {
  return (
    <SVGSprite
      icon={"icon-arrow-down"}
      fill={theme.colors.quote}
      height={"8px"}
      width={"8px"}
    />
  );
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

export const Input = (props) => {
  return <components.Input pattern="[0-9]*" inputMode="decimal" {...props} />;
};

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {children && (
          <SVGSprite
            icon="icon-phone"
            fill={theme.colors.quote}
            height={"19px"}
            width={"19px"}
          />
        )}
        {children}
      </components.ValueContainer>
    )
  );
};

function CountryDropdown(props) {
  const {
    options,
    onChange,
    wrapperClassName = "",
    variant = "v1",
    ...rest
  } = props || {};

  return (
    <SelectWrapper className={wrapperClassName}>
      <SelectInput
        components={{ Input }}
        options={options}
        onChange={onChange}
        zIndex={"unset"}
        tabIndex={"-1"}
        variant={variant}
        instanceId="country-code-dropdown"
        {...rest}
      />
    </SelectWrapper>
  );
}

export default CountryDropdown;

const SelectWrapper = styled(FlexDiv)``;
