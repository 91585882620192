import { Control, Controller } from "react-hook-form";

import Input from "@/components/Atoms/InputsV2/CheckboxInput";
import ErrorUI from "@/components/Atoms/InputsV2/ErrorUI";

import { classNameGenerator } from "@/utils";

import styles from "./CheckboxInput.module.scss";

interface CheckboxInputProps {
  id: string;
  name: string;
  control: Control<any>;
  label?: string;
  className?: string;
  onBlur?: (e: any) => void;
}

export const isValidName = (value) =>
  value &&
  (value.match(/^[a-zA-Z\s]*$/) || value === "") &&
  value.length <= 140;

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  id,
  name,
  control,
  label,
  onBlur = () => {},
  ...rest
}) => {
  const cls = classNameGenerator(styles);

  const validateName = (e) => {
    const value = e.target.value;
    return value === "" || isValidName(value);
  };

  return (
    <div className={cls("checkboxInput")}>
      <Controller
        name={name}
        control={control}
        shouldUnregister
        render={({
          field: { value, onBlur: handleBlur, onChange, ref },
          fieldState: { error },
        }) => (
          <>
            <Input
              id={id}
              ref={ref}
              label={label}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              {...rest}
            />
            <ErrorUI error={error?.message} />
          </>
        )}
      />
    </div>
  );
};

export default CheckboxInput;
