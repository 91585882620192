import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
    showImagePreviewModal: any;
}

const initialState: InitialState = {
    showImagePreviewModal: false
};

const aiChatbotSlice = createSlice({
    name: "aiChatbot",
    initialState,
    reducers: {
        setImagePreviewModal(state, { payload }) {
            return {
                ...state,
                showImagePreviewModal: payload,
            };
        }
    }
});

export const {
    setImagePreviewModal
} = aiChatbotSlice.actions;

export default aiChatbotSlice.reducer;
