import { LegacyRef, forwardRef } from "react";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./RadioInput.module.scss";

interface TextInputProps {
  id?: string;
  className?: string;
  checked?: boolean;
  label?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

const RadioInput = forwardRef(
  (
    { id, className, checked, label, ...rest }: TextInputProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const cls = classNameGenerator(styles);

    return (
      <label htmlFor={id} className={cls("radioLabel")}>
        <input
          id={id}
          type="radio"
          ref={ref}
          className={clsx(cls("radioInput"), className)}
          checked={checked}
          {...rest}
        />
        <span className={cls("checkmark")}></span>
        {label}
      </label>
    );
  }
);

RadioInput.displayName = "RadioInput";

export default RadioInput;
