import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import { DEFAULT_API_ERROR_MESSAGE } from "../../utils/Constants";
import { RAZOR_KEY_ID } from "../../utils/Constants/env";
import { sentryLogs, timezone } from "../../utils";

import logo from "../../static/logo-mh-razorpay.png";
import theme from "../../foundation/Theme";

function useRazorPay({
  name,
  countryCode,
  mobile,
  email,
  onDismiss,
  onSuccessCallback,
}) {
  const router = useRouter();
  const [razorPayError, setRazorPayError] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  let desc = "";
  let totalPrice = "";
  let currency = "";

  let razorPayOptions = {
    key: RAZOR_KEY_ID,
    amount: totalPrice * 100, //  100 = 1 INR
    currency: currency,
    name: name,
    description: desc,
    image: logo,
    handler: function (response) {
      if (response.razorpay_payment_id) {
        onSuccessCallback({ ...response, amount: totalPrice });
      }
    },
    prefill: {
      contact: "",
      email: "",
    },

    theme: {
      color: theme.colorsV2.shyft.purple,
    },
    modal: {
      ondismiss: async function () {
        await onDismiss();
      },
    },
  };

  const initiateRazorPayPayment = () => {
    const onLoad = () => {
      let rzp1 = new window.Razorpay(razorPayOptions);
      rzp1.open();
    };
    if (!window.Razorpay) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.defer = true;
      document.body.appendChild(script);
      script.onload = onLoad;

      script.onerror = () => {
        setRazorPayError(DEFAULT_API_ERROR_MESSAGE);
        sentryLogs(
          "error",
          "Razorpay library not loaded hence Razorpay is undefined."
        );
      };
    } else {
      onLoad();
    }
  };

  const openPaymentModal = (
    _totalPrice,
    _currency,
    _desc,
    callback = () => {},
    notesData
  ) => {
    totalPrice = _totalPrice;
    currency = _currency;
    desc = _desc;
    callback();

    const {
      package_slug,
      page_type,
      purchased_plan_slug,
      package_start_date,
      urls_arguments,
    } = notesData || {};

    razorPayOptions = {
      ...razorPayOptions,
      amount: totalPrice * 100,
      currency: currency,
      description: desc,
      secondaryBuy: false,
      prefill: {
        name,
        contact: (countryCode ? countryCode : "+91") + mobile,
        email: email ? email : "",
      },
      notes: {
        name,
        primary_contact: (countryCode || "+91") + mobile,
        ...(email && {
          email: email,
        }),
        package_slug: package_slug,
        urls_arguments: urls_arguments,
        purchased_plan_slug: purchased_plan_slug,
        package_start_date: package_start_date,
        page_type,
        timezone: timezone,
      },
    };
    initiateRazorPayPayment();
  };

  return { openPaymentModal, razorPayError };
}

export default useRazorPay;
