import React from "react";
import styled from "styled-components";
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  system,
} from "styled-system";

interface SVGSpriteProps {
  icon: string;
  className?: string;
  height?: string;
  width?: string;
}

const SVGSpriteComponent: React.FC<SVGSpriteProps> = ({
  icon,
  className = "",
  height,
  width,
  ...rest
}) => {
  return (
    <svg
      className={`icon ${icon} ${className}`}
      height={height}
      width={width}
      {...rest}
    >
      <use xlinkHref={`/images/icon.svg?v=1#${icon}`} {...rest} />
    </svg>
  );
};

const fill = system({
  fill: {
    property: "fill",
    scale: "colors",
  },
});

const SVGSprite = styled(SVGSpriteComponent)<{ [key: string]: any }>`
  ${space}
  ${color}
    ${layout}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
    ${shadow}
    ${fill}
`;

export default SVGSprite;
