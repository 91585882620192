import { useCallback, useState } from "react";

import {
  isValidMobile,
  matchCountryRegex,
} from "@/components/Molecules/HookFormInputs/V1/PhoneInput";
import { isValidEmail } from "@/components/Molecules/HookFormInputs/V1/EmailInput";

import { addKey, axios } from "@/utils";

import useRazorpay from "../useRazorpayPayment";

import { useAppDispatch } from "../useRedux";
import * as actions from "@/redux/LandingPageReducer";
import { removeKeys } from "../../utils/index"
import { useRouter } from "next/router";
interface mapDataI {
  name?: string;
  phone_prefix?: string;
  mobile?: string;
  email?: string;
  package_slug?: string;
  urls_arguments?: string;
  timezone?: string;
  host: string;
}

interface useFormPaymentI {
  methods: any;
  packData: any;
  urlsArguments: string;
  timezone: string;
  onSuccessCallback?: (data: any) => void;
  onFailureCallback?: (data: any) => void;
  additionalData?: any;
  isPromoCodeApplied?: boolean;
  setFormError?: (error: string) => void;
}

const useFormPayment = ({
  methods,
  packData,
  urlsArguments,
  timezone,
  onSuccessCallback = () => { },
  onFailureCallback = () => { },
  additionalData = {},
  isPromoCodeApplied,
  setFormError = () => {}
}: useFormPaymentI) => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { query } = router || {};
  const [isLoading, setIsLoading] = useState(false);
  const isGeneralYogaPage = query?.bootcampType === "yoga" && query?.slug === undefined

  const { openPaymentModal } = useRazorpay();

  const { getValues } = methods;

  const mapData = useCallback(
    (formValues): mapDataI => {
      const {
        name,
        mobileCountryCode,
        mobile,
        email,
        mhGoal,
        promoCode,
        nutritionGoalID,
        joinCommunity,
        referrerName,
        referrerMobileCountryCode,
        referrerMobile,
        selectedOneOnOneYogaDate,
        selectedOneOnOneYogaSlot,
        preferredContactMedium,
        ...rest
      } = formValues;

      return {
        ...addKey("name", name),
        ...addKey("phone_prefix", mobileCountryCode),
        ...addKey("mobile", mobile),
        ...addKey("email", email),
        ...addKey("mh_goal", mhGoal),
        ...addKey("nutrition_goal_id", nutritionGoalID),
        ...(isPromoCodeApplied && addKey("coupon_code", promoCode)),
        ...addKey("package_slug", packData?.packageSlug),
        ...addKey("purchased_plan_slug", packData?.purchasedPlanSlug),
        ...addKey("urls_arguments", urlsArguments),
        ...addKey("referrer_name", referrerName),
        ...addKey("referrer_phone_prefix", referrerMobileCountryCode),
        ...addKey("referrer_mobile", referrerMobile),
        ...addKey("selected_date", selectedOneOnOneYogaDate),
        ...addKey("selected_slot", selectedOneOnOneYogaSlot),
        ...addKey("preferred_contact_medium", preferredContactMedium),
        timezone,
        host: process.env.NEXT_PUBLIC_WEBSITE_HOST,
        ...rest,
      };
    },
    [packData, urlsArguments, timezone, isPromoCodeApplied]
  );

  const onBlur = (event) => {
    let values = getValues();
    const name = event.target.name;
    const value = event.target.value;

    if (name) {
      values = {
        ...values,
        [name]: value,
      };
    }

    const { mobile, mobileCountryCode, email } = values;

    const isValidContactDetail =
      (isValidMobile(mobile) && matchCountryRegex(mobileCountryCode, mobile)) ||
      isValidEmail(email);

    if (isValidContactDetail && packData?.packageSlug) {
      const apiData = {
        is_lead: true,
        ...mapData(values),
      };
      dispatch(actions.saveLead({ data: apiData }));
    }
  };

  const onDismiss = () => {
    const values = getValues();
    setIsLoading(false);
    const apiData = {
      is_lead: true,
      ...mapData(values),
      payment_mode: "razorpay",
      payment_stage: "payment_cancel",
      ...additionalData,
    };
    dispatch(actions.saveLead({ data: apiData }));
  };

  const onSuccess = ({ payment_mode, transaction_id, amount, values }) => {
    const apiData = {
      ...values,
      is_payment_completed: true,
      payment_mode,
      transaction_id,
      is_callback_request: false,
      amount_paid: amount,
      currency: packData?.currency,
      ...additionalData,
    };
    return dispatch(actions.saveLead({ data: apiData }))
      .then((res) => {
        setIsLoading(false);
        if (!res?.payload?.status) {
          return onFailureCallback(res);
        }
        onSuccessCallback(apiData);
      })
      .catch(() => {
        setIsLoading(false);
        onFailureCallback(apiData);
      });
  };

  const checkYogaPackPurchase =  async (formValues) => {
    try{
      const url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}admin/package-purchase-check/`
      const res = await axios.post(url,formValues)
      return res?.status
    }catch(err) {
      return false
    }
  }

  const onPackPurchase = async ({ keys = [], ...formValues }) => {
    setIsLoading(true);

    const values = mapData({ ...formValues });

    if(isGeneralYogaPage){
      const hasYogaPackPurchase = await checkYogaPackPurchase(values) 
      if(!hasYogaPackPurchase){
        setIsLoading(false)
        setFormError("You have already availed this pack.")
        return;
      }

    }

    const paymentSuccessPayload = {
      ...values
    }
    const apiData = {
      ...values,
      is_lead: true,
      event_state: "payment_initiated",
      currency: packData?.currency,
    };
    dispatch(actions.saveLead({ data: apiData }));
    //remove extra keys to avoid razorpay notes limit
    removeKeys({ obj: values, keys })

    if (packData?.price) {
      const paymentData = {
        ...values,
        amount: packData?.price,
        currency: packData?.currency,
        description: packData?.name,
        onDismiss,
        onSuccess: (data) =>
          onSuccess({
            ...data,
            payment_mode: "razorpay",
            transaction_id: data?.razorpay_payment_id,
            values: paymentSuccessPayload
          }),
      };
      openPaymentModal(paymentData);
    } else {
      onSuccess({
        payment_mode: "coupon_code",
        transaction_id: String(Date.now()),
        amount: 0,
        values: paymentSuccessPayload
      });
    }
  };

  const onRequestCallback = (formValues) => {
    setIsLoading(true);

    const apiData = {
      ...mapData(formValues),
      is_callback_request: true,
      join_whatsapp_community: !!formValues?.joinCommunity,
      ...additionalData,
    };

    return dispatch(actions.saveLead({ data: apiData })).then(() => {
      setIsLoading(false);
    });
  };

  return {
    isLoading,
    mapData,
    onBlur,
    onPackPurchase,
    onRequestCallback,
  };
};

export default useFormPayment;
