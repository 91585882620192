import { createSlice } from "@reduxjs/toolkit";

import * as actions from "./Actions";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
  isLoadingCareer: false,
  errorCareer: "",
  successCareer: false,
  dataCareer: {},
  isGetJobDetailLoading: false,
  getJobDetailsError: "",
  getJobDetailSuccess: false,
  jobData: {},
};

const CareerPageSlice = createSlice({
  name: "careerPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => {
        return {
          ...state,
          ...payload.careerPage,
        };
      })
      .addCase(actions.careerDetailsApi.pending, (state) => ({
        ...state,
        isLoadingCareer: true,
        errorCareer: "",
        successCareer: false,
      }))
      .addCase(actions.careerDetailsApi.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoadingCareer: false,
          errorCareer: "",
          successCareer: false,
          dataCareer: payload?.data,
        };
      })
      .addCase(actions.careerDetailsApi.rejected, (state, { payload }) => {
        return {
          ...state,
          isLoadingCareer: false,
          errorCareer: payload,
          successCareer: false,
          status: false,
        };
      })
      .addCase(actions.getJobDetailsById.pending, (state) => ({
        ...state,
        isGetJobDetailLoading: true,
        getJobDetailsError: "",
        getJobDetailSuccess: false,
        jobData: {},
      }))
      .addCase(actions.getJobDetailsById.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isGetJobDetailLoading: false,
          getJobDetailsError: "",
          getJobDetailSuccess: true,
          jobData: payload?.data,
        };
      })
      .addCase(actions.getJobDetailsById.rejected, (state, { payload }) => {
        return {
          ...state,
          isGetJobDetailLoading: false,
          getJobDetailsError: payload,
          getJobDetailSuccess: false,
        };
      });
  },
});

export { careerDetailsApi, getJobDetailsById } from "./Actions";

export default CareerPageSlice.reducer;
