import { LegacyRef, forwardRef } from "react";

import Button from "@/components/Atoms/Button";
import SVGSprite from "@/components/Atoms/SVGSprite";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./PromoCodeInput.module.scss";

interface PromoCodeInputProps {
  name: string;
  placeholder?: string;
  className?: string;
  value?: string;
  isLoading?: boolean;
  ctaText: string;
  isPromoCodeApplied?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onCTAClick?: (e: any) => void;
  defaultValue?: string;
}

const PromoCodeInput = forwardRef(
  (
    {
      className,
      isLoading,
      ctaText,
      onCTAClick,
      isPromoCodeApplied,
      onChange = () => {},
      defaultValue,
      ...rest
    }: PromoCodeInputProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const cls = classNameGenerator(styles);

    return (
      <div className={cls("promoCodeInputWrapper")}>
        <SVGSprite icon="icon-discount" className={cls("discount")} />
        <input
          type="text"
          ref={ref}
          className={clsx(cls("promoCodeInput"), className)}
          onChange={(e) => {
            const event = {
              ...e,
              target: {
                ...e.target,
                value: e?.target?.value?.toUpperCase()?.trim() || "",
              },
            };
            onChange(event);
          }}
          defaultValue={defaultValue}
          {...rest}
        />
        {isPromoCodeApplied ? (
          <SVGSprite icon="icon-check-success" className={cls("icon")} />
        ) : (
          <Button
            className={cls("actionButton")}
            isLoading={isLoading}
            onClick={onCTAClick}
          >
            {ctaText}
          </Button>
        )}
      </div>
    );
  }
);

PromoCodeInput.displayName = "PromoCodeInput";

export default PromoCodeInput;
