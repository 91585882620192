import styled from "styled-components";

import SVGSprite from "@/components/Atoms/SVGSprite";

export const CloseIcon = styled(SVGSprite)`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Icon = styled(SVGSprite)`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
