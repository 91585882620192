import { DEFAULT_URL_ARGUMENTS, PARTNER_URL_ARGUMENTS } from "@/constants";
import axios from "./Axios";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";

export { axios };

export * from "./CookieStorage";
export * from "./Validators";
export * from "./MetaTags";
export * from "./Scripts";

export const noop = () => { };

export const propTypeChildren = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const propTypeRef = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.any }),
]);

export const timezone =
  (typeof window !== "undefined" &&
    typeof Intl !== "undefined" &&
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone) ||
  "";

const isMobileCheck = () => {
  let width = 1400;
  if (typeof window !== "undefined") {
    width = window.innerWidth;
  }
  return width <= 600;
};

export const isMobile = isMobileCheck();

export function sentryLogs(type, message, extraScope, tags) {
  Sentry.withScope((scope) => {
    if (extraScope && Object.keys(extraScope).length)
      for (let key in extraScope) scope.setExtra(key, extraScope[key]);
    scope.setExtra("customError", true);
    // scope.clear();
    if (tags && Object.keys(tags).length > 0) {
      scope.setTags(tags);
    }
    if (type === "error") Sentry.captureException(new Error(message));
    else if (type === "event") Sentry.captureEvent({ message: message });
    else Sentry.captureMessage(message);
  });
}

export const generateQueryArguments = (
  query: { [key: string]: string } = {}
) => {
  let search = "";
  if (typeof window !== "undefined") {
    search = document?.location?.search;
  }

  const params = new URLSearchParams(search);

  Object.keys(query)?.map((key) => {
    const value = params.get(key);
    if (!value) params.set(key, query[key]);
  });

  const paramsString = params.toString();

  return paramsString ? `?${paramsString}` : "";
};

export const generateQueryArgumentsServerSide = (
  query: { [key: string]: string } = {}
) => {
  let paramsString = "";
  let isFirstItem = true;

  Object.keys(query)?.map((key) => {
    const value = query[key];
    if (value) {
      if (isFirstItem) {
        paramsString += `?${key}=${value}`;
        isFirstItem = false;
      } else {
        paramsString += `&${key}=${value}`;
      }
    }
  });

  return paramsString;
};

export const generateUrlArguments = (
  urlParams: string = "",
  addDefault: { [key: string]: string | boolean } = {}
) => {
  let search = "";
  let pathname = "";
  if (typeof window !== "undefined") {
    search = document?.location?.search;
    pathname = document?.location?.pathname?.substring(1);
  }

  const params = new URLSearchParams(urlParams || search);

  Object.keys(addDefault)?.map((key) => {
    const value = params.get(key);
    if (!value) params.set(key, addDefault[key]);
  });

  if (search) {
    params.delete("lead_via_main_website");
  }

  const utmPage = params.get("utm_page");
  if (!utmPage) params.append("utm_page", pathname || "homepage");

  const paramsString = params?.toString()?.replaceAll("%2F", "/");

  return paramsString ? `?${paramsString}` : "";
};

export const generateStaticPageParams = (list) =>
  list.map((slug) => ({
    params: { slug },
  }));

export const appendGoogleAnalyticsScript = () => {
  const isProduction = process.env.NODE_ENV === "production";

  if (isProduction && typeof window !== "undefined") {
    const head = document.getElementsByTagName("head")[0];

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = true;

    const code = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
      gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADS_ID}');
    `);
    script.appendChild(code);

    head.appendChild(script);
  }
};

export const appendGoogleTagManagerScript = () => {
  const isProduction = process.env.NODE_ENV === "production";

  if (isProduction && typeof window !== "undefined") {
    const head = document.getElementsByTagName("head")[0];

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = true;

    const code = document.createTextNode(`
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.defer = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-K58CM2J");
    `);
    script.appendChild(code);

    head.appendChild(script);
  }
};

export const captureFBEvent = (payload: any, arg: any) => {
  const isProduction = process.env.NODE_ENV === "production";

  if (isProduction && typeof window !== "undefined") {
    const body = document.getElementsByTagName("body")[0];

    const fbcapture = document.createElement("div");
    fbcapture.id = "fbcapture";
    fbcapture.setAttribute("packageslug", arg?.data?.package_slug);
    fbcapture.innerHTML = payload.lead_id;

    body.appendChild(fbcapture);

    setTimeout(() => {
      body.removeChild(fbcapture);
    }, 500);
  }
};

const random16Digits = () => {
  let string = "";

  while (string.length < 16) {
    const number = Math.floor(Math.random() * 10).toString();
    string += number;
  }
  return string;
};

function xor(bytes1: string, bytes2: string) {
  const result = new Uint8Array(bytes1.length);
  for (let i = 0; i < bytes1.length; i++) {
    result[i] = bytes1[i] ^ bytes2[i % bytes2.length];
  }
  return result;
}

export const encryptDict = (plaintextDict: any, key: any) => {
  const plaintext = JSON.stringify(plaintextDict);
  const keyRepeated = key
    .repeat(Math.ceil(plaintext.length / key.length))
    .substr(0, plaintext.length);
  const plaintextBytes = Buffer.from(plaintext, "utf-8");
  const keyBytes = Buffer.from(keyRepeated, "utf-8");
  const ciphertextBytes = xor(plaintextBytes, keyBytes);
  const randomNumber = random16Digits();
  const finalString =
    randomNumber + Buffer.from(ciphertextBytes).toString("hex");

  return Buffer.from(finalString).toString("base64");
};

const getUnixTime = () =>
  fetch("https://worldtimeapi.org/api/timezone/Etc/UTC")
    .then((response) => response.json())
    .then((data) => data?.unixtime)
    .catch(() => Math.floor(Date.now() / 1000));

export const generateAccessKey = async (data) => {
  const salt = process.env.NEXT_PUBLIC_OTP_SALT || "";
  if (!salt) {
    console.log(
      "[log-salt]",
      "Please add Salt in NEXT_PUBLIC_OTP_SALT Environment Variable"
    );
  }
  const timestamp = await getUnixTime();
  return encryptDict({ ...data, timestamp }, salt);
};

export const appendArguments = (url, append) =>
  `${url}${url.includes("?") ? "&" : "?"}${append}`;

export const getURLParams = () => {
  let search = "";
  if (typeof window !== "undefined") {
    search = document?.location?.search;
  }

  return new URLSearchParams(search);
};

export const isEmptyObject = (obj) => !Object.keys(obj)?.length;

export const getValuesFromDate = (value: Date) => {
  let date = String(value?.getDate());
  let month = String(value?.getMonth() + 1);
  let year = String(value?.getFullYear());

  if (date?.length === 1) date = `0${date}`;
  if (month?.length === 1) month = `0${month}`;

  return { date, month, year };
};

export const clsx = (...atr: any[]) => atr.join(" ");

export const classNameGenerator = (styles: any) => (className: string) =>
  clsx(className, styles[className]);

export const getCanonicalURL = () => {
  let canonicalURL = "";
  if (typeof window !== "undefined") {
    let [protocol, origin] = window.location.origin.split("//");
    origin = origin.replace("www.", "");
    let pathname = window.location.pathname;
    if (pathname?.endsWith("/")) {
      pathname = pathname.substring(0, pathname.length - 1);
    }
    canonicalURL = `https://www.${origin}${pathname}/`;
  }
  return canonicalURL;
};

export const handleScrollToSection = ({ refElement, position = "start" }) => {
  if (refElement?.current) {
    let element = refElement.current;
    element.scrollIntoView({
      behavior: "smooth",
      block: position,
    });
  }
};

export const handleScrollIntoView = ({ id, position = "start" }) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: position,
    });
  }
};

export const getAdsetParams = ({
  urlParams,
  adsetValue = "",
  isPartnerWebsite = true,
  isOldUserReactivationPage = false,
}: any) => {
  let url = `${isPartnerWebsite ? "?" + PARTNER_URL_ARGUMENTS : DEFAULT_URL_ARGUMENTS
    }`;
  const adsetName =
    new URLSearchParams(urlParams).get("adset_name") ||
    new URLSearchParams(urlParams).get("adset name");
  if (urlParams) {
    url = `${urlParams}${urlParams.endsWith("&") ? "" : "&"}${isPartnerWebsite ? PARTNER_URL_ARGUMENTS : DEFAULT_URL_ARGUMENTS
      }`;
  }
  if (!adsetName) {
    url = `${url}${adsetValue ? `&adset_name=${adsetValue}` : ""}`;
  }
  if (isOldUserReactivationPage) {
    url = url + "&utm_source=allservices&adset_name=all_services";
  }
  return url;
};

export const priceCommaSeparator = (
  str: string,
  index: number,
  stringToAdd: string
) => {
  if (str !== undefined) {
    let price =
      str?.includes(",") || str?.length <= 3
        ? str
        : str?.substring(0, index) +
        stringToAdd +
        str?.substring(index, str?.length);
    return price;
  }
};

export const debounce = (callback: Function, delay = 500) => {
  let timerContext: NodeJS.Timeout;
  return function () {
    clearTimeout(timerContext);
    const self = this;
    const args = arguments;
    timerContext = setTimeout(() => callback.apply(self, args), delay);
  };
};

export const addKey = (key: string, value: any) =>
  value ? { [key]: value } : {};

export const appendRazorpayScript = () => {
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  script.async = true;
  document.body.appendChild(script);
};

export function getTimeDifferenceFromNow(targetDate: string = "") {
  const [day, month, year] = targetDate.split("-");
  const targetDateTime = new Date(
    `${year}-${month}-${day}T00:00:00+05:30`
  ).getTime(); // +05:30 is the offset for IST

  const now = new Date().getTime();
  const timeDifference = targetDateTime - now;

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export function isDateInPast(targetDate: string = "") {
  // Parse the target date string in the format dd-mm-yyyy
  const [day, month, year] = targetDate.split("-");
  const targetDateTime = new Date(
    `${year}-${month}-${day}T00:00:00+05:30`
  ).getTime();

  // Get the current time
  const now = new Date().getTime();

  // Compare the target date with the current date
  return targetDateTime < now;
}

export const appendPaymentAnalyticsScript = ({ amount_paid, currency }) => {
  const isProduction = true || process.env.NEXT_PUBLIC_SENTRY_ENV === "production";
  if (isProduction && typeof window !== "undefined") {
    const head = document.getElementsByTagName("head")[0];

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = true;
    const code = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('event', 'conversion', {
        'send_to': '${process.env.NEXT_PUBLIC_PACK_PAYMENT_ANALYTICS_ID}',
        'value': ${parseFloat(amount_paid)},
        'currency': '${currency}'
      });
    `;

    script.appendChild(document.createTextNode(code));
    head.appendChild(script);
  }
}

export const removeKeys = ({ obj, keys }) => {
  keys?.forEach((_val) => delete obj[_val])
}

export const getCurrentDate = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it is zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const appendClarityScript = () => {
  const isProduction = process.env.NODE_ENV === "production";

  if (isProduction && typeof window !== "undefined") {
    const head = document.getElementsByTagName("head")[0];

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = true;

    const code = document.createTextNode(`
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){
          (c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);
        t.async=1;
        t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_TOKEN}");
    `);
    script.appendChild(code);

    head.appendChild(script);
  }
};

export function getCurrentDateFormatted() {
  const date = new Date();

  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th'; // catch exceptions for 11th to 20th
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
  const year = date.getFullYear();

  const dayWithSuffix = day + getOrdinalSuffix(day);

  return `${dayWithSuffix} ${month} ${year}`;
}