import React from "react";

import { Paragraph } from "../style";

interface ParagraphProps {
  children?: React.ReactNode;
  variant: string;
}

const renderParagraph = ({ children, variant, ...rest }: ParagraphProps) => {
  const paragraphProps = { ...rest };

  return (
    ["paragraph1", "paragraph2", "paragraph3", "paragraph4"].includes(
      variant
    ) && <Paragraph {...paragraphProps}>{children}</Paragraph>
  );
};

export default renderParagraph;
