import axios from "axios";

let baseURL = process.env.NEXT_PUBLIC_LOCAL_API_URL;

if (typeof window !== "undefined") {
  const searchParams = new URLSearchParams(window.location.search);
  const hostName = searchParams?.get("host_name") || "";
  baseURL = hostName || process.env.NEXT_PUBLIC_LOCAL_API_URL;
}

const createHeaders = () => {
  let headerObj = {};
  const accessToken = "";
  // const accessToken = getCookie("token");
  if (accessToken) headerObj = { Authorization: accessToken };
  return headerObj;
};

export const errorHandling = (err) => {
  if (typeof err === "string") return console.log(err);

  try {
    const isError = err?.isError;
    if (isError) return console.log(JSON.stringify(err.data));
  } catch (error) {
    return console.log("Something went wrong.", "error");
  }

  if (err.message === "Network Error")
    return console.log("Can't connect to servers", "error");

  const statusCode = err?.response?.status;
  if (!statusCode) {
    return console.log("Something went wrong", "error");
  }
  // if (statusCode === 401 || statusCode === 403) {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   // store.dispatch(authActions.logout);
  //   if (window.location.href !== baseURL) {
  //     window.location.href = baseURL || "";
  //   }
  // }
  const statusType = statusCode.toString()[0];
  if (statusType === "4") {
    if (statusCode === 401 || statusCode === 403) {
      return console.log("Unauthorized", "error");
    }
    if (statusCode === 422) {
      return console.log(JSON.stringify(err?.response?.data?.data));
    }
    return console.log("API Not Found!", "error");
  }
  if (statusType === "3") {
    return "Redirecting...";
  }
  if (statusType === "5") {
    return console.log("Internal server error", "error");
  }
  return console.log("Something went wrong", "error");
};

export const apiErrorHandling = (err) => {
  errorHandling(err);
  return Promise.reject(err?.response?.data || err);
};

export const apiDataExtractor = (res) =>
  Promise.resolve().then(() => {
    //TODO: Add Status Check Again
    // if (!res?.data?.status) {
    //   console.log(res?.message, "error");
    //   throw res?.data;
    // }
    return res?.data;
  });

const axiosApi = axios.create({ baseURL: `${baseURL}/` });

axiosApi.interceptors.request.use((request) => {
  if (!request.ignoreAuth)
    request.headers = { ...request.headers, ...createHeaders() };
  return request;
});

axiosApi.interceptors.response.use(
  (response) => apiDataExtractor(response),
  (err) => apiErrorHandling(err)
);

export default axiosApi;
