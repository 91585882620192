import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

const initialState = {
  isLoading: false,
  data: {},
  success: false,
  error: false,
};

const webinarSlice = createSlice({
  name: "webinar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => {
        return {
          ...state,
          ...payload.webinar,
        };
      })
      // GET WEBINAR LIST
      .addCase(actions.getWebinarListData.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: false,
      }))
      .addCase(actions.getWebinarListData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          success: true,
          data: payload?.data,
        };
      })
      .addCase(actions.getWebinarListData.rejected, (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: true,
      }));
  },
});
export { getWebinarListData } from "./Actions";

export default webinarSlice.reducer;
