import { Control, Controller } from "react-hook-form";

import Input from "@/components/Atoms/InputsV2/TextInput";
import ErrorUI from "@/components/Atoms/InputsV2/ErrorUI";

import { classNameGenerator } from "@/utils";

import styles from "./CourseHours.module.scss";
import Typography from "@/foundation/Typography/V2";

interface NumberInputProps {
    id: string;
    name: string;
    control: Control<any>;
    label?: string;
    className?: string;
    onBlur?: (e: any) => void;
    heading?: string
}

export const isValidName = (value) =>
    value &&
    (value.match(/^[a-zA-Z\s]*$/) || value === "") &&
    value.length <= 140;

const CourseHours: React.FC<NumberInputProps> = ({
    id,
    name,
    control,
    heading,
    onBlur = () => { },
    ...rest
}) => {
    const cls = classNameGenerator(styles);

   
    const isValidInput = (e)=> {
        const newValue = e.target.value;
        if (newValue === '' || (newValue > 0 && newValue <= 999)) {
            return true
        }
        return false
    }
    return (
        <div className={cls("courseHours")}>
            <Typography
                variant="heading4"
                className={cls("totalHours")}
                dangerouslySetInnerHTML={{
                    __html: heading
                }}
            />
            <Controller
                name={name}
                control={control}
                shouldUnregister
                render={({
                    field: { value, onBlur: handleBlur, onChange, ref },
                    fieldState: { error },
                }) => (
                    <>
                        <div className={cls("fieldWrapper")}>
                            <Input
                                id={id}
                                ref={ref}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                    if(isValidInput(e)){
                                        onChange(e);
                                    }
                                  }}
                                {...rest}
                                type="tel"
                                min="1"
                                max="999"
                            />
                            <label>Hours</label>
                        </div>
                        <ErrorUI error={error?.message} />
                    </>
                )}
            />
        </div>
    );
};

export default CourseHours;
