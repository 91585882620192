import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  axios,
  generateUrlArguments,
  sentryLogs,
  timezone as tz,
} from "@/utils";
import { DEFAULT_URL_ARGUMENTS } from "@/constants";

const _timezone = tz || "Asia/Calcutta";

export const saveLead = createAsyncThunk<any, { data: any }>(
  "saveLead",
  ({ data }) => {
    const slugUrl = data?.package_slug;
    const apiData = { ...data };
    apiData.urls_arguments = generateUrlArguments(
      data.urls_arguments || DEFAULT_URL_ARGUMENTS
    );
    if (!apiData?.timezone) {
      apiData.timezone = _timezone;
    }
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}landingpage/${slugUrl}/`;
    if (data?.is_callback_request) url = `${url}?timezone=${_timezone}`;

    return axios.post(url, apiData);
  }
);

export const postPackageSave = createAsyncThunk<any, { data: any }>(
  "postPackageSave",
  ({ data }) => {
    const slugUrl = data?.package_slug;
    const apiData = { ...data };
    apiData.urls_arguments = generateUrlArguments(
      data.urls_arguments || DEFAULT_URL_ARGUMENTS
    );
    if (!apiData?.timezone) {
      apiData.timezone = _timezone;
    }
    const url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}landingpage/${slugUrl}/`;
    return axios.post(url, apiData);
  }
);

export const promoCodeCheckRequest = createAsyncThunk(
  "promoCodeCheckRequest",
  (apiData) => {
    const slugurl = apiData.slug;
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}landingpage/${slugurl}/apply_coupon/`;

    return axios.post(url, apiData);
  }
);

export const getPackagePricing = createAsyncThunk(
  "getPackagePricing",
  ({
    product_type,
    nutrition_goal_id,
    isForTrialPackage,
    pageType,
    ignoreTimezone,
  }) => {
    if (!product_type || !nutrition_goal_id) return;
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/package-pricings/`;
    url = `${url}?product_type=${product_type}&nutrition_goal_id=${nutrition_goal_id}`;
    if (isForTrialPackage) url = `${url}&is_for_trial=true`;
    if (pageType) url = `${url}&page_type=${pageType}`;
    if (!ignoreTimezone) url = `${url}&timezone=${_timezone}`;

    return axios.get(url);
  }
);

export const setPaymentFailedError = (name, error) =>
  sentryLogs(
    "error",
    `LANDINGPAGE - PAYMENT BACKEND CALL FAILURE - ${name}`,
    error
  );

export const postCampaignLead = createAsyncThunk<any, { data: any }, any>(
  "postCampaignLead",
  ({ data }) =>
    axios.post(
      `${process.env.NEXT_PUBLIC_LOCAL_API_URL}admin/campaign-lead/`,
      data
    )
);
