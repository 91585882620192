import { forwardRef, useMemo } from "react";

import SelectInput from "../SelectInput";

import { useAppSelector } from "@/hooks/useRedux";

import { classNameGenerator } from "@/utils";

import styles from "./CountryCodeInput.module.scss";

interface CountryCodeInputProps {
  instanceId?: string;
  name: string;
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

const CountryCodeInput = forwardRef(
  (
    {
      instanceId = "country-code-input",
      className,
      ...rest
    }: CountryCodeInputProps,
    ref: any
  ) => {
    const cls = classNameGenerator(styles);

    const { countryCodeData } = useAppSelector((state) => state.dashboard);

    const countryCodeList = useMemo(() => {
      return countryCodeData?.list?.map(({ code, value }) => ({
        label: code,
        value,
      }));
    }, [countryCodeData]);

    return (
      <SelectInput
        ref={ref}
        instanceId={instanceId}
        className={cls("countryCode")}
        classNamePrefix="countryCode"
        options={countryCodeList}
        isSearchable
        maxMenuHeight={100}
        {...rest}
      />
    );
  }
);

CountryCodeInput.displayName = "CountryCodeInput";

export default CountryCodeInput;
