import { createSlice } from "@reduxjs/toolkit";

import * as actions from "./Actions";

interface InitialState {
  isLoading: boolean;
  error?: string;
  success: boolean;
  data: any;
  postAmbassadorDetailsLoading: boolean;
  postAmbassadorDetailsError?: string;
  postAmbassadorDetailsSuccess: boolean;
}

const initialState: InitialState = {
  isLoading: false,
  error: "",
  success: false,
  data: {},
  postAmbassadorDetailsLoading: false,
  postAmbassadorDetailsError: "",
  postAmbassadorDetailsSuccess: false,
};

const ambassadorSlice = createSlice({
  name: "ambassador",
  initialState,
  reducers: {
    resetPostAmbassadorDetails(state, action) {
      return {
        ...state,
        postAmbassadorDetailsLoading: false,
        postAmbassadorDetailsError: "",
        postAmbassadorDetailsSuccess: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // GET AMBASSADOR DETAILS
      .addCase(actions.getAmbassadorDetails.pending, (state) => ({
        ...state,
        isLoading: true,
        error: "",
        success: false,
      }))
      .addCase(actions.getAmbassadorDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          error: "",
          success: true,
          data: payload?.data,
        };
      })
      .addCase(actions.getAmbassadorDetails.rejected, (state, { error }) => ({
        ...state,
        isLoading: false,
        error: error?.message,
        success: false,
      }))
      // POST AMBASSADOR DETAILS
      .addCase(actions.postAmbassadorDetails.pending, (state) => ({
        ...state,
        postAmbassadorDetailsLoading: true,
        postAmbassadorDetailsError: "",
        postAmbassadorDetailsSuccess: false,
      }))
      .addCase(actions.postAmbassadorDetails.fulfilled, (state) => {
        return {
          ...state,
          postAmbassadorDetailsLoading: false,
          postAmbassadorDetailsError: "",
          postAmbassadorDetailsSuccess: true,
        };
      })
      .addCase(actions.postAmbassadorDetails.rejected, (state, { error }) => ({
        ...state,
        postAmbassadorDetailsLoading: false,
        postAmbassadorDetailsError: error?.message,
        postAmbassadorDetailsSuccess: false,
      }));
  },
});

export { getAmbassadorDetails, postAmbassadorDetails } from "./Actions";

export const { resetPostAmbassadorDetails } = ambassadorSlice.actions;

export default ambassadorSlice.reducer;
