import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios } from "@/utils";

export const getAmbassadorDetails = createAsyncThunk<any, { slug: string }>(
  "getAmbassadorDetails",
  ({ slug }) =>
    axios.get(
      `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/website_data/${slug}`
    )
);

export interface PostAmbassadorDetailsI {
  lead_type: string;
  has_subscribed: boolean;
  name: string;
  primary_contact: string;
  email: string;
  linkedin: string;
  instagram: string;
  twitter: string;
  url_arguments: string;
}

export const postAmbassadorDetails = createAsyncThunk<
  any,
  { data: PostAmbassadorDetailsI }
>("postAmbassadorDetails", ({ data }) =>
  axios.post(`${process.env.NEXT_PUBLIC_LOCAL_API_URL}admin/campaign-lead/`, data)
);
