import React, { useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import theme from "../../../foundation/Theme";
import { getCustomScroll } from "../Scrollbar";
import SVGSprite from "../SVGSprite";
import { SelectWrapperV3 } from "./selectStyle/selectStyle";
function SelectInput(props) {
  const {
    defaultValue,
    variant = "v1",
    isDisabled = false,
    isLoading = false,
    isClearable = false,
    isSearchable = true,
    focusable = true,
    options = [],
    classNamePrefix = "shyft",
    caretIcon = variant === "v2" || variant === "v3"
      ? "icon-caret-down"
      : "icon-arrow-down",
    dropDownHeight = "300px",
    name = "",
    zIndex = "2",
    placeholder = "",
    fontSize = "16px",
    minHeight = variant === "v2" ? "36px" : variant === "v3" ? "46px" : "38px",
    containerPadding = "3px 8px",
    inputMargin = "2px",
    dropdownPos = "",
    borderRadius = "",
    singleValueColor = variant === "v2"
      ? theme.colorsV2.gray.shade_800
      : variant === "v3"
      ? theme.colorsV2.gray.shade_900
      : theme.colors.quote,
    onBlur = () => {},
    value = "",
    ...rest
  } = props || {};
  const CaretDownIcon = () => {
    return (
      <SVGSprite
        icon={caretIcon}
        fill={
          variant === "v2" || variant === "v3"
            ? isDisabled
              ? theme.colorsV2.gray.shade_500
              : theme.colorsV2.gray.shade_800
            : theme.colors.quote
        }
        height={variant === "v2" || variant === "v3" ? "16px" : "8px"}
        width={variant === "v2" || variant === "v3" ? "16px" : "8px"}
      />
    );
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  const [zIndexHook, setZindex] = useState(zIndex);
  return (
    <>
      {variant !== "v3" && (
        <SelectWrapper
          classNamePrefix={classNamePrefix}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isSearchable={isSearchable}
          name={name}
          value={value}
          placeholder={placeholder}
          fontSize={fontSize}
          minHeight={minHeight}
          components={{ DropdownIndicator }}
          focusable={focusable}
          dropDownHeight={dropDownHeight}
          options={options}
          zIndex={zIndexHook}
          containerPadding={containerPadding}
          inputMargin={inputMargin}
          dropdownPos={dropdownPos}
          borderRadius={borderRadius}
          singleValueColor={singleValueColor}
          variant={variant}
          onFocus={() => setZindex(zIndex + "1")}
          onBlur={(e) => {
            setZindex(zIndex);
            onBlur({ target: { name, value } });
          }}
          instanceId="select-dropdown"
          {...rest}
        />
      )}
      {variant === "v3" && (
        <SelectWrapperV3
          classNamePrefix={classNamePrefix}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isSearchable={isSearchable}
          name={name}
          value={value}
          placeholder={placeholder}
          fontSize={fontSize}
          minHeight={minHeight}
          components={{ DropdownIndicator }}
          focusable={focusable}
          dropDownHeight={dropDownHeight}
          options={options}
          zIndex={zIndexHook}
          containerPadding={containerPadding}
          inputMargin={inputMargin}
          dropdownPos={dropdownPos}
          borderRadius={borderRadius}
          singleValueColor={singleValueColor}
          variant={variant}
          onFocus={() => setZindex(zIndex + "1")}
          onBlur={(e) => {
            setZindex(zIndex);
            onBlur({ target: { name, value } });
          }}
          instanceId="select-dropdown"
          {...rest}
        />
      )}
    </>
  );
}

export default SelectInput;
const SelectWrapper = styled(Select)`
  z-index: ${({ zIndex }) => zIndex};
  .${({ classNamePrefix }) => classNamePrefix}__control {
    border-color: ${({ variant }) =>
      variant === "v2" ? theme.colorsV2.gray.shade_300 : theme.colors.quote};
    box-shadow: none;
    ${({ showBorderBottom }) => {
      return (
        showBorderBottom &&
        `
    border: none;
    border-bottom: 1px solid ${theme.colors.quote} ;
      `
      );
    }}

    z-index: 67;
    ${({ borderRadius }) => borderRadius && `border-radius:${borderRadius}`};
    min-height: ${({ minHeight }) => minHeight};
    &:hover {
      box-shadow: none;
      border-color: ${({ variant }) =>
        variant === "v2" ? theme.colorsV2.gray.shade_300 : theme.colors.quote};
    }
    ${({ focusable, classNamePrefix, variant, dropdownPos }) =>
      focusable &&
      `
      &.${classNamePrefix}__control--is-focused {
        border-color: ${
          variant === "v2" ? theme.colorsV2.shyft.purple : theme.colors.quote
        };
        ${
          variant === "v2" &&
          dropdownPos === "bottom" &&
          `
          &.${classNamePrefix}__control--menu-is-open {
            border-bottom-color: transparent;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
          `
        }
      }
      `}
    &.${({ classNamePrefix }) => classNamePrefix}__control--menu-is-open {
      .${({ classNamePrefix }) => classNamePrefix}__indicator {
        &.css-1gtu0rj-indicatorContainer {
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .${({ classNamePrefix }) => classNamePrefix}__input-container {
    margin: ${({ inputMargin }) => inputMargin};
  }
  .${({ classNamePrefix }) => classNamePrefix}__value-container {
    padding: ${({ containerPadding }) => containerPadding} !important;
  }
  .${({ classNamePrefix }) => classNamePrefix}__indicators {
    pointer-events: none;
  }
  .${({ classNamePrefix }) => classNamePrefix}__input,.${({
      classNamePrefix,
    }) => classNamePrefix}__single-value {
    width: 100%;
    font-size: ${({ fontSize }) => fontSize} !important;
    color: ${({ singleValueColor }) => singleValueColor} !important;
    font-family: var(
      --${({ variant }) => (variant === "v2" ? "man-700" : "font-500")}
    ) !important;
    input {
      font-family: inherit !important;
    }
  }
  .${({ classNamePrefix }) => classNamePrefix}__indicator-separator {
    display: none;
  }
  .${({ classNamePrefix }) => classNamePrefix}__indicator {
    padding: ${({ variant }) => (variant === "v2" ? "8px 16px" : "8px")};
    .icon {
      transition: all 0.2s ease-in-out;
    }
  }
  .${({ classNamePrefix }) => classNamePrefix}__menu {
    margin: 0;
    top: ${({ dropdownPos }) => (dropdownPos === "bottom" ? "100%" : "0")};
    transform: translateY(
      ${({ dropdownPos, variant }) =>
        dropdownPos === "top"
          ? "-100%"
          : dropdownPos === "bottom"
          ? variant === "v2"
            ? "calc(-1px)"
            : "0%"
          : "-50%"}
    );
    left: 0%;
    z-index: 69;
    padding-top: 0px;
    border: 1px solid
      ${({ variant }) =>
        variant === "v2" ? theme.colorsV2.shyft.purple : theme.colors.quote};
    ${({ variant, dropdownPos }) =>
      variant === "v2" &&
      dropdownPos === "bottom" &&
      `
      border-radius: 8px;
      border-top:0;
      border-top-right-radius:0;
      border-top-left-radius:0;
      `}
    overflow:hidden;
    box-shadow: 2px 4px 4px 0 rgba(${theme.colors.shadows.banner}, 0.1);
    .${({ classNamePrefix }) => classNamePrefix}__option {
      color: ${({ variant }) =>
        variant === "v2"
          ? theme.colorsV2.gray.shade_800
          : theme.colors.Usual_Icons};
      font-size: ${({ fontSize }) => fontSize};
      font-family: var(
        --${({ variant }) => (variant === "v2" ? "man-700" : "font-500")}
      ) !important;
      cursor: pointer;
      padding: ${({ variant }) => (variant === "v2" ? "4px" : "6px")} 16px;
    }
    .${({ classNamePrefix }) => classNamePrefix}__menu-list {
      max-height: ${({ dropDownHeight }) => dropDownHeight};
      ${getCustomScroll({})};
    }
    .${({ classNamePrefix }) => classNamePrefix}__option--is-selected.${({
        classNamePrefix,
      }) => classNamePrefix}__option--is-focused {
      background-color: ${({ variant }) =>
        variant === "v2" ? theme.colorsV2.shyft.purple : theme.colors.quote};
    }
    .${({ classNamePrefix }) => classNamePrefix}__option--is-selected {
      background-color: ${({ variant }) =>
        variant === "v2" ? theme.colorsV2.shyft.purple : theme.colors.quote};
      color: ${theme.colors.white};
    }
    .${({ classNamePrefix }) => classNamePrefix}__option--is-disabled {
      background-color: transparent;
      opacity: 0.5;
      cursor: not-allowed;
    }
    .${({ classNamePrefix }) => classNamePrefix}__option--is-focused {
      background-color: ${theme.colors.gray_bg};
      color: ${theme.colors.Usual_Icons};
    }
    .${({ classNamePrefix }) => classNamePrefix}__menu-notice--no-options {
      color: ${theme.colors.Usual_Icons};
      font-size: ${({ fontSize }) => fontSize};
    }
  }
  .${({ classNamePrefix }) => classNamePrefix}__placeholder {
    color: ${({ variant }) =>
      variant === "v2" ? theme.colorsV2.gray.shade_500 : theme.colors.quote};
    font-size: ${({ fontSize }) => fontSize};
    font-family: var(
      --${({ variant }) => (variant === "v2" ? "man-500" : "font-500")}
    ) !important;
  }
`;
