import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

const initialState = {
  isLoading: false,
  data: {},
  success: false,
  error: false,
};

const nutritionGoalSlice = createSlice({
  name: "nutritionGoalList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.nutritionGoalList,
      }))
      // GET NUTRITION GOAL LIST
      .addCase(actions.getNutritionGoalList.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: false,
      }))
      .addCase(actions.getNutritionGoalList.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          success: true,
          data: payload?.data?.data,
        };
      })
      .addCase(actions.getNutritionGoalList.rejected, (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload || true,
      }));
  },
});
export { getNutritionGoalList } from "./Actions";

export default nutritionGoalSlice.reducer;
