import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

const initialState = {
  isLoading: true,
  error: "",
  success: false,
  data: null,
};

const UnlimitedYogaFlowSlice = createSlice({
  name: "unlimitedYogaFlow",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.unlimitedYogaFlow,
      }))
      // GET HEALTH LANDING PAGE
      .addCase(
        actions.unlimitedYogaEmailerDataSubmitAction.pending,
        (state) => ({
          ...state,
          isLoading: true,
          success: false,
          error: "",
        })
      )
      .addCase(
        actions.unlimitedYogaEmailerDataSubmitAction.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            success: true,
            data: payload?.data,
          };
        }
      )
      .addCase(
        actions.unlimitedYogaEmailerDataSubmitAction.rejected,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            error: payload?.error || true,
          };
        }
      );
  },
});

export { unlimitedYogaEmailerDataSubmitAction } from "./Actions";

export default UnlimitedYogaFlowSlice.reducer;
