import { createAsyncThunk } from "@reduxjs/toolkit";

import { appendArguments, axios } from "@/utils";

export const getShyftPassScheduleData = createAsyncThunk(
  "getShyftPassScheduleData",
  ({ startDate, encodedId, onboardId, zoneId }) => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}admin/shyft-pass-schedule/`;
    if (startDate) url = appendArguments(url, `start_date=${startDate}`);
    if (encodedId) url = appendArguments(url, `encoded_id=${encodedId}`);
    if (onboardId) url = appendArguments(url, `onboard_id=${onboardId}`);
    if (zoneId) url = appendArguments(url, `zone_id=${zoneId}`);
    return axios.get(url);
  }
);

export const getShyftPassScheduleDataById = createAsyncThunk(
  "getShyftPassScheduleDataById",
  ({ id }) => {
    const url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}admin/shyft-pass-schedule/${id}/`;

    return axios.get(url);
  }
);

export const getShyftPassScheduleByClassSlug = createAsyncThunk(
  "getShyftPassScheduleByClassSlug",
  ({ slug }) => {
    const url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/admin/shyft-pass-slug-mapping/${slug}/`;

    return axios.get(url);
  }
);

export const bookClass = createAsyncThunk("bookClass", ({ data }) => {
  let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}admin/shyft-pass-booking/`;

  return axios.post(url, data);
});

export const cancelClass = createAsyncThunk(
  "cancelClass",
  ({ data, bookingId }) => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}admin/shyft-pass-booking/${bookingId}/`;

    return axios.patch(url, data);
  }
);

export const addToWaitList = createAsyncThunk("addToWaitList", ({ data }) => {
  let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/admin/shyft-pass-wait-list/`;

  return axios.post(url, data);
});
