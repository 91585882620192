import { DEFAULT_API_ERROR_MESSAGE } from "@/utils/Constants";
import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

const initialState: any = {
  isLoading: true,
  success: false,
  data: null,
  error: null,
};

const partnershipPageSlice = createSlice({
  name: "partnershipPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.partnershipPage,
      }))
      .addCase(actions.getPartnershipPage.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(actions.getPartnershipPage.fulfilled, (state, { payload }) => {
        if (!payload?.status) {
          return {
            ...state,
            isLoading: false,
            success: false,
            error: payload?.message || DEFAULT_API_ERROR_MESSAGE,
          };
        }

        return {
          ...state,
          isLoading: false,
          success: true,
          data: payload?.data,
          error: "",
        };
      })
      .addCase(actions.getPartnershipPage.rejected, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          success: false,
          error: payload?.message || DEFAULT_API_ERROR_MESSAGE,
        };
      });
  },
});

export { getPartnershipPage } from "./Actions";

export const {} = partnershipPageSlice.actions;

export default partnershipPageSlice.reducer;
