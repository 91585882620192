import { FormProvider } from "react-hook-form";

import FormInputs from "@/components/Organisms/HookFormInputs/V1";
import Typography from "@/foundation/Typography/V2";

import useCallbackForm from "./useCallbackForm";

import { classNameGenerator, clsx } from "@/utils";

import { useAppSelector } from "@/hooks";

import styles from "./CallbackForm.module.scss";

interface CallbackFormProps {
  title: string;
  subtitle: string;
  setIsFormSubmitted?: (isFormSubmitted: boolean) => void;
}

const CallbackForm: React.FC<CallbackFormProps> = ({
  title,
  subtitle,
  setIsFormSubmitted,
}) => {
  const cls = classNameGenerator(styles);

  const { data } = useAppSelector((state) => state.bootCamp);

  const packData = data?.packData || {
    packageSlug: "nutrition-bootcamp-default",
  };

  const { methods, isLoading, onBlur, requestCallbackHandler } =
    useCallbackForm({ packData, setIsFormSubmitted });

  const components = [
    {
      name: "name",
      props: {
        name: "name",
        className: cls("name"),
      },
    },
    {
      name: "phone",
      props: {
        name: "mobile",
        className: cls("phone"),
        onBlur,
      },
    },
    {
      name: "toggle",
      props: {
        name: "joinCommunity",
        label:
          "Add me to Shyft's Whatsapp community for health resources + inspiration.",
        className: clsx(cls("joinCommunity"), cls("dark")),
      },
    },
    {
      name: "submit",
      props: {
        type: "submit",
        children: "Submit details",
        className: cls("submitButton"),
        isLoading,
      },
    },
  ];

  return (
    <div className={cls("callbackForm")}>
      <div className={cls("header")}>
        <Typography
          variant="heading2"
          className={cls("title")}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Typography
          variant="paragraph1"
          className={cls("description")}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      </div>
      <FormProvider {...methods}>
        <form className={cls("formWrapper")} onSubmit={requestCallbackHandler}>
          <FormInputs components={components} />
        </form>
      </FormProvider>
    </div>
  );
};

export default CallbackForm;
