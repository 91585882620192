import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios, timezone } from "@/utils";

export const careerDetailsApi = createAsyncThunk<string, () => any>(
  "careerDetailsApi",
  ({ ignoreTimezone }: any) => {
    let careerPageApiURl = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}admin/career/`;
    if (!ignoreTimezone) careerPageApiURl += "?timezone=" + timezone;
    return axios.get(careerPageApiURl);
  }
);

export const getJobDetailsById = createAsyncThunk<string, () => any>(
  "getJobDetailsById",
  ({ jobId, ignoreTimezone }: any) => {
    const url = `admin/career/${jobId}/job/${
      !ignoreTimezone ? `?timezone=${timezone}` : ""
    }`;
    return axios.get(url);
  }
);
