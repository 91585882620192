import React from "react";

import { FlexDiv } from "@/components/Atoms/Box";
import Image from "@/components/Atoms/Image";

import Typography from "@/foundation/Typography/V2";
import theme from "@/foundation/Theme";

export const countryFlagList = {
  "+91": "india.png",
  "+971": "uae.png",
  "+974": "qatar.png",
  "+1": "usa.png",
  "+65": "singapore.png",
  "+44": "uk.png",
};

export const formatOptionLabelV2 = ({ code }) => (
  <FlexDiv alignItems={"center"}>
    <FlexDiv height={"16px"} width={"24px"} mr={"4px"}>
      {countryFlagList?.[code] && (
        <Image
          maxWidth={"24px"}
          maxHeight={"16px"}
          imageName={`flags/${countryFlagList?.[code]}`}
          alt="flag"
        />
      )}
    </FlexDiv>
    <Typography variant="label3" color={theme.colorsV2.gray.shade_700}>
      {code}
    </Typography>
  </FlexDiv>
);
