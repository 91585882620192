import { createSlice } from "@reduxjs/toolkit";

interface YogaFaqsType {
  searchValue: string;
  showDropdown: boolean;
  questionsList: { question: string; url: string }[];
  dropdownList: { question: string; url: string; styledQuestion: string }[];
}

const initialState: YogaFaqsType = {
  searchValue: "",
  showDropdown: false,
  questionsList: [],
  dropdownList: [],
};

const yogaFaqsPageSlice = createSlice({
  name: "yogaFaqsPage",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      return {
        ...state,
        searchValue: action.payload.text,
      };
    },
    setSelectedSearchValue(state, action) {
      return {
        ...state,
        searchValue: action.payload.text,
      };
    },
    setQuestionList(state, action) {
      return {
        ...state,
        questionsList: action.payload?.questionsList,
      };
    },
    setDropdownList(state, action) {
      return {
        ...state,
        dropdownList: action.payload?.dropdownList,
      };
    },
    closeDropdown(state) {
      return {
        ...state,
        showDropdown: false,
      };
    },
    openDropdown(state) {
      return {
        ...state,
        showDropdown: true,
      };
    },
  },
});

export const {
  setSearchValue,
  setQuestionList,
  setDropdownList,
  closeDropdown,
  openDropdown,
  setSelectedSearchValue,
} = yogaFaqsPageSlice.actions;
export default yogaFaqsPageSlice.reducer;
