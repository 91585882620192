import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

const initialState = {
  isLoading: false,
  error: "",
  success: false,
  data: {},
};

const HealthProgramPageSlice = createSlice({
  name: "HealthProgramPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.healthProgram,
      }))
      // GET HEALTH LANDING PAGE
      .addCase(actions.getHealthProgramPage.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(actions.getHealthProgramPage.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          success: true,
          data: payload?.data,
        };
      })
      .addCase(actions.getHealthProgramPage.rejected, (state, { payload }) => ({
        ...state,
        isLoading: false,
        // error: payload?.error,
      }));
  },
});

export { getHealthProgramPage } from "./Actions";

export default HealthProgramPageSlice.reducer;
