const organizationData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Shyft",
  alternateName: "Betheshyft",
  url: "https://www.betheshyft.com/",
  logo: "https://blog.betheshyft.com/wp-content/themes/yogax/mindhouse-theme/assets/images/mh-logo-dark.svg",
  contactPoint: {
    "@type": "ContactPoint",
    telephone: `+91-${process.env.NEXT_PUBLIC_CONTACT_NUMBER}`,
    contactType: "customer service",
  },
  sameAs: [
    "https://www.facebook.com/shyftwellness/",
    "https://twitter.com/shyfthealth",
    "https://www.instagram.com/shyfthealth",
    "https://www.linkedin.com/company/shyftwellness/mycompany/",
  ],
};

const localBusinessData = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  name: "Shyft",
  image:
    "https://blog.betheshyft.com/wp-content/themes/yogax/mindhouse-theme/assets/images/mh-logo-dark.svg",
  url: "https://www.betheshyft.com/",
  telephone: `+91-${process.env.NEXT_PUBLIC_CONTACT_NUMBER}`,
  address: {
    "@type": "PostalAddress",
    streetAddress:
      "WeWork, Platina Tower, MG Road, A Block, DLF Phase 1, Sector 28",
    addressLocality: "Gurgaon",
    postalCode: "122002",
    addressRegion: "Haryana",
    addressCountry: "IN",
  },
  geo: {
    "@type": "GeoCoordinates",
    latitude: 28.480630019087,
    longitude: 77.0925854439192,
  },
  openingHoursSpecification: {
    "@type": "OpeningHoursSpecification",
    dayOfWeek: ["Monday", "Tuesday", "Thursday", "Friday", "Wednesday"],
    opens: "10:00",
    closes: "19:00",
  },
  sameAs: [
    "https://www.facebook.com/shyftwellness/",
    "https://twitter.com/shyfthealth",
    "https://www.instagram.com/shyfthealth",
    "https://www.linkedin.com/company/shyftwellness/mycompany/",
  ],
};

export { organizationData, localBusinessData };
