import React from "react";

import { Link, RLink } from "./style";

interface LinksProps {
  children?: React.ReactNode;
  variant: string;
  href?: string;
  to?: string;
}

const renderLinks = (
  { children, variant, href, to, ...rest }: LinksProps,
  search: string
) => {
  return (
    <>
      {variant === "link1" && (
        <Link href={href} {...rest}>
          {children}
        </Link>
      )}
      {variant === "link2" && (
        <Link href={href} {...rest}>
          {children}
        </Link>
      )}
      {variant === "routelink1" && ( //TODO: Add Query for Search
        <RLink href={to || ""} {...rest}>
          {children}
        </RLink>
      )}
      {variant === "routelink2" && (
        <RLink href={to || ""} {...rest}>
          {children}
        </RLink>
      )}
    </>
  );
};

export default renderLinks;
