import React, { useEffect, useRef, useState } from "react";

import CountryDropdown from "@/components/Atoms/Inputs/CountryDropdown";
import ErrorUI from "@/components/Atoms/ErrorUI";
import Input from "@/components/Atoms/Inputs/Input";
import {
  countryFlagList,
  formatOptionLabelV2,
} from "@/components/Molecules/FormatOptionLabel/V2";

import { useAppSelector } from "@/hooks";

import {
  ArrowDown,
  CountryPlaceholder,
  DropDown,
  InputWrap,
  NumberPlaceholder,
  PhoneIcon,
  PhoneFlag,
  ImageHolder,
  CountryCode,
} from "../style";

const PhoneInput = ({
  countryCode,
  setCountryCode,
  mobileNo,
  setMobileNo,
  onSubmit,
  error,
}) => {
  const { countryCodeData: countryCodes } = useAppSelector(
    (state) => state.dashboard
  );

  const wrapperRef = useRef(null);

  const [showCountryDropDown, setShowCountryDropDown] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef?.current &&
        !wrapperRef?.current?.contains(event?.target)
      ) {
        setShowCountryDropDown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChange = (event) => {
    const value = event?.target?.value;
    if (value.length > 11) return;
    let regEX = /^[0-9]*$/;
    if (value === "" || regEX.test(value)) {
      setMobileNo(value);
    }
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      onSubmit(event);
    }
  };

  return (
    <InputWrap mb="48px">
      <CountryPlaceholder showCountryDropDown={showCountryDropDown}>
        <NumberPlaceholder
          onClick={() => setShowCountryDropDown(!showCountryDropDown)}
          position="relative"
        >
          {countryFlagList?.[countryCode] ? (
            <PhoneFlag height={"16px"} width={"24px"}>
              <ImageHolder
                maxWidth={"24px"}
                maxHeight={"16px"}
                imageName={`flags/${countryFlagList?.[countryCode]}`}
                alt="flag"
              />
            </PhoneFlag>
          ) : (
            <PhoneIcon icon="icon-phone-2" height={"16px"} width={"16px"} />
          )}
          <CountryCode variant={"label2"} width={"100%"} textAlign={"center"}>
            {countryCode}
          </CountryCode>
          <ArrowDown icon={"icon-caret-down"} height={"8px"} width={"8px"} />
        </NumberPlaceholder>
        <DropDown showCountryDropDown={showCountryDropDown} ref={wrapperRef}>
          {!countryCodes?.isLoading && (
            <CountryDropdown
              menuIsOpen={showCountryDropDown}
              options={countryCodes?.list}
              formatOptionLabel={formatOptionLabelV2}
              wrapperClassName="shyftList"
              dropDownHeight="180px"
              placeholder="Search"
              defaultValue={countryCode}
              onChange={(countryCode) => {
                setCountryCode(countryCode.code);
                setShowCountryDropDown(false);
              }}
              closeMenuOnSelect
              variant={"v2"}
            />
          )}
        </DropDown>
      </CountryPlaceholder>
      <Input
        inputPadding="3px 16px 3px 100px"
        placeholder="10 Digit Mobile Number"
        value={mobileNo}
        pattern="[0-9]*"
        inputMode="decimal"
        onChange={handleChange}
        onKeyPress={onKeyPress}
        autoFocus
        autoComplete="off"
        required
        variant={"v2"}
      />
      {error && <ErrorUI error={error} hasBottomBorder />}
    </InputWrap>
  );
};

export default PhoneInput;
