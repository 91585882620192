import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios } from "@/utils";

import timezoneMapping, {
  ilaTimezoneMapping,
  oneOnOneYogaTimezoneMapping,
} from "./timezoneMapping";

import getData from "./BootcampData";

export const generateBootcampListing = (
  allBootcampData: any,
  bootcampType: string,
  pageSlug: string,
  countryCode: string
) => {
  const currentBootcamp = allBootcampData?.[bootcampType]?.[pageSlug];
  const bootcampListingType = currentBootcamp?.bootcampListingType;
  const bootcampListingHREFs =
    allBootcampData?.listingData?.[bootcampListingType];

  const bootcampListing: any = [];
  Object.values(allBootcampData).forEach((PageTypeData: any) => {
    Object.values(PageTypeData).forEach((innerPageData: any) => {
      const { isActive, hideFromBootCampListing, href } = innerPageData || {};
      if (
        isActive &&
        !hideFromBootCampListing &&
        currentBootcamp?.href !== href &&
        bootcampListingHREFs?.includes(href)
      ) {
        let bootcampData = { ...innerPageData };
        if (bootcampData.packData) {
          bootcampData.packData = innerPageData?.packData?.[countryCode];
        }
        if (bootcampData.preConsultation) {
          bootcampData.preConsultation = innerPageData?.preConsultation?.[countryCode];
        }
        bootcampListing.push(bootcampData);
      }
    });
  });
  return bootcampListing;
};

export const getOneOnOneYogaSlots = createAsyncThunk<
  any,
  { timezone?: string }
>("getOneOnOneYogaSlots", async ({ timezone = "" }) => {
  let slug = "1-on-1-yoga-est-slots";
  if (
    ["US/Pacific", "America/Vancouver", "America/Los_Angeles"]?.includes(
      timezone
    )
  ) {
    slug = "1-on-1-yoga-pst-slots";
  }
  let oneOnOneYogaSlotsURL = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/module-header/${slug}/`;
  return await axios.get(oneOnOneYogaSlotsURL);
});

export const getBootCampPageData = createAsyncThunk<
  any,
  { bootcampType: string; pageSlug: string; timezone?: string }
>("getBootCampPageData", async ({ bootcampType, pageSlug, timezone }) => {
  let countryCode = timezoneMapping?.[timezone || "Asia/Calcutta"] || "USD";
  if (pageSlug === "indians-living-abroad") {
    countryCode = ilaTimezoneMapping?.[timezone || ""] || "USD";
  } else if (pageSlug === "1-on-1-yoga") {
    countryCode = oneOnOneYogaTimezoneMapping?.[timezone || ""] || "USD";
  } else if (pageSlug === "1-on-1-yoga-trial") {
    countryCode = "USD";
  } else if (pageSlug === "online-yoga-class") {
    countryCode = "USD";
  }

  // return getData({ bootcampType, pageSlug, countryCode });

  let bootcampListURL = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/module-header/bootcamp-list/`;
  let pageDataURL = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/module-header/${bootcampType}-${pageSlug}/`;

  const bootcampListRes = await axios.get(bootcampListURL);
  const bootcampList = bootcampListRes?.data?.meta;

  const bootcampTypeData = bootcampList?.[bootcampType] || {};
  const bootcampData = bootcampTypeData?.[pageSlug] || {};

  if (!bootcampData?.isActive) return null;

  const pageDataRes = await axios.get(pageDataURL);
  const pageData = pageDataRes?.data?.meta;

  if (pageData) {
    let data = {
      ...bootcampData,
      ...pageData,
      bootcampList: generateBootcampListing(
        bootcampList,
        bootcampType,
        pageSlug,
        countryCode
      ),
    };

    if (data.packData) {
      data.packData = data?.packData?.[countryCode];
      if(!timezone){
        const rawData = {...data.packData}
        rawData.currency=""
        rawData.currencySymbol=""
        rawData.price=null
        rawData.originalPrice=null
        data.packData = rawData
      }
    }
    if (data.trialData) {
      data.trialData = data?.trialData?.[countryCode];
      if(!timezone){
        const rawData = {...data.trialData}
        rawData.currency=""
        rawData.currencySymbol=""
        rawData.price=null
        rawData.originalPrice=null
        data.trialData = rawData
      }
    }
    if (data.trialsData) {
      data.trialsData = data?.trialsData?.map(
        (trialData: any) => trialData?.[countryCode]
      );
      if(!timezone){
        const rawData = [...data.trialData]
        data.trialsData = rawData.map((trialData: any) => {
          const data = {...trialData}
          data.currency=""
          data.currencySymbol=""
          data.price=null
          data.originalPrice=null
          return data
        })
      }
    }
    if (data.pricingData) {
      data.pricingData = data?.pricingData?.[countryCode];
      if(!timezone){
        const rawData = {...data.pricingData}
        rawData.currency=""
        rawData.currencySymbol=""
        rawData.price=null
        rawData.originalPrice=null
        data.pricingData = rawData
      }
    }

    if (data.allPackages) {
      data.allPackages = {
        ...pageData?.allPackages,
        packages: data?.allPackages?.packages?.[countryCode],
      };
      if(!timezone){
        let rawData = [...data?.allPackages?.packages]
        rawData = rawData.map((packages: any) => {
          const data = {...packages}
          data.currency=""
          data.currencySymbol=""
          data.price=null
          data.originalPrice=null
          return data
        })
        data.allPackages = {
          ...pageData?.allPackages,
          packages: rawData,
        };
      }
    }
    if (data.preConsultation) {
      data.preConsultation = data?.preConsultation?.[countryCode];
      if(!timezone){
        const rawData = {...data.preConsultation}
        rawData.currency=""
        rawData.currencySymbol=""
        rawData.price=null
        rawData.originalPrice=null
        data.preConsultation = rawData
      }
    }

    return data;
  } else {
    return null;
  }
});
