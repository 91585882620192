import { Control, Controller } from "react-hook-form";

import Input from "@/components/Atoms/InputsV2/PromoCodeInput";
import ErrorUI from "@/components/Atoms/InputsV2/ErrorUI";

import { classNameGenerator } from "@/utils";

import styles from "./PromoCodeInput.module.scss";

interface PromoCodeInputProps {
  name: string;
  control: Control<any>;
  placeholder?: string;
  className?: string;
  isLoading?: boolean;
  ctaText: string;
  isPromoCodeApplied?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onCTAClick?: (e: any) => void;
  defaultValue?: string;
}

export const isValidName = (value) =>
  value &&
  (value.match(/^[a-zA-Z\s]*$/) || value === "") &&
  value.length <= 140;

const PromoCodeInput: React.FC<PromoCodeInputProps> = ({
  name,
  control,
  placeholder = "Enter promo code",
  onChange = () => {},
  onBlur = () => {},
  defaultValue,
  ...rest
}) => {
  const cls = classNameGenerator(styles);

  return (
    <div className={cls("promoCodeInput")}>
      <Controller
        name={name}
        control={control}
        shouldUnregister
        render={({
          field: { value, onBlur: handleBlur, onChange: handleChange, ref },
          fieldState: { error },
        }) => (
          <>
            <Input
              ref={ref}
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={(e) => {
                onChange(e);
                handleChange(e);
              }}
              onBlur={(e) => {
                onBlur(e);
                handleBlur();
              }}
              defaultValue={defaultValue}
              {...rest}
            />
            <ErrorUI error={error?.message} />
          </>
        )}
      />
    </div>
  );
};

export default PromoCodeInput;
