import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios, timezone as tz } from "@/utils";

const _timezone = tz || "Asia/Calcutta";

export const getMembershipBenefitData = createAsyncThunk<string, any>(
  "getMembershipBenefitData",
  (slug) => {
    const url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/health-program/landingpage/${slug}/?timezone=${_timezone}`;
    return axios.get(url);
  }
);
