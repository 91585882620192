import React, { useEffect } from "react";
import { useRouter } from "next/router";
import NextImage from "next/image";

import Button from "@/components/Atoms/Button";
import SVGSprite from "@/components/Atoms/SVGSprite";
import Modal from "@/components/Molecules/Modal";
import Header from "../Header";
import NavMenu from "./NavMenu";

import { generateQueryArguments } from "@/utils";
import Typography from "@/foundation/Typography/V2";

import styles from "./MobileDrawer.module.scss";

const MobileDrawer = ({ onClose = () => {}, ...rest }) => {
  const router = useRouter();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const appLinks = [
    {
      link: "https://play.google.com/store/apps/details?id=com.mindhouse",
      icon: "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/website/footer/Google-dark.svg",
      iconDark:
        "https://d1mxd7n691o8sz.cloudfront.net/static/nutrition/nutritiongoal/2023-06/Google_Light-0f53e36e83aa43beaa054bf3c26a69ce.svg",
      text: "play-store",
      external: true,
    },
    {
      link: "https://apps.apple.com/in/app/mindhouse-modern-meditation/id1484471377",
      icon: "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/website/footer/Apple-dark.svg",
      iconDark:
        "https://d1mxd7n691o8sz.cloudfront.net/static/nutrition/nutritiongoal/2023-06/Apple_Light-6ef14b8a4a4d4f70bfe2ee8b9d016ecd.svg",
      text: "apple-store",
      external: true,
    },
  ];

  return (
    <Modal
      height="100%"
      width="100%"
      mobilewidth="100%"
      overlayClosable
      onClose={onClose}
      bodyPadding="0px"
    >
      <div className={styles.mobileDrawer}>
        <Header
          {...rest}
          headerBackgroundColor="#f3f3ef"
          darkHeader={false}
          showRibbon={false}
          hideShadow
        />
        <div className={styles.content}>
          <NavMenu />
          <Button
            className={styles.shyftPassButton}
            onClick={() =>
              router.push(`/shyftpass/${generateQueryArguments()}`)
            }
            id="shyftpass-drawer-button"
          >
            <p>Weekend workouts with Shyft Pass</p>
            <div
              style={{
                height: "32px",
                width: "32px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <SVGSprite
                icon="icon-header-arrow-right"
                height="16px"
                width="16px"
                fill="#1570EF"
              />
            </div>
          </Button>
          <Button
            className={styles.joinCommunityButton}
            onClick={() =>
              router.push(`/joincommunity/${generateQueryArguments()}`)
            }
            id="join-community-drawer-button"
          >
            <p>Join Shyft Health Community</p>
            <div
              style={{
                height: "32px",
                width: "32px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <SVGSprite
                icon="icon-header-arrow-right"
                height="16px"
                width="16px"
                fill="#487B07"
              />
            </div>
          </Button>
          <div className={styles.appDownload}>
            <Typography className={styles.title} variant="heading3">
              Try Shyft for free
            </Typography>
            <Typography className={styles.description} variant="paragraph3">
              Download Shyft Health App and start your wellness journey
            </Typography>
            <div className={styles.icons}>
              {appLinks?.map((link, index) => (
                <a
                  key={index}
                  href={link.link?.endsWith("/") ? link.link : `${link.link}/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <NextImage
                    className="img-fluid lazyload loaded"
                    src={link.iconDark}
                    height={40}
                    width={130}
                    alt={link.text}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MobileDrawer;
