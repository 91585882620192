export const APP_LOCATION = process.env.NEXT_PUBLIC_LOCATION;
export const LOCAL_API_URL = process.env.NEXT_PUBLIC_LOCAL_API_URL;
export const MAIN_WEBSITE_URL = process.env.NEXT_PUBLIC_MAIN_WEBSITE_URL;
export const RAZOR_KEY_ID = process.env.NEXT_PUBLIC_RAZOR_KEY_ID;
export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
export const FACEBOOK_CLIENT_ID = process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID;
export const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
export const FIREBASE_API_ID = process.env.NEXT_PUBLIC_FIREBASE_API_ID;
export const SENTRY_WEB_DSN = process.env.NEXT_PUBLIC_SENTRY_WEB_DSN;
export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID;
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
export const NODE_ENV = process.env.NODE_ENV;
