import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios, getCookieStorage, timezone } from "@/utils";

const PROFILE_API_URL =
  process.env.NEXT_PUBLIC_LOCAL_API_URL + "zoom_desktop/profile_new/";
const PACKS_API_URL =
  process.env.NEXT_PUBLIC_LOCAL_API_URL +
  "zoom_desktop/profile/single_pack_new/";

export const getProfileDetails = createAsyncThunk("getProfileDetails", () => {
  return axios.get(PROFILE_API_URL, {
    headers: {
      Authorization: "Bearer " + getCookieStorage("__authAccessToken"),
      Customertimezone: timezone,
    },
  });
});

export const updateProfileDetails = createAsyncThunk(
  "updateProfileDetails",
  () => {
    return axios.get(PROFILE_API_URL, {
      headers: {
        Authorization: "Bearer " + getCookieStorage("__authAccessToken"),
        Customertimezone: timezone,
      },
    });
  }
);

export const getPacksDetails = createAsyncThunk(
  "getPacksDetails",
  (apiData: any) => {
    return axios.post(PACKS_API_URL, apiData, {
      headers: {
        Authorization: "Bearer " + getCookieStorage("__authAccessToken"),
        Customertimezone: timezone,
      },
    });
  }
);
