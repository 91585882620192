import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios } from "@/utils";

const NUTRITION_GOAL_LIST =
  process.env.NEXT_PUBLIC_LOCAL_API_URL + "api/v1/nutrition/nutrition-goals/";

export const getNutritionGoalList = createAsyncThunk(
  "getNutritionGoalList",
  ({ isHealthGoal, isYogaGoal, isNutritionGoal }: any) => {
    let api = NUTRITION_GOAL_LIST + "?";
    if (isHealthGoal) {
      api = api + "is_health_goal=true";
    }
    if (isYogaGoal) {
      api = api + "is_yoga_goal=true";
    }
    if (isNutritionGoal) {
      api = api + "is_nutrition_goal=true";
    }

    return axios.get(api);
  }
);
