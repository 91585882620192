import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios, timezone as tz } from "@/utils";

const _timezone = tz || "Asia/Calcutta";

export const getHealthSurveyPageData = createAsyncThunk<
  any,
  { surveyPageSlug: string }
>("getHealthSurveyPageData", ({ surveyPageSlug }) => {
  let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/integration/tally/${surveyPageSlug}/?timezone=${_timezone}`;
  return axios.get(url);
});
