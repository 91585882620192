export const ilaTimezoneMapping = {
  "Asia/Dubai": "AED",
};

export const oneOnOneYogaTimezoneMapping = {
  "Asia/Dubai": "AED",
};

const timezoneMapping = {
  "Asia/Dubai": "AED",
  "Asia/Calcutta": "INR",
};

export default timezoneMapping;
