/* eslint-disable react/display-name */
import React from "react";

import renderHeading from "./Heading/index";
import renderParagraph from "./Paragraph/index";
import renderDisplay from "./Display/index";
import renderLabel from "./Label/index";
import renderSpan from "./Span/index";
import renderLinks from "./Link/index";

import theme from "../../foundation/Theme";

interface TypographyProps {
  color?: string;
  variant: string;
  [key: string]: any;
}

const Typography = React.forwardRef(
  ({ color, ...rest }: TypographyProps, ref) => {
    let search = "";
    if (typeof window !== "undefined") {
      search = window.location.search;
    }

    const typographyProps = {
      ...rest,
      color: color || theme.colors.Text_1,
      ref: ref,
    };

    return (
      <>
        {renderHeading(typographyProps)}
        {renderParagraph(typographyProps)}
        {renderDisplay(typographyProps)}
        {renderLabel(typographyProps)}
        {renderSpan(typographyProps)}
        {renderLinks(typographyProps, search)}
      </>
    );
  }
);

export default Typography;
