import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

const initialState: any = {
  isLoading: false,
  error: "",
  success: false,
  data: {},
  isRecipeThreadLoading: false,
  attributes: [],
  categories: [],
};

const recipeSlice = createSlice({
  name: "recipe",
  initialState,
  reducers: {
    resetRecipeThread(state) {
      return {
        ...state,
        data: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.recipe,
      }))
      // GET RECIPE DETAIL LANDING PAGE
      .addCase(actions.getRecipeThreadByID.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(actions.getRecipeThreadByID.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          success: true,
          data: { ...state?.data, ...payload?.data },
        };
      })
      .addCase(actions.getRecipeThreadByID.rejected, (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload || true,
      }))

      // GET RECIPE LIST BY CATEGORY
      .addCase(actions.getRecipeByCategorySlug.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(
        actions.getRecipeByCategorySlug.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            success: true,
            data: {
              ...state?.data,
              categoryRecipeData: {
                ...payload?.data?.results?.header_data,
                recipeList: payload?.data?.results?.data || [],
              },
            },
          };
        }
      )
      .addCase(
        actions.getRecipeByCategorySlug.rejected,
        (state, { payload }) => ({
          ...state,
          isLoading: false,
          error: payload || true,
        })
      )

      // GET RECIPE LIST BY COLLECTION
      .addCase(actions.getRecipeByCollectionSlug.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(
        actions.getRecipeByCollectionSlug.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            success: true,
            data: {
              ...state?.data,
              collectionRecipeData: {
                ...payload?.data?.results?.header_data,
                recipeList: payload?.data?.results?.data || [],
              },
            },
          };
        }
      )
      .addCase(
        actions.getRecipeByCollectionSlug.rejected,
        (state, { payload }) => ({
          ...state,
          isLoading: false,
          error: payload || true,
        })
      )

      // GET RECIPE LIST BY ATTRIBUTES
      .addCase(actions.getRecipeByAttributeSlug.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(
        actions.getRecipeByAttributeSlug.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            success: true,
            data: {
              ...state?.data,
              attributeRecipeData: {
                ...payload?.data?.results?.header_data,
                recipeList: payload?.data?.results?.data || [],
              },
            },
          };
        }
      )
      .addCase(
        actions.getRecipeByAttributeSlug.rejected,
        (state, { payload }) => ({
          ...state,
          isLoading: false,
          error: payload || true,
        })
      )

      // GET RECIPE LIST
      .addCase(actions.getRecipeThread.pending, (state) => ({
        ...state,
        isRecipeThreadLoading: true,
        success: false,
        error: "",
      }))
      .addCase(actions.getRecipeThread.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isRecipeThreadLoading: false,
          success: true,
          data: {
            ...state?.data,
            ...payload?.data?.results?.header_data,
            recipeList: payload?.data?.results?.data || [],
          },
        };
      })
      .addCase(actions.getRecipeThread.rejected, (state, { payload }) => ({
        ...state,
        isRecipeThreadLoading: false,
        error: payload || true,
      }))

      // GET ATTRIBUTES AND COLLECTIONS
      .addCase(actions.getAttributeAndCategories.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(
        actions.getAttributeAndCategories.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            success: true,
            attributes:
              payload?.data?.attiribute
                ?.filter(
                  ({ is_active, is_deleted, is_disabled }) =>
                    is_active && !is_deleted && !is_disabled
                )
                ?.map(({ id, title }) => ({ label: title, value: id })) || [],
            categories:
              payload?.data?.categories
                ?.filter(
                  ({ is_active, is_deleted, is_disabled }) =>
                    is_active && !is_deleted && !is_disabled
                )
                ?.map(({ id, title }) => ({ label: title, value: id })) || [],
          };
        }
      )
      .addCase(
        actions.getAttributeAndCategories.rejected,
        (state, { payload }) => ({
          ...state,
          isLoading: false,
          error: payload || true,
        })
      );
  },
});
export {
  getRecipeThreadByID,
  getRecipeByAttributeSlug,
  getRecipeByCategorySlug,
  getRecipeByCollectionSlug,
  getRecipeThread,
  getAttributeAndCategories,
} from "./Actions";

export const { resetRecipeThread } = recipeSlice.actions;

export default recipeSlice.reducer;
