import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import {
  axios,
  generateAccessKey,
  getCookieStorage,
  setCookieStorage,
  timezone as tz,
} from "@/utils";

const _timezone = tz || "Asia/Calcutta";

export const requestOTP = createAsyncThunk<
  any,
  {
    data: { mobile: string };
  }
>("requestOTP", async ({ data }) => {
  let uuid = getCookieStorage("uuid");
  if (!uuid) {
    uuid = uuidv4();
    setCookieStorage("uuid", uuid);
  }
  const accessKey = await generateAccessKey(data);

  const url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v2/customer/otp-send/`;

  return axios.post(url, data, {
    headers: {
      "Access-Key": accessKey,
      Uniqueid: uuid,
      Customertimezone: _timezone,
    },
  });
});

export const login = createAsyncThunk<
  any,
  { data: { otp: string; mobile: string } }
>("login", async ({ data }) => {
  const uuid = getCookieStorage("uuid");
  const accessKey = await generateAccessKey({ mobile: data?.mobile });

  let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v2/customer/otp-validate/`;

  return axios.post(url, data, {
    headers: {
      "Access-Key": accessKey,
      Uniqueid: uuid,
      Customertimezone: _timezone,
    },
  });
});
