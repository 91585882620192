import { Control, Controller } from "react-hook-form";

import Input from "@/components/Atoms/InputsV2/SelectHealthConditions";
import ErrorUI from "@/components/Atoms/InputsV2/ErrorUI";

import { classNameGenerator } from "@/utils";

import styles from "./SelectHealthConditions.module.scss";

interface SelectHealthConditionsProps {
  name: string;
  control: Control<any>;
  placeholder?: string;
  className?: string;
  onBlur?: (e: any) => void;
  options: any;
}

const SelectHealthConditions: React.FC<SelectHealthConditionsProps> = ({
  name,
  control,
  placeholder = "Select a health condition",
  onBlur = () => {},
  ...rest
}) => {
  const cls = classNameGenerator(styles);

  return (
    <div className={cls("selectHealthConditions")}>
      <Controller
        name={name}
        control={control}
        shouldUnregister
        render={({
          field: { value, onBlur: handleBlur, onChange, ref },
          fieldState: { error },
        }) => (
          <>
            <Input
              ref={ref}
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={(e) => {
                handleBlur();
                onBlur(e);
              }}
              {...rest}
            />
            <ErrorUI error={error?.message} />
          </>
        )}
      />
    </div>
  );
};

export default SelectHealthConditions;
