// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAwO_9o0pW9k11Zo7U6V8sLQTSDHcwdUwY",
  appId: "1:81873957774:web:2301da2152abc5dbe42adf",
  messagingSenderId: "319640346012",
  projectId: "mindhouseweb",
  authDomain: "mindhouseweb.firebaseapp.com",
  storageBucket: "mindhouseweb.appspot.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
