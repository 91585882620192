import { createSlice } from "@reduxjs/toolkit";

import * as actions from "./Actions";

import { getCookieStorage, removeCookieStorage } from "@/utils";

let accessToken = getCookieStorage("__authAccessToken");
let refreshToken = getCookieStorage("__authRefreshToken");

let data = {};
if (accessToken && refreshToken) {
  data = {
    access: accessToken,
    refresh: refreshToken,
  };
}

interface InitialState {
  isLoading: boolean;
  error: string;
  success: boolean;
  data: any;
  isLoggingIn: boolean | string;
  isOTPLoading: boolean;
  otpError: string;
  otpSuccess: boolean;
  otpResponse: any;
}

const initialState: InitialState = {
  isLoading: false,
  error: "",
  success: false,
  data: data,
  isLoggingIn: accessToken ? true : false,
  isOTPLoading: false,
  otpError: "",
  otpSuccess: false,
  otpResponse: null,
  isProfileLoading: false,
  profileError: "",
  profileSuccess: false,
  profileDetails: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state, action) {
      removeCookieStorage("__authAccessToken");
      removeCookieStorage("__authRefreshToken");
      removeCookieStorage("__primaryContact");
      removeCookieStorage("__profilePicture");
      return {
        ...state,
        isLoading: false,
        error: "",
        success: false,
        data: {},
        isLoggingIn: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // REQUEST OTP
      .addCase(actions.requestOTP.pending, (state) => ({
        ...state,
        isOTPLoading: true,
        otpError: "",
        otpSuccess: false,
        otpResponse: null,
      }))
      .addCase(actions.requestOTP.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isOTPLoading: false,
          otpError: "",
          otpSuccess: true,
          otpResponse: payload?.data,
        };
      })
      .addCase(actions.requestOTP.rejected, (state, { error }) => ({
        ...state,
        isOTPLoading: false,
        otpError: error?.message || "",
        otpSuccess: false,
        otpResponse: null,
      }))
      // VERIFY OTP
      .addCase(actions.login.pending, (state) => ({
        ...state,
        isLoading: true,
        error: "",
        success: false,
        data: {},
        isLoggingIn: false,
      }))
      .addCase(actions.login.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          error: "",
          success: true,
          data: {
            ...(payload?.data?.tokens || {}),
            primaryContact: payload?.data?.primary_contact || "",
          },
          isLoggingIn: true,
        };
      })
      .addCase(actions.login.rejected, (state, { error }) => ({
        ...state,
        isLoading: false,
        error: error?.message || "",
        success: false,
        data: {},
        isLoggingIn: false,
      }));
  },
});

export { requestOTP, login } from "./Actions";

export const { logout } = authSlice.actions;

export default authSlice.reducer;
