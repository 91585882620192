import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios, timezone as tz } from "@/utils";

const _timezone = tz || "Asia/Calcutta";

const WEBINAR_LIST_API_URL =
  process.env.NEXT_PUBLIC_LOCAL_API_URL + "landingpage/" || "";

export const getWebinarListData = createAsyncThunk(
  "getWebinarListData",
  ({ slug, timezone }) => {
    let webinarListApiUrl = WEBINAR_LIST_API_URL + slug;
    webinarListApiUrl += `/?timezone=${timezone || _timezone}`;
    return axios.get(webinarListApiUrl);
  }
);
