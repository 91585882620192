import React from "react";
import styled from "styled-components";
import { typography } from "styled-system";

import { ALink, StyledRouterLink } from "@/components/Atoms/Box";

export const Link = styled(({ lineHeight, textTransform, ...rest }) => (
  <ALink {...rest} />
))`
  ${typography}
  text-decoration: none;
  &:hover {
    color: ${({ color }) => color};
    text-decoration: none;
  }
`;

export const RLink = styled(({ lineHeight, textTransform, to, ...rest }) => (
  <StyledRouterLink {...rest} />
))`
  ${typography}
  text-decoration: none;
  &:hover {
    color: ${({ color }) => color};
    text-decoration: none;
  }
`;
