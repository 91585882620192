import { useState, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../useRedux";
import {
  promoCodeCheckRequest,
  removePromoCode,
} from "@/redux/LandingPageReducer";

import {
  inputValidator,
  isValidEmail,
  isValidMobile,
  isValidName,
  matchCountryRegex,
  timezone,
} from "../../utils";

const useRegisterForm = (fields) => {
  const dispatch = useAppDispatch();
  const fieldKeys = Object.keys(fields) || [];
  const initialInputs = {};
  const initialErrors = {};

  fieldKeys?.forEach((key) => {
    initialInputs[key] = fields[key];
    initialErrors[`${key}Error`] = "";
  });

  const initialState = { ...initialInputs, ...initialErrors };

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState(initialState);
  const [promoCodeButtonText, setPromoCodeButtonText] = useState(
    initialState?.promoCodeButtonText || "APPLY"
  );

  const { countryCode, mobile, email, promoCode, goal } = inputs;

  const { isPromoCodeLoading, promoCodeSuccess, promoCodeData } =
    useAppSelector((state) => state.landingPage);

  const applyCode = useCallback(
    (apiData) => dispatch(promoCodeCheckRequest(apiData)),
    [dispatch]
  );
  const removeCode = useCallback(() => dispatch(removePromoCode()), [dispatch]);

  const applyPromoCodeHelper = useCallback(
    (isValidInputs, apiData) => {
      if (isValidInputs) {
        applyCode(apiData).then((payload) => {
          setIsLoading(false);
          if (payload?.error) {
            setInputs((prevState) => ({
              ...prevState,
              promoCodeError: payload.error,
            }));
          } else {
            setPromoCodeButtonText("REMOVE");
          }
        });
      }
    },
    [applyCode, setIsLoading]
  );

  const applyPromoCode = useCallback(() => {
    setInputs((prevState) => ({ ...prevState, promoCodeError: "" }));
    if (!promoCode) {
      setInputs((prevState) => ({
        ...prevState,
        promoCodeError: "Please enter promo code",
      }));
      return;
    }
    const apiData = {
      coupon_code: promoCode?.toUpperCase(),
      mobile: mobile,
      phone_prefix: countryCode,
      is_hero_package: true,
      timezone,
      slug: goal,
    };
    applyPromoCodeHelper(validateInput(), apiData);
  }, [applyPromoCodeHelper, promoCode]);

  const removeAppliedPromoCode = useCallback(() => {
    setInputs((prevState) => ({
      ...prevState,
      promoCode: "",
      promoCodeError: "",
    }));
    setPromoCodeButtonText("APPLY");
    removeCode();
  }, [removeCode]);

  useEffect(() => {
    return () => removeAppliedPromoCode();
  }, [removeAppliedPromoCode]);

  const handleBlur = (event, onBlur) => {
    const field = event?.target?.name;
    const value = event?.target?.value;

    const isValidContactDetail =
      (field === "mobile" &&
        isValidMobile(value) &&
        matchCountryRegex(countryCode, value)) ||
      (field === "email" && isValidEmail(value)) ||
      (isValidMobile(mobile) && matchCountryRegex(countryCode, mobile)) ||
      isValidEmail(email);

    if (isValidContactDetail) onBlur(event);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      if (value === "" || isValidName(value))
        setInputs((inputs) => ({ ...inputs, [name]: value }));
    } else if (name === "mobile") {
      if (value === "" || isValidMobile(value))
        setInputs((inputs) => ({ ...inputs, [name]: value }));
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
    setInputs((inputs) => ({ ...inputs, [name + "Error"]: "" }));
  };

  const handleSelectChange = (name, value) => {
    if (fieldKeys.includes(name)) {
      setInputs((inputs) => ({
        ...inputs,
        [name]: value,
        [name + "Error"]: "",
      }));
    }
    if (["goal", "service"].includes(name) && promoCodeSuccess) {
      const previousPromoCode = promoCode;
      removeAppliedPromoCode();
      setInputs((prevInput) => {
        return {
          ...prevInput,
          promoCodeError: `Promo code has removed, your promo code was ${previousPromoCode}. Please apply promo code again.`,
        };
      });
    }
  };

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: checked }));
    setInputs((inputs) => ({ ...inputs, [name + "Error"]: "" }));
  };

  const resetErrors = () => {
    setInputs({
      ...inputs,
      ...initialErrors,
    });
  };

  const validateInput = () => {
    resetErrors();

    const { errors, isError } = inputValidator({ ...inputs });

    if (isError) {
      setInputs((inputs) => ({
        ...inputs,
        ...errors,
      }));
      setIsLoading(false);
      return false;
    } else {
      setIsLoading(true);
      return true;
    }
  };

  const onSubmitCallback = (state, onSubmit) => {
    if (validateInput() && state) {
      setIsLoading(false);

      onSubmit();
    }
  };

  const onShowCallbackModal = (onHide) => {
    setIsFormSubmitted(true);
    setShowSuccessModal(true);
  };

  const onHideCallbackModal = (onHide) => {
    onHide();
    setIsLoading(false);

    setInputs(initialState);
    setShowSuccessModal(false);
  };

  const isAllInputsFilled = () => {
    const isInputFilled = fieldKeys?.filter((key) => !!inputs[key]);
    return isInputFilled?.length === fieldKeys?.length;
  };
  const resetFormInputs = () => {
    setIsLoading(false);
    setInputs(initialState);
  };

  return {
    inputs,
    isFormSubmitted,
    showSuccessModal,
    isLoading,
    handleBlur,
    handleChange,
    handleSelectChange,
    onSubmitCallback,
    onShowCallbackModal,
    onHideCallbackModal,
    isAllInputsFilled,
    handleCheckBoxChange,
    validateInput,
    resetFormInputs,
    applyPromoCode,
    promoCodeButtonText,
    isPromoCodeChecking: isPromoCodeLoading,
    promoCodeData,
    isPromoCodeApplied: promoCodeSuccess,
    removeAppliedPromoCode,
  };
};

export default useRegisterForm;
