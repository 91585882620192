import React from "react";

import { Span } from "./style";

interface SpanProps {
  children?: React.ReactNode;
  variant: string;
}

const renderSpan = ({ children, variant, ...rest }: SpanProps) => {
  const spanProps = { ...rest };

  return (
    <>{["span"].includes(variant) && <Span {...spanProps}>{children}</Span>}</>
  );
};

export default renderSpan;
