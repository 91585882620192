import Select from "react-select";
import styled from "styled-components";
import { getCustomScroll } from "../../Scrollbar";

export const SelectWrapperV3 = styled(Select)`
  z-index: ${({ zIndex }) => zIndex};
  .${({ classNamePrefix }) => classNamePrefix}__control {
    border-color: ${({ theme }) => theme.colorsV2.gray.shade_300};
    box-shadow: none;
    z-index: 67;
    ${({ borderRadius }) => borderRadius && `border-radius:${borderRadius}`};
    min-height: ${({ minHeight }) => minHeight};
    &:hover {
      box-shadow: none;
      border-color: ${({ theme }) => theme.colorsV2.gray.shade_300};
    }
    ${({ focusable, classNamePrefix, dropdownPos, theme }) =>
      focusable &&
      `
      &.${classNamePrefix}__control--is-focused {
        border-color: ${theme.colorsV2.shyft.purple};
        ${
          dropdownPos === "bottom" &&
          `
          &.${classNamePrefix}__control--menu-is-open {
            border-bottom-color: transparent;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
          `
        }
      }
      `}
    &.${({ classNamePrefix }) => classNamePrefix}__control--menu-is-open {
      .${({ classNamePrefix }) => classNamePrefix}__indicator {
        &.css-1gtu0rj-indicatorContainer {
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .${({ classNamePrefix }) => classNamePrefix}__input-container {
    margin: ${({ inputMargin }) => inputMargin};
  }
  .${({ classNamePrefix }) => classNamePrefix}__value-container {
    padding: ${({ containerPadding }) => containerPadding} !important;
  }
  .${({ classNamePrefix }) => classNamePrefix}__indicators {
    pointer-events: none;
  }
  .${({ classNamePrefix }) => classNamePrefix}__input,.${({
      classNamePrefix,
    }) => classNamePrefix}__single-value {
    width: 100%;
    font-size: ${({ fontSize }) => fontSize} !important;
    color: ${({ singleValueColor, isDisabled, theme }) =>
      isDisabled ? theme.colorsV2.gray.shade_500 : singleValueColor} !important;
    font-family: var(--man-500) !important;
    input {
      font-family: inherit !important;
    }
  }
  .${({ classNamePrefix }) => classNamePrefix}__indicator-separator {
    display: none;
  }
  .${({ classNamePrefix }) => classNamePrefix}__indicator {
    padding: 8px 16px;
    .icon {
      transition: all 0.2s ease-in-out;
    }
  }
  .${({ classNamePrefix }) => classNamePrefix}__menu {
    margin: 0;
    top: ${({ dropdownPos }) => (dropdownPos === "bottom" ? "100%" : "0")};
    transform: translateY(
      ${({ dropdownPos, variant }) =>
        dropdownPos === "top"
          ? "-100%"
          : dropdownPos === "bottom"
          ? variant === "v2"
            ? "calc(-1px)"
            : "0%"
          : "-50%"}
    );
    left: 0%;
    z-index: 69;
    padding-top: 0px;
    border: 1px solid ${({ theme }) => theme.colorsV2.shyft.purple};
    ${({ dropdownPos }) =>
      dropdownPos === "bottom" &&
      `
      border-radius: 8px;
      border-top:0;
      border-top-right-radius:0;
      border-top-left-radius:0;
      `}
    overflow:hidden;
    box-shadow: 2px 4px 4px 0
      rgba(${({ theme }) => theme.colors.shadows.banner}, 0.1);
    .${({ classNamePrefix }) => classNamePrefix}__option {
      color: ${({ theme }) => theme.colorsV2.gray.shade_800};
      font-size: ${({ fontSize }) => fontSize};
      font-family: var(--man-500) !important;
      cursor: pointer;
      padding: ${({ variant }) => (variant === "v2" ? "4px" : "6px")} 16px;
    }
    .${({ classNamePrefix }) => classNamePrefix}__menu-list {
      max-height: ${({ dropDownHeight }) => dropDownHeight};
      ${getCustomScroll({})};
    }
    .${({ classNamePrefix }) => classNamePrefix}__option--is-selected.${({
        classNamePrefix,
      }) => classNamePrefix}__option--is-focused {
      background-color: ${({ variant, theme }) =>
        variant === "v2" ? theme.colorsV2.shyft.purple : theme.colors.quote};
    }
    .${({ classNamePrefix }) => classNamePrefix}__option--is-selected {
      background-color: ${({ theme }) => theme.colorsV2.shyft.purple};
      color: ${({ theme }) => theme.colors.white};
    }
    .${({ classNamePrefix }) => classNamePrefix}__option--is-disabled {
      background-color: transparent;
      opacity: 0.5;
      cursor: not-allowed;
    }
    .${({ classNamePrefix }) => classNamePrefix}__option--is-focused {
      background-color: ${({ theme }) => theme.colors.gray_bg};
      color: ${({ theme }) => theme.colors.Usual_Icons};
    }
    .${({ classNamePrefix }) => classNamePrefix}__menu-notice--no-options {
      color: ${({ theme }) => theme.colors.Usual_Icons};
      font-size: ${({ fontSize }) => fontSize};
    }
  }
  .${({ classNamePrefix }) => classNamePrefix}__placeholder {
    color: ${({ theme }) => theme.colorsV2.gray.shade_500};
    font-size: ${({ fontSize }) => fontSize};
    font-family: var(--man-500) !important;
  }
`;
