const data = {
  status: true,
  data: {
    logo: "https://app.mindhouse.com//static/uploads/group_1xxd0NJ.png",
    hero: {
      hero_logo: "",
      hero_title: "Nutrition, Exercise & Community",
      hero_subtitle: "Easy. Impactful. Proven",
      hero_description: "for a healthier, happier you",
      hero_cta_title: "See our programs",
      hero_cta_title_secondary: "Let's Talk",
      hero_background: "",
      hero_background_mob: "",
      package_slug: null,
    },
    footer: {
      title:
        "Lifestyle management and tracking for specific health conditions like chronic ailments, pregnancy care, chronic pains and more.",
      subtitle: "Download Shyft Health App and start your wellness journey",
      footer_cta_title: "Try Shyft for free",
    },
    floating_banner: {
      title: "",
      subtitle: "",
      cta_title: "",
      cta_title_secondary: null,
      description: "",
    },
    meta_title: null,
    meta_desc: null,
    og_meta: null,
    services: {
      title: "Services",
      sub_title: "",
      description: "",
      image: "",
      image_mob: "",
      url: null,
      logo: "",
      section_name: "SERVICES",
      sequence_no: 0,
      is_heading: true,
      tag: "",
      title_color: "",
      background_color: "",
      components: [
        {
          title: "Nutrition & Exercise",
          sub_title:
            "Make impactful lifestyle changes with nutrition plans, personalized to your dietary preferences! Get moving with targeted functional training exercise & yoga plans that help with weight management and general well-being.",
          description: "FOR HOMEPAGE",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/yoga.png",
          image_mob: "",
          url: "https://www.betheshyft.com/yoga/",
          logo: "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/nutrition.svg",
          section_name: "SERVICES",
          sequence_no: 1,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
        {
          title: "Health Programs",
          sub_title:
            "Combine the power of exercise with the right nutrition to achieve your goals faster. A health coach helps track progress, recommends program modifications and provides support 24*7.",
          description: "",

          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/hDny2p.png",
          image_mob: "",
          url: "https://www.betheshyft.com/health/",
          logo: "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/hand.svg",
          section_name: "SERVICES",
          sequence_no: 2,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
        {
          title: "Community",
          sub_title:
            "Join a group of like minded people that motivates, inspires and helps you achieve your health goals, all while having fun!",
          description: "",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-01/shutterstock_1303046203_1_1-ad9152a7ab4c442e83fb0235b4fb1c48.png",
          image_mob: "",
          url: null,
          logo: "https://d1mxd7n691o8sz.cloudfront.net/static/nutrition/nutritiongoal/2024-01/Groupaaaaaa-206ec9fe1e1e483082444619486e9662.svg",
          section_name: "SERVICES",
          sequence_no: 3,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
      ],
    },
    medical_conditions: {
      title: "Health Goals & Conditions",
      sub_title: "",
      description: "",
      image: "",
      image_mob: "",
      url: null,
      logo: "",
      section_name: "MEDICAL_CONDITIONS",
      sequence_no: 0,
      is_heading: true,
      tag: "",
      title_color: "",
      background_color: "",
      components: [
        {
          title: "Staying Fit",
          sub_title:
            "Mobilise the various joints in the body through a carefully curated sequence of postures that help stretch the spine, relieve stiffness, improve alignment, and de-stress the body.",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-01/Frame_7453-7cd5e32a25b448629aed30fde7fbca29.jpg",
          image_mob: "",
          url: "/yoga-for-wellness/",
          logo: "",
          section_name: "MEDICAL_CONDITIONS",
          sequence_no: 4,
          is_heading: false,
          tag: ["Yoga", "Nutrition", "Breathwork"],
          title_color: "",
          background_color: "",
        },
        {
          title: "Weight Management",
          sub_title:
            "Embark on a transformative journey towards a healthier weight with our range of programs as you optimize metabolism, shed excess weight, boost energy levels and embrace a healthier lifestyle.",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-01/Fitness_woman_preparing_a_detox_juice-566a3246f44146bf91528907d2d2eb.jpg",
          image_mob: "",
          url: "/plans/nutrition/weight-loss/",
          logo: "",
          section_name: "MEDICAL_CONDITIONS",
          sequence_no: 4,
          is_heading: false,
          tag: ["Yoga", "Nutrition", "Breathwork"],
          title_color: "",
          background_color: "",
        },
        {
          title: "Diabetes",
          sub_title:
            "Take control of your health with our specialized approach to managing diabetes. Our programs are supported by expert guidance to help maintain blood sugar levels, manage pre-diabetic symptoms & maintain weight better.",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-01/Frame_7453q-00f801c8683b4cf89168ec4de4dafaf5.jpg",
          image_mob: "",
          url: "/health-for-diabetes/",
          logo: "",
          section_name: "MEDICAL_CONDITIONS",
          sequence_no: 4,
          is_heading: false,
          tag: ["Yoga", "Nutrition", "Breathwork"],
          title_color: "",
          background_color: "",
        },
        {
          title: "Thyroid",
          sub_title:
            "Manage the physiological and psychological symptoms of hypothyroidism. The programs help with better weight management, reduced fatigue, constipation, and hair loss.",
          description: "Learn More",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/Thyroid.png",
          image_mob: "",
          url: "/health-for-thyroid/",
          logo: "",
          section_name: "MEDICAL_CONDITIONS",
          sequence_no: 6,
          is_heading: false,
          tag: ["Yoga", "Nutrition", "Breathwork"],
          title_color: "",
          background_color: "",
        },
        {
          title: "Hypertension",
          sub_title:
            "Lower and manage high blood pressure along with your doctor-prescribed medication with Yoga and Nutrition that help reduce symptoms like headaches, difficulty breathing, chest pain, irregular heartbeats, and palpitation.",
          description: "Learn More",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/High_BP.png",
          image_mob: "",
          url: "/health-for-hypertension/",
          logo: "",
          section_name: "MEDICAL_CONDITIONS",
          sequence_no: 5,
          is_heading: false,
          tag: ["Yoga", "Nutrition", "Breathwork"],
          title_color: "",
          background_color: "",
        },
        {
          title: "PCOS",
          sub_title:
            "We focus on PCOS symptom reversal by regulating blood flow, improving menstrual cycles, enhancing hormonal balance, and lowering Androgen levels with steady and mindful lifestyle changes.",
          description: "Learn More",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/PCOS.png",
          image_mob: "",
          url: "/health-for-pcos/",
          logo: "",
          section_name: "MEDICAL_CONDITIONS",
          sequence_no: 1,
          is_heading: false,
          tag: ["Yoga", "Nutrition", "Breathwork"],
          title_color: "",
          background_color: "",
        },
        {
          title: "Prenatal",
          sub_title:
            "We provide prenatal care for a smoother pregnancy to increase strength and flexibility, decrease lower back pain, ease nausea and increase the chance of natural childbirth with mindful lifestyle changes.",
          description: "Learn More",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/Prenatal.png",
          image_mob: "",
          url: "/health-for-prenatal/",
          logo: "",
          section_name: "MEDICAL_CONDITIONS",
          sequence_no: 2,
          is_heading: false,
          tag: ["Yoga", "Birthing Classes", "Breathwork"],
          title_color: "",
          background_color: "",
        },
        {
          title: "Postnatal",
          sub_title:
            "Post-pregnancy care helps manage weight, combat exhaustion, backache, postpartum depression, and avoid co-morbidities like high blood pressure and cardiovascular diseases.",
          description: "Learn More",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/Postnatal.png",
          image_mob: "",
          url: "/health-for-postnatal/",
          logo: "",
          section_name: "MEDICAL_CONDITIONS",
          sequence_no: 3,
          is_heading: false,
          tag: ["Yoga", "Nutrition", "Breathwork"],
          title_color: "",
          background_color: "",
        },
      ],
    },
    features_and_benefits: {
      title: "How it works",
      sub_title: "",
      description: "",
      image: "",
      image_mob: "",
      url: null,
      logo: "",
      section_name: "FEATURES_AND_BENEFITS",
      sequence_no: 0,
      is_heading: true,
      tag: "",
      title_color: "",
      background_color: "",
      components: [
        {
          title: "Personalised diet plan",
          sub_title:
            "with cheat meals & detox diets,and frequent consultations with your personal dietician",
          description: "",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-01/Diet_plan_1-549836aceb234bfb89268385741c991b.png",
          image_mob: "",
          url: null,
          logo: "",
          section_name: "FEATURES_AND_BENEFITS",
          sequence_no: 1,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
        {
          title: "Join our Community",
          sub_title:
            "Find your tribe that motivates, inspires and helps you achieve your health goals",
          description: "",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-01/Chat_Mockup_1-def37acf064448cda7a2f1fcc42873ef.png",
          image_mob: "",
          url: null,
          logo: "",
          section_name: "FEATURES_AND_BENEFITS",
          sequence_no: 2,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
        {
          title: "Dedicated health coach",
          sub_title:
            "with 24/7 availability to monitor progress, build accountability for your program and suggest any lifestyle changes",
          description: "",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/health-coach-mock.png",
          image_mob: "",
          url: null,
          logo: "",
          section_name: "FEATURES_AND_BENEFITS",
          sequence_no: 3,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
        {
          title: "Live interactive sessions",
          sub_title:
            "online yoga or exercise classes with best in class instructors",
          description: "",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/live-interactive.png",
          image_mob: "",
          url: null,
          logo: "",
          section_name: "FEATURES_AND_BENEFITS",
          sequence_no: 4,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
        {
          title: "Track your progress",
          sub_title:
            "and health vital via our app to see the progress on a daily basis",
          description: "",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/track-progress.png",
          image_mob: "",
          url: null,
          logo: "",
          section_name: "FEATURES_AND_BENEFITS",
          sequence_no: 5,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
      ],
    },
    highlights: {
      title: "HomePage Highlights",
      sub_title: "",
      description: "",
      image: "",
      image_mob: "",
      url: null,
      logo: "",
      section_name: "HIGHLIGHTS",
      sequence_no: 0,
      is_heading: true,
      tag: "",
      title_color: "",
      background_color: "",
      components: [
        {
          title: "30000+",
          sub_title: "Happy Customers Served",
          description: "",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/icons8-sparkling-96_1.png",
          image_mob: "",
          url: null,
          logo: "",
          section_name: "HIGHLIGHTS",
          sequence_no: 1,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
        {
          title: "900+",
          sub_title: "Doctors Recommend Us",
          description: "",
          image: "",
          image_mob: "",
          url: null,
          logo: "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/icons8-sparkling-96_1.png",
          section_name: "HIGHLIGHTS",
          sequence_no: 2,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
        {
          title: "90%",
          sub_title: "Customers see a positive outcome",
          description: "",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/icons8-sparkling-96_1.png",
          image_mob: "",
          url: null,
          logo: "",
          section_name: "HIGHLIGHTS",
          sequence_no: 3,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
      ],
    },
    testimonials: {
      title: "Customer Stories",
      sub_title:
        "More than 30,000 customers trust Shyft to manage their chronic and health conditions",
      description: "What our members are saying",
      image: "",
      image_mob: "",
      url: null,
      logo: "",
      section_name: "TESTIMONIALS",
      sequence_no: 0,
      is_heading: true,
      tag: "",
      title_color: "",
      background_color: "",
      components: [
        {
          title: "Drishti",
          sub_title:
            "In these 6 months of yoga at Shyft, my symptoms like moodswings and anxiety have reduced exponentially. The small batch size ensured personal attention from the trainers and their availability even after class made it easier to resolve all queries.",
          description: "",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/Drishti.jfif",
          image_mob: "",
          url: null,
          logo: "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/Drishti.jfif",
          section_name: "TESTIMONIALS",
          sequence_no: 0,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
        {
          title: "Khyati",
          sub_title:
            "The instructors are available to help at all times. My PCOS symptoms from the past 8 years have now started to improve with motivation and support. I would suggest women to get their Shyft subscriptions today!",
          description: "",
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/Khyati_1.png",
          image_mob: "",
          url: null,
          logo: "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/Khyati_1.png",
          section_name: "TESTIMONIALS",
          sequence_no: 2,
          is_heading: false,
          tag: "",
          title_color: "",
          background_color: "",
        },
      ],
    },
    forms: {
      title: "Ready to make the Shyft?",
      name: "home page webinar form",
      sub_title:
        "Fill the form and our team will get back to you within 24 hrs.",
      bottom_title: "Call Us",
      bottom_sub_title: "Write to us",
      description: "Say farewell to unwell",
      button_text: "Submit",
      first_name_text: "Your Name",
      last_name_text: null,
      contact_number_text: "Your Phone",
      contact_number_text_secondary: null,
      contact_email_text: null,
      form_alignment: "CUSTOM",
      submission_title: "Form submitted</br>Thank you for your interest.",
      submission_subtitle:
        "Our team will get in touch within 24hrs. We look forward to welcoming you to our family &#128522;",
      submission_extra_text: "",
      before_submission_image:
        "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/before_submission.svg",
      image:
        "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/ok-submit.svg",
      show_app_download_links: true,
      request_callback: true,
      meta: null,
      default_percent: 0,
      adset_name: null,
      page_type: "YOGA_PARTNERSHIP",
      services: {
        label: "Health Programs",
        value: "health_program",
        slugs: [
          {
            label: "PCOS",
            value: "pcos",
            slug: "health-for-pcos-india-49",
            ranking: 0,
            related_nutrition_goals: ["PCOS", "PCOS & Infertility"],
            related_nutrition_goals_ids: [3, 15],
            is_customer_goal: false,
          },
          {
            label: "Prenatal Care",
            value: "prenatal",
            slug: "health-for-prenatal-india-49",
            ranking: 0,
            related_nutrition_goals: ["Pre Natal"],
            related_nutrition_goals_ids: [9],
            is_customer_goal: false,
          },
          {
            label: "Postnatal Care",
            value: "postnatal",
            slug: "health-for-postnatal-india-49",
            ranking: 0,
            related_nutrition_goals: ["Post Natal"],
            related_nutrition_goals_ids: [6],
            is_customer_goal: false,
          },
          {
            label: "Wellness",
            value: "wellness",
            slug: "health-for-wellness-india-49",
            ranking: 0,
            related_nutrition_goals: ["General"],
            related_nutrition_goals_ids: [1],
            is_customer_goal: false,
          },
          {
            label: "Thyroid Management",
            value: "thyroid",
            slug: "health-for-thyroid-india-49",
            ranking: 0,
            related_nutrition_goals: ["Thyroid Disorders"],
            related_nutrition_goals_ids: [4],
            is_customer_goal: false,
          },
          {
            label: "Hypertension Management",
            value: "hypertension",
            slug: "health-for-hypertension-india-49",
            ranking: 0,
            related_nutrition_goals: ["High Blood Pressure", "Hypertension"],
            related_nutrition_goals_ids: [7, 17],
            is_customer_goal: false,
          },
          {
            label: "Diabetes Management",
            value: "diabetes",
            slug: "health-for-diabetes-india-49",
            ranking: 0,
            related_nutrition_goals: ["Diabetes"],
            related_nutrition_goals_ids: [5],
            is_customer_goal: false,
          },
        ],
        currency_code: "INR",
        amount: "199",
      },
      number: "+91-7048969168",
      email: "hello@betheshyft.com",
      default_goal: "",
      show_packages: [],
    },
  },
  message: null,
};

export default data;
