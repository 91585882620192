import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios } from "@/utils";

export const getCommunityThreadByID = createAsyncThunk(
  "getCommunityThreadByID",
  ({ id }) => {
    let url = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/community/public-thread/${id}/`;

    return axios.get(url);
  }
);
