import { useEffect } from "react";
import { useRouter } from "next/router";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";

import * as dashboardActions from "@/redux/DashboardReducer";

interface RibbonDataProps {
  hideRibbon?: boolean;
}

const useRibbonData = ({ hideRibbon }: RibbonDataProps) => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  // const { isRibbonDataLoading, ribbonDataError, ribbonData } = useAppSelector(
  //   (state) => state.dashboard
  // );
  const { data } = useAppSelector(
    (state) => state.bootCamp
  );

  const { headerRibbon : ribbonData } = data
  const showRibbon = !data?.headerRibbon ? false : true
    // !hideRibbon && !isRibbonDataLoading && !ribbonDataError && ribbonData;

  const pathname = router?.asPath?.split("?")?.[0];

  useEffect(() => {
    if (!hideRibbon) {
      dispatch(dashboardActions.getRibbonData({ pathname }));
    }
  }, [dispatch, pathname, hideRibbon]);

  return {
    showRibbon,
    ribbonData
  };
};

export default useRibbonData;
