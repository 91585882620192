import {
  configureStore,
  AnyAction,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import { createWrapper, HYDRATE } from "next-redux-wrapper";

import CombinedReducer from "./CombinedReducer";

const reducer = (
  state: ReturnType<typeof CombinedReducer>,
  action: AnyAction
) => CombinedReducer(state, action);

let store: ReturnType<typeof configStore>;

export const makeStore = () => {
  store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  return store;
};

type Store = ReturnType<typeof makeStore>;

export type AppDispatch = Store["dispatch"];
export type RootState = ReturnType<Store["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const wrapper = createWrapper<Store>(
  makeStore
  // { debug: true }
);

export default store;
