import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";
import { DEFAULT_API_ERROR_MESSAGE } from "@/utils/Constants";

let initialState = {
  isLoading: true,
  error: "",
  success: false,
  trialUserData: {},
};

const trialClassSlice = createSlice({
  name: "trialClass",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.trialClass,
      }))
      // GET HEALTH SURVEY DATA
      .addCase(actions.validateTrialClass.pending, (state) => ({
        ...state,
        isLoading: true,
        error: "",
        success: null,
        trialUserData: {},
      }))
      .addCase(actions.validateTrialClass.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          error: "",
          success: true,
          trialUserData: payload.data,
        };
      })
      .addCase(actions.validateTrialClass.rejected, (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload?.error || DEFAULT_API_ERROR_MESSAGE,
        success: false,
        trialUserData: {},
      }));
  },
});

export { validateTrialClass } from "./Actions";

export default trialClassSlice.reducer;
