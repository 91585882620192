import { motion } from "framer-motion";
import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";
import SVGSprite from "@/components/Atoms/SVGSprite";
import { AnimateDirection } from "@/components/Molecules/ModalMotion";

export const ModalOverlay = styled(FlexDiv)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ zindex }) => zindex};
  background-color: ${({ overlayBgColor, overlayOpacity }) =>
    `rgba(${overlayBgColor},${overlayOpacity});`};
`;

export const ModalContainer = styled(motion.div)`
  max-width: ${({ width }) => width};
  width: 100%;
  position: fixed;
  border-radius: ${({ borderradius }) => borderradius};
  height: ${({ height }) => height};
  ${({ margin }) => (margin ? `margin: ${margin}` : "")};
  background-color: ${({ bgcol }) => bgcol};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  text-align: left;
  z-index: ${({ zindex }) => zindex};
  @media only screen and (max-width: 449px) {
    max-width: ${({ mobilewidth }) => mobilewidth};
  }
  ${({ showshadow, shadow }) =>
    showshadow &&
    `
    box-shadow: ${shadow};
  `};
  ${({ animatedirection }) =>
    animatedirection === AnimateDirection.BOTTOM
      ? `
            bottom:0;
            left: 50%;
        `
      : animatedirection === AnimateDirection.CENTER
      ? `
            right: 50%;
            top: 50%;
            @media only screen and (max-width: 439px){
              max-width: 95%;
            }
        `
      : animatedirection === AnimateDirection.LEFT
      ? `
            left: 0;
            top: 0;
      `
      : `
        right: 0;
        top: 0;
    `};
`;

export const CloseIcon = styled(SVGSprite)`
  position: absolute;
  top: ${({ icontopposition }) => icontopposition};
  padding: ${({ iconpadding }) => iconpadding};
  right: ${({ iconrightposition }) => iconrightposition};
  cursor: pointer;
`;

export const Body = styled(FlexDiv)`
  height: ${({ height }) => height};
  overflow: hidden auto;
  padding: ${({ bodyPadding }) => bodyPadding};
  flex-grow: 1;
  max-height: ${({ animatedirection }) =>
    animatedirection === AnimateDirection.CENTER
      ? "calc(100vh - 90px)"
      : "100%"};
`;
