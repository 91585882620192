import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

const initialState = {
  isLoading: true,
  error: "",
  success: false,
  data: {},
};

const membershipBenefitSlice = createSlice({
  name: "membershipBenefitData",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.membershipBenefitData,
      }))
      // GET MEMBERSHIP BENEFIT DATA
      .addCase(actions.getMembershipBenefitData.pending, (state) => ({
        ...state,
        isLoading: true,
        error: "",
        success: null,
      }))
      .addCase(
        actions.getMembershipBenefitData.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            error: "",
            success: true,
            data: payload?.data,
          };
        }
      )
      .addCase(
        actions.getMembershipBenefitData.rejected,
        (state, { payload }) => ({
          ...state,
          isLoading: false,
          error: payload || true,
        })
      );
  },
});
export { getMembershipBenefitData } from "./Actions";

export default membershipBenefitSlice.reducer;
