import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";
import Typography from "@/foundation/Typography/V2";

export const StyledRibbon = styled(FlexDiv)`
  z-index: 5;
  background-color: ${({ backgroundColor }) => backgroundColor};
  justify-content: center;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 1024px) {
    //TODO: Added only for shyftpass
    img {
      width: 41px !important;
      height: 18.72px !important;
    }
  }
`;

export const Text = styled(Typography)`
  text-align: center;
  color: ${({ color }) => color};
  span {
    color: #0000ee;
    font-family: var(--man-700);
    font-size: 14px;
    line-height: 19px;
    @media only screen and (max-width: 1024px) {
      font-size: 14px;
      line-height: 19px;
    }

    @media only screen and (max-width: 600px) {
      font-size: 11px;
      line-height: 18px;
    }
  }
  .icon {
    height: 24px;
  }
  @media only screen and (max-width: 1024px) {
    margin: 0 56px;
    .icon {
      height: 16px;
    }
  }
  @media only screen and (max-width: 480px) {
    margin: 0 12px;
    text-align: left;
    padding: 10px 0px;
    font-size: 12px;
    line-height: 18px;
  }
  `;