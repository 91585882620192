import { Button } from "@/components/Atoms";
import Typography from "@/foundation/Typography/V2";

import { classNameGenerator } from "@/utils";

import styles from "./SubmitButton.module.scss";

const SubmitButton: React.FC = ({ packDetail, ...rest }) => {
  const cls = classNameGenerator(styles);

  return (
    <div className={cls("submitButtonWrapper")}>
      {packDetail && (
        <Typography
          variant="span"
          className={cls("packDetail")}
          dangerouslySetInnerHTML={{ __html: packDetail }}
        />
      )}
      <Button
        variant="primary"
        className={cls("submitButton")}
        type="submit"
        {...rest}
      />
    </div>
  );
};

export default SubmitButton;
