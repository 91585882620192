import { useEffect, useState } from "react";

import ModalMotion, {
  AnimateDirection,
} from "@/components/Molecules/ModalMotion";

import CallbackForm from "./CallbackForm";
import CallbackSuccess from "./CallbackSuccess";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./CallbackModal.module.scss";

interface CallbackModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  title: string;
  subtitle: string;
}

const CallbackModal: React.FC<CallbackModalProps> = ({
  showModal,
  setShowModal,
  title,
  subtitle,
}) => {
  const cls = classNameGenerator(styles);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isFormSubmitted) {
      timer = setTimeout(() => {
        setIsFormSubmitted(false);
        setShowModal(false);
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isFormSubmitted, setShowModal]);

  return showModal ? (
    <ModalMotion
      overlayClosable
      onClose={() => setShowModal(false)}
      width="520px"
      animateDirection={AnimateDirection.CENTER}
      borderRadius="10px"
      showClose
      iconTopPosition="20px"
      iconRightPosition="20px"
      className={clsx(
        cls("callbackModal"),
        isFormSubmitted
          ? cls("callbackSuccessWrapper")
          : cls("callbackFormWrapper")
      )}
    >
      {isFormSubmitted ? (
        <CallbackSuccess />
      ) : (
        <CallbackForm
          title={title}
          subtitle={subtitle}
          setIsFormSubmitted={setIsFormSubmitted}
        />
      )}
    </ModalMotion>
  ) : (
    <></>
  );
};

export default CallbackModal;
