import SVGSprite from "@/components/Atoms/SVGSprite";
import Typography from "@/foundation/Typography/V2";

import { classNameGenerator } from "@/utils";

import styles from "./CallbackButton.module.scss";

interface CallbackButtonProps {
  onClick: () => void;
}

const CallbackButton: React.FC<CallbackButtonProps> = ({ onClick }) => {
  const cls = classNameGenerator(styles);

  return (
    <Typography
      onClick={onClick}
      variant="paragraph1"
      className={cls("requestCallback")}
    >
      Need more information?
      <Typography variant="span" className={cls("link")}>
        Request a callback
      </Typography>
      <SVGSprite icon="icon-arrow-right-callback" className={cls("icon")} />
    </Typography>
  );
};

export default CallbackButton;
