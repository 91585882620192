import { useCallback } from "react";
import Typography from "@/foundation/Typography/V2";

import { classNameGenerator } from "@/utils";

import { useAppDispatch, useAppSelector } from "@/hooks";
import * as actions from "@/redux/BootCampReducer";

import styles from "./TermsAndConditionStrip.module.scss";
import theme from "@/foundation/Theme";
import { Image } from "@/components/Atoms";
import TncModal from "../TncModal";

interface TncStripProps { }

const TncStrip: React.FC<TncStripProps> = () => {
    const dispatch = useAppDispatch();

    const cls = classNameGenerator(styles);

    const { data } = useAppSelector((state) => state.bootCamp);

    const setShowTncModal = useCallback(
        (value: any) => {
            dispatch(actions.setShowTncModal(value));
        },
        [dispatch]
    );

    if (!data?.termsAndCondition) {
        return <></>
    }

    return (
        <>
            {data?.termsAndCondition &&
                <div className={cls("tncWrapper")}>
                    <div className={cls("iconWrapper")}>
                        <Image src="/images/tnc-icon.svg" alt="tnc-icon" />
                    </div>
                    <div className={cls("tncContent")}>
                        <Typography color={theme.colorsV2.blueGray.shade_800} variant="paragraph4" className={cls("text")}
                            dangerouslySetInnerHTML={{ __html: "<strong>100%  money-back guarantee</strong> if you don’t see results." }}
                        />
                        <Typography color={theme.colorsV2.blueGray.shade_800} onClick={setShowTncModal} variant="paragraph4" className={cls("link")}
                            dangerouslySetInnerHTML={{ __html: "T&C apply" }}
                        />
                    </div>
                </div>
            }
            <TncModal
                title={data?.termsAndCondition?.title || ""}
                description={data?.termsAndCondition?.description || ""}
                points={data?.termsAndCondition?.points || []}
            />
        </>
    )
};

export default TncStrip;
