import useLockBodyScroll from "./useLockBodyScroll";
import useRazorPay from "./useRazorPay";
import useRegisterForm from "./useRegisterForm";
import useWindowDimensions from "./useWindowDimensions";
import useIntervalCall from "./useIntervalCall";
import useOnClickOutside from "./useOnClickOutside";
import usePromoCode from "./usePromoCode";

export * from "./useRedux/index";

export {
  useLockBodyScroll,
  useRazorPay,
  useRegisterForm,
  useWindowDimensions,
  useIntervalCall,
  useOnClickOutside,
  usePromoCode,
};
