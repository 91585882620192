import React, { useEffect, useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";
import Link from "next/link";

import { SVGSprite } from "@/components/Atoms";

import { clsx, generateQueryArguments } from "@/utils";
import menu from "@/constants/HeaderMenu";

import styles from "./NavMenu.module.scss";

const ChevronDownIcon = (props) => (
  <SVGSprite icon="icon-header-chevron-down" {...props} />
);

const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className={styles.accordionHeader}>
      <Accordion.Trigger
        className={clsx(styles.accordionTrigger, className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon className={styles.accordionChevron} aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  )
);

AccordionTrigger.displayName = "AccordionTrigger";

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={clsx(styles.accordionContent, className)}
      {...props}
      ref={forwardedRef}
    >
      <div className={styles.accordionContentText}>{children}</div>
    </Accordion.Content>
  )
);

AccordionContent.displayName = "AccordionContent";

interface NavLinkProps {
  id: string;
  link: string;
  query: { [key: string]: string };
  category: string;
  isExternal?: boolean;
  isSpecialLink?: boolean;
  icon?: string;
}

const NavLink: React.FC<NavLinkProps> = ({
  id,
  link,
  query,
  category,
  isExternal,
  isSpecialLink,
  icon,
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);
  const routeLink = link?.endsWith("/") ? link : `${link}/`;

  const href = isClient
    ? `${routeLink}${generateQueryArguments(query)}`
    : routeLink;

  const linkCategory = isSpecialLink ? (
    <p className={styles.specialLink}>
      <p>
        <SVGSprite icon={icon} height="24px" width="24px" />
        {category}
      </p>
      <SVGSprite
        icon="icon-header-chevron-right"
        height="16px"
        width="16px"
        fill="#F79009"
      />
    </p>
  ) : (
    category
  );
  return (
    <li className={styles.subNavigationMenuItem}>
      <div id={id}>
        {isExternal ? (
          <a
            className={clsx(
              styles.subNavigationMenuLink,
              isSpecialLink ? styles.isSpecialLinkWrapper : ""
            )}
            href={href}
          >
            {linkCategory}
          </a>
        ) : (
          <Link
            className={clsx(
              styles.subNavigationMenuLink,
              isSpecialLink ? styles.isSpecialLinkWrapper : ""
            )}
            href={href}
          >
            {linkCategory}
          </Link>
        )}
      </div>
    </li>
  );
};

const NavMenu = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Accordion.Root
      className={styles.accordionRoot}
      type="single"
      defaultValue="item-1"
      collapsible
    >
      {menu?.map(
        ({ id, link, query, category, subCategory, isExternal }, index) => {
          const routeLink = link?.endsWith("/") ? link : `${link}/`;

          const href = isClient
            ? `${routeLink}${generateQueryArguments(query)}`
            : routeLink;

          return subCategory?.length ? (
            <Accordion.Item
              key={index}
              className={styles.accordionItem}
              value={category}
            >
              <div id={id}>
                <AccordionTrigger>
                  {link ? (
                    <Link href={href} className={styles.accordionTriggerLink}>
                      {category}
                    </Link>
                  ) : (
                    <div className={styles.accordionTriggerLink}>
                      {category}
                    </div>
                  )}
                </AccordionTrigger>
              </div>
              <AccordionContent>
                <ul className={styles.subNavigationMenu}>
                  {subCategory?.map((cat, index) => (
                    <NavLink key={index} {...cat} />
                  ))}
                </ul>
              </AccordionContent>
            </Accordion.Item>
          ) : (
            <div key={index} className={styles.accordionItem} value={category}>
              <div className={styles.accordionHeader}>
                <div id={id}>
                  <div className={styles.accordionTrigger}>
                    {isExternal ? (
                      <a
                        key={index}
                        href={href}
                        className={styles.accordionTriggerLink}
                      >
                        {category}
                      </a>
                    ) : (
                      <Link
                        key={index}
                        href={href}
                        className={styles.accordionTriggerLink}
                      >
                        {category}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      )}
    </Accordion.Root>
  );
};

export default NavMenu;
