import Typography from "@/foundation/Typography/V2";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { classNameGenerator, clsx } from "@/utils";
import styles from "./Packages.module.scss"
import { useCallback, useState } from "react";
import * as actions from "@/redux/BootCampReducer";

const Packages = ({
    removePromoCode
}) => {
    const cls = classNameGenerator(styles);
    const dispatch = useAppDispatch();
    const [currentCardIdx, setCurrentCardIdx] = useState(0)
    const { data } = useAppSelector((state) => state.bootCamp);
    const { packages } = data?.allPackages

    const setShowBookSlotModal = useCallback(
        (value: any) => {
            dispatch(actions.setShowBookSlotModal(value));
        },
        [dispatch]
    );

    return (
        <div className={cls("packageCards")}>
            <Typography
                variant="heading1"
                className={cls("heading")}
                dangerouslySetInnerHTML={{
                    __html: "Select a plan"
                }}
            />
            <div className={cls("cardList")}>
                {packages.map((_val, index) => {
                    return (
                        <div
                            onClick={() => {
                                setCurrentCardIdx(index)
                                removePromoCode()
                                setShowBookSlotModal({
                                    packageData: packages[index],
                                    showSubtitle: true
                                })
                            }
                            }
                            className={clsx(cls("card"), currentCardIdx === index ? cls("active") : "")}
                        >
                            <div className={cls("priceSection")}>
                                {_val?.currencySymbol && _val?.price !== null && <Typography
                                    variant="paragraph1"
                                    className={cls("price")}
                                    dangerouslySetInnerHTML={{
                                        __html: `${_val?.currencySymbol}&nbsp;${_val?.price}`
                                    }}
                                />}
                               {_val?.currencySymbol && _val?.originalPrice !== null && <Typography
                                    variant="paragraph1"
                                    className={cls("strikeout")}
                                    dangerouslySetInnerHTML={{
                                        __html: `${_val?.currencySymbol}&nbsp;${_val?.originalPrice}`
                                    }}
                                />}
                            </div>
                            <Typography
                                variant="paragraph1"
                                className={cls("duration")}
                                dangerouslySetInnerHTML={{
                                    __html: _val?.duration
                                }}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Packages