import styled from "styled-components";
import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  system,
} from "styled-system";
import RouterLink from "next/link";

import {
  LayoutProps,
  PositionProps,
  BackgroundProps,
  BorderProps,
  ColorProps,
  SpaceProps,
  ShadowProps,
  FlexboxProps,
} from "styled-system";

interface Props //TODO: Use this in Future
  extends PositionProps,
    LayoutProps,
    BackgroundProps,
    BorderProps,
    ColorProps,
    SpaceProps,
    ShadowProps,
    FlexboxProps {
  children?: React.ReactNode;
}

import { getCustomScroll } from "../Scrollbar";

export const Box = styled.div<{ [key: string]: any }>`
  ${flexbox}
  ${layout}
  ${space}
  ${color}
  ${position}
  ${background}
  ${border}
  ${shadow}
  ${system({
    boxSizing: true,
    cursor: true,
  })}
  ${getCustomScroll({})};
`;

export const FlexDiv = styled(Box)<{ [key: string]: any }>`
  display: flex;   
`;

export const FlexCenter = styled(FlexDiv)<{ [key: string]: any }>`
  justify-content: center;
  align-items: center;
`;

export const SpaceBetween = styled(FlexDiv)`
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled(Box)`
  max-width: 1312px;
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
`;

export const FlexContainer = styled(Container)`
  display: flex;
`;

export const Table = styled.table`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;

export const TR = styled.tr<Props>`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;

export const TH = styled.th`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;

export const TD = styled.td`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;

export const TBody = styled.tbody`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;

export const Label = styled.label`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  margin-bottom:0;
`;

export const Header = styled.header`
  ${flexbox}
  ${layout}
  ${space}
  ${color}
  ${position}
  ${background}
  ${border}
  ${shadow}
  ${system({
    boxSizing: true,
    cursor: true,
  })}
${getCustomScroll({})};
`;

export const ALink = styled.a`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;

export const StyledRouterLink = styled(RouterLink)`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;

export const FlexSection = styled.section`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  display: flex;
  ${getCustomScroll({})};
`;

export const FlexContainerV2 = styled.div`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  display: flex;
  max-width: 1262px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  ${getCustomScroll({})};
`;

export const DesktopContainer = styled.div`
  @media (max-width: 768px) {
    display: none !important;
  }
`;
export const MobileContainer = styled.div`
  @media (min-width: 769px) {
    display: none !important;
  }
`;
