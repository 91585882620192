import { useEffect, useState } from "react";

import SVGSprite from "../SVGSprite";

import { clsx } from "@/utils";

import styles from "./style.module.scss";

function Accordion({ title, content, defaultOpen = false, isWHSPage }: any) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (defaultOpen) {
      setIsOpen(true);
    }
  }, [defaultOpen]);

  function handleAccordion() {
    setIsOpen(!isOpen);
  }

  return (
    <div
      className={clsx(styles.accordionWrapper, "accordion-wrapper")}
      onClick={handleAccordion}
    >
      <div className={`${styles.accordion} ${isOpen && styles.open}`}>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      {isWHSPage ? (
        <SVGSprite
          className={styles.iconWrapper}
          icon={isOpen ? "icon-accordion-close" : "icon-accordion-down"}
        />
      ) : (
        <div className={`${styles.icon} ${isOpen && styles.close}`}>
          <SVGSprite
            icon={isOpen ? "icon-close" : "icon-down"}
            height="24px"
            width="24px"
            fill={isOpen ? "#ffffff" : ""}
            stroke={isOpen ? "#ffffff" : ""}
          />
        </div>
      )}
    </div>
  );
}

export default Accordion;
