import { Fragment } from "react";
import { useFormContext } from "react-hook-form";

import NameInput from "@/components/Molecules/HookFormInputs/V1/NameInput";
import PhoneInput from "@/components/Molecules/HookFormInputs/V1/PhoneInput";
import EmailInput from "@/components/Molecules/HookFormInputs/V1/EmailInput";
import SelectInput from "@/components/Molecules/HookFormInputs/V1/SelectInput";
import RadioGroup from "@/components/Molecules/HookFormInputs/V1/RadioGroup";
import SelectHealthConditions from "@/components/Molecules/HookFormInputs/V1/SelectHealthConditions";
import CheckboxInput from "@/components/Molecules/HookFormInputs/V1/CheckboxInput";
import ToggleInput from "@/components/Molecules/HookFormInputs/V1/ToggleInput";
import PromoCodeInput from "@/components/Molecules/HookFormInputs/V1/PromoCodeInput";
import SubmitButton from "@/components/Molecules/HookFormInputs/V1/SubmitButton";
import InfoText from "@/components/Molecules/HookFormInputs/V1/InfoText";
import CallbackButton from "@/components/Molecules/HookFormInputs/V1/CallbackButton";
import ErrorUI from "@/components/Atoms/InputsV2/ErrorUI";

import YogaTrialSelectDate from "@/components/Molecules/HookFormInputs/V1/YogaTrialSelectDate";
import YogaTrialSelectSlot from "@/components/Molecules/HookFormInputs/V1/YogaTrialSelectSlot";
import CourseHours from "@/components/Molecules/HookFormInputs/V1/CourseHours";
import Packages from "@/components/Molecules/HookFormInputs/V1/Packages";

const renderComponent = (name: string, props: any) => {
  switch (name) {
    case "name":
      return <NameInput {...props} />;
    case "phone":
      return <PhoneInput {...props} />;
    case "email":
      return <EmailInput {...props} />;
    case "select":
      return <SelectInput {...props} />;
    case "radioGroup":
      return <RadioGroup {...props} />;
    case "healthCondition":
      return <SelectHealthConditions {...props} />;
    case "checkbox":
      return <CheckboxInput {...props} />;
    case "toggle":
    case "switch":
      return <ToggleInput {...props} />;
    case "promoCode":
      return <PromoCodeInput {...props} />;
    case "submit":
      return <SubmitButton {...props} />;
    case "info":
      return <InfoText {...props} />;
    case "callback":
      return <CallbackButton {...props} />;
    case "error":
      return <ErrorUI {...props} />;
    case "error":
      return <ErrorUI {...props} />;

    case "yogaTrialSelectDate":
      return <YogaTrialSelectDate {...props} />;
    case "yogaTrialSelectSlot":
      return <YogaTrialSelectSlot {...props} />;
    case "numberInput":
      return <CourseHours {...props} />
    case "packages":
      return <Packages {...props}/>
    default:
      return props.children;
  }
};

interface FormInputsProps {
  setFormData: any;
  formData: any;
  components: {
    name?: string;
    props?: any;
  }[];
}

const FormInputs = ({ components, setFormData, formData }: FormInputsProps) => {
  const { control } = useFormContext();

  return (
    <Fragment>
      {components?.map(({ name = "", props }, index) => (
        <Fragment key={index}>
          {renderComponent(name, {
            control,
            setFormData, 
            formData,
            ...props,
          })}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default FormInputs;
