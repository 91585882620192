import React, { Component } from "react";
import * as Sentry from "@sentry/react";
import { NextRouter } from "next/router";
import { BrowserTracing } from "@sentry/tracing";

import ErrorPageFallbackView from "./ErrorPageFallbackView";

import store from "@/redux/Store";
import { SENTRY_WEB_DSN } from "@/config";

interface ErrorBoundaryI {
  children: any;
  darkHeader?: boolean;
  hideLinks?: boolean;
  windowWidth?: number;
  headerBackgroundColor?: string;
  hideRibbon?: boolean;
  hideHeader?: boolean;
  history?: NextRouter;
  fromTopErrorBoundary?: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryI> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.initializeSentry =
      process.env.NODE_ENV !== "development" &&
      !Sentry.getCurrentHub().getClient();

    if (this.initializeSentry) {
      Sentry.init({
        dsn: SENTRY_WEB_DSN,
        environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
        integrations: [new BrowserTracing()],

        beforeSend: (event) => {
          if (!event.extra?.customError) return null;
          return event;
        },
      });
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const state = store?.getState();
    const API_ERROR = state?.authReducer?.error;
    Sentry.withScope((scope) => {
      scope.setLevel("fatal");
      scope.setExtras(errorInfo);
      scope.setExtra("customError", true);
      if (API_ERROR) {
        scope.setTag("INTERNAL_SERVER_ERROR", API_ERROR?.message);
      }
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPageFallbackView {...this.props} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
