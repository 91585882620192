import Head from "next/head";

export const renderMetaTags = (data: any) => {
  const { meta_title, meta_desc, meta_image, og_meta, noIndexNoFollow } =
    data || {};
  const metaTitle =
    meta_title || "Shyft - Health & Wellness Platform | Shyft Health";
  const metaDescription =
    meta_desc ||
    "Shyft provides personalized wellness solutions for specific health conditions like pregnancy care, chronic ailments, etc. through nutrition, yoga & meditation.";
  const metaImage =
    meta_image ||
    "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/shyft.jpg";
  const canonicalURL = og_meta?.canonical_url;

  return (
    <>
      <Head>
        {metaTitle && <title>{metaTitle}</title>}
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        <meta
          name="og:image"
          property="og:image"
          content={
            metaImage ||
            "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/shyft.jpg"
          }
          key="og:image"
        />

        <meta name="description" content={metaDescription} key="description" />
        <meta property="og:locale" content="en_US" key="og:locale" />
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:title" content={metaTitle} key="og:title" />
        <meta
          property="og:description"
          content={metaDescription}
          key="og:description"
        />
        <meta property="og:url" content={canonicalURL} key="og:url" />
        <meta property="og:site_name" content="Shyft" key="og:site_name" />
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        {canonicalURL && (
          <link rel="canonical" href={canonicalURL} key="canonical" />
        )}
        {/* TODO: provide data for noIndexNoFollow */}
        {noIndexNoFollow && <meta name="robots" content="noindex, nofollow" />}
      </Head>
    </>
  );
};

export const renderMetaData = ({
  siteName = "Shyft",
  siteURL = "https://www.betheshyft.com/",
  title = "Shyft - Health & Wellness Platform | Shyft Health",
  description = "Shyft provides personalized wellness solutions for specific health conditions like pregnancy care, chronic ailments, etc. through nutrition, yoga & meditation.",
  thumbnail = "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/shyft.jpg",
  h1Text = null,
  pageTitle = title,
  pageDescription = description,
}: any) => {
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {siteURL && <meta property="og:url" content={siteURL} />}
        {siteName && <meta property="og:site_name" content={siteName} />}
        <meta property="og:image" content={thumbnail} />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      {h1Text && <h1 style={{ display: "none" }}>{h1Text}</h1>}
    </>
  );
};
