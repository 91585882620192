import { DEFAULT_API_ERROR_MESSAGE } from "@/utils/Constants";
import { createSlice } from "@reduxjs/toolkit";

import * as actions from "./Actions";

import { captureFBEvent } from "@/utils";

const initialState = {
  isLeadLoading: false,
  leadSuccess: false,
  leadError: "",
  isPaymentLoading: false,
  paymentSuccess: false,
  paymentError: "",
  isPromoCodeLoading: false,
  promoCodeSuccess: false,
  promoCodeError: "",
  promoCodeData: {},
  isPricingLoading: false,
  pricingSuccess: false,
  pricingError: "",
  pricingData: {},
  isCampaignLeadLoading: false,
  campaignLeadSuccess: false,
  campaignLeadError: "",
};

const landingPageSlice = createSlice({
  name: "landingPage",
  initialState,
  reducers: {
    removePromoCode(state, action) {
      return {
        ...state,
        isPromoCodeLoading: false,
        promoCodeSuccess: false,
        promoCodeError: "",
        promoCodeData: {},
      };
    },
    resetNutritionCallbackValues: (state) => {
      return {
        ...state,
        isPaymentLoading: false,
        paymentError: "",
        paymentSuccess: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // POST LEAD DATA
      .addCase(actions.saveLead.pending, (state) => ({
        ...state,
        isLeadLoading: true,
        leadSuccess: false,
        leadError: "",
      }))
      .addCase(
        actions.saveLead.fulfilled,
        (state, { payload, meta: { arg } }) => {
          if (!payload?.status) {
            return {
              ...state,
              leadSuccess: true,
              isLeadLoading: false,
              leadError: payload?.message,
            };
          }

          if (payload?.fire_event && typeof window !== "undefined") {
            captureFBEvent(payload, arg);

            // ReactPixel.trackCustom('FB_Silently_Captured', {
            //   packageSlug: packageSlug,
            //   urls_arguments: urls_arguments,
            //   lead_id: payload.lead_id,
            // });
          }

          return {
            ...state,
            isLeadLoading: false,
            leadSuccess: true,
          };
        }
      )
      .addCase(actions.saveLead.rejected, (state, { payload }) => {
        return {
          ...state,
          isLeadLoading: false,
          leadError: payload,
        };
      })
      // POST PAYMENT DATA
      .addCase(actions.postPackageSave.pending, (state) => ({
        ...state,
        isPaymentLoading: true,
        paymentSuccess: false,
        paymentError: "",
      }))
      .addCase(
        actions.postPackageSave.fulfilled,
        (state, { payload, meta: { arg } }) => {
          const { name, package_slug, urls_arguments, is_payment_completed } =
            arg.data;
          const showSuccessPage =
            payload?.status ||
            (process.env.NEXT_PUBLIC_NODE_ENV === "testing" &&
              is_payment_completed);

          if (!showSuccessPage) {
            actions.setPaymentFailedError(name, payload.data);
            arg?.router?.push({
              pathname: process.env.NEXT_PUBLIC_LOCATION + "payments/failure",
            });
            return {
              ...state,
              isPaymentLoading: false,
              paymentError: payload?.message,
            };
          }

          arg?.router?.push({
            pathname:
              process.env.NEXT_PUBLIC_LOCATION +
              (arg?.paymentSuccessPath || "payments/success"),
            search: `?package_code=${package_slug}&leadid=${payload?.lead_id}${
              urls_arguments ? "&" + urls_arguments?.slice(1) : ""
            }`,
          });

          return {
            ...state,
            isPaymentLoading: false,
            paymentSuccess: true,
          };
        }
      )
      .addCase(actions.postPackageSave.rejected, (state, { payload, meta }) => {
        const { name } = meta?.arg || {};
        actions.setPaymentFailedError(name, payload);
        let errorMsg = DEFAULT_API_ERROR_MESSAGE;
        if (payload?.response?.data?.message)
          errorMsg = payload.response.data.message;

        return {
          ...state,
          isPaymentLoading: false,
          paymentError: errorMsg,
        };
      })
      // POST PROMOCODE DATA
      .addCase(actions.promoCodeCheckRequest.pending, (state) => ({
        ...state,
        isPromoCodeLoading: true,
        promoCodeSuccess: false,
        promoCodeError: "",
      }))
      .addCase(
        actions.promoCodeCheckRequest.fulfilled,
        (state, { payload, meta: { arg } }) => {
          if (!payload?.status) {
            return {
              ...state,
              isPromoCodeLoading: false,
              promoCodeError: payload?.message,
            };
          }
          // const { is_hero_package, new_price, original_price } = response?.data;
          // if (is_hero_package) {
          //   dispatch(
          //     updateLandingPageData(
          //       slugurl,
          //       is_hero_package,
          //       new_price,
          //       original_price
          //     )
          //   );
          // } else {
          //   dispatch(
          //     updateYogaData({
          //       slugurl,
          //       is_hero_package,
          //       new_price,
          //       original_price,
          //     })
          //   );
          // }

          return {
            ...state,
            isPromoCodeLoading: false,
            promoCodeSuccess: true,
            promoCodeData: payload,
          };
        }
      )
      .addCase(actions.promoCodeCheckRequest.rejected, (state, { payload }) => {
        let errorMsg = DEFAULT_API_ERROR_MESSAGE;
        if (payload?.response?.data?.message)
          errorMsg = payload.response.data.message;

        return {
          ...state,
          isPromoCodeLoading: false,
          promoCodeError: errorMsg,
        };
      })
      // GET PRICING DATA
      .addCase(actions.getPackagePricing.pending, (state) => ({
        ...state,
        isPricingLoading: true,
        pricingSuccess: false,
        pricingError: "",
      }))
      .addCase(
        actions.getPackagePricing.fulfilled,
        (state, { payload, meta: { arg } }) => {
          return {
            ...state,
            isPricingLoading: false,
            pricingSuccess: true,
            pricingData: payload?.data,
          };
        }
      )
      .addCase(actions.getPackagePricing.rejected, (state, { payload }) => {
        let errorMsg = DEFAULT_API_ERROR_MESSAGE;
        if (payload?.response?.data?.message)
          errorMsg = payload.response.data.message;

        return {
          ...state,
          isPricingLoading: false,
          pricingError: errorMsg,
        };
      })
      // POST CAMPAIGN LEAD
      .addCase(actions.postCampaignLead.pending, (state) => ({
        ...state,
        isCampaignLeadLoading: true,
        campaignLeadSuccess: false,
        campaignLeadError: "",
      }))
      .addCase(actions.postCampaignLead.fulfilled, (state) => {
        return {
          ...state,
          isCampaignLeadLoading: false,
          campaignLeadSuccess: true,
        };
      })
      .addCase(actions.postCampaignLead.rejected, (state, { payload }) => {
        let errorMsg = DEFAULT_API_ERROR_MESSAGE;
        if (payload?.response?.data?.message)
          errorMsg = payload.response.data.message;

        return {
          ...state,
          isCampaignLeadLoading: false,
          campaignLeadError: errorMsg,
        };
      });
  },
});

export {
  saveLead,
  postPackageSave,
  promoCodeCheckRequest,
  getPackagePricing,
  postCampaignLead,
} from "./Actions";

export const { removePromoCode, resetNutritionCallbackValues } =
  landingPageSlice.actions;

export default landingPageSlice.reducer;
