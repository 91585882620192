import { useEffect, useState } from "react";

const getTimeDifference = (timeInterval: any) => {
  const currentTime = new Date().getMinutes();
  let diff;
  if (currentTime % timeInterval === 0) {
    diff = timeInterval;
  } else {
    const roundTime =
      Number(Math.floor(currentTime / timeInterval)) * timeInterval +
      timeInterval;
    diff = Math.abs(roundTime - currentTime);
  }
  return diff;
};

const useIntervalCall = (status: any, callback: any, timeInterval = 5) => {
  const [time, setTime] = useState(getTimeDifference(timeInterval));

  useEffect(() => {
    let timeout: any;
    let interval: any;
    if (status) {
      if (time !== timeInterval) {
        timeout = setTimeout(() => {
          setTime(timeInterval);
          callback();
        }, time * 60000);
      } else {
        interval = setInterval(() => {
          callback();
        }, timeInterval * 60000);
      }
    }
    return () => {
      if (timeout) clearTimeout(timeout);
      if (interval) clearInterval(interval);
    };
  }, [status, callback, timeInterval, time]);
};

export default useIntervalCall;
