import { Control, Controller } from "react-hook-form";

import CountryCodeInput from "@/components/Atoms/InputsV2/CountryCodeInput";
import TextInput from "@/components/Atoms/InputsV2/TextInput";
import ErrorUI from "@/components/Atoms/InputsV2/ErrorUI";
import Typography from "@/foundation/Typography/V2";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./PhoneInput.module.scss";

interface PhoneInputProps {
  name: string;
  control: Control<any>;
  placeholder?: string;
  className?: string;
  fixedCountryCode?: string;
  onBlur?: (e: any) => void;
  setFormData?: any;
  formData?: any;
  removeAppliedPromoCode?: (e: any) => void
}

export const matchCountryRegex = (countryCode, number) => {
  if (countryCode === "+91") {
    return /^[6-9]\d{9}$/.test(number);
  } else if (countryCode === "+971") {
    return /^((5){1}([0-9]{8}))/.test(number);
  } else if (countryCode === "+1") {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      number
    );
  } else {
    return /^((6|8|9|1){1}([0-9]{7}))/.test(number);
  }
};

export const isValidMobile = (value) =>
  value && (value.match(/^[\d -]+$/) || !value) && value.length <= 14;

const PhoneInput: React.FC<PhoneInputProps> = ({
  name,
  control,
  placeholder = "Enter your phone number",
  onBlur = () => {},
  fixedCountryCode,
  setFormData = () => {},
  formData = {},
  removeAppliedPromoCode = () => {},
  ...rest
}) => {
  const cls = classNameGenerator(styles);

  const validatePhone = (e) => {
    const value = e.target.value;
    return value === "" || isValidMobile(value);
  };

  return (
    <div
      className={clsx(
        cls("phoneInput"),
        fixedCountryCode ? cls("fixedCountryCode") : ""
      )}
    >
      {fixedCountryCode ? (
        <Typography variant="paragraph1" className={cls("countryCode")}>
          {fixedCountryCode}
        </Typography>
      ) : (
        <Controller
          name={`${name}CountryCode`}
          control={control}
          shouldUnregister
          render={({ field: { value, onBlur: handleBlur, onChange, ref } }) => (
            <CountryCodeInput
              ref={ref}
              placeholder=""
              name={name}
              value={value || "+91"} // TODO: FIX THIS HACK
              onChange={onChange}
              onBlur={handleBlur}
              {...rest}
            />
          )}
        />
      )}
      <Controller
        name={name}
        control={control}
        shouldUnregister
        render={({
          field: { value, onBlur: handleBlur, onChange, ref },
          fieldState: { error },
        }) => (
          <>
            <TextInput
              ref={ref}
              placeholder={placeholder}
              name={name}
              value={value || formData?.mobile}
              onChange={(e) => {
                if (validatePhone(e)) {
                  onChange(e);
                  setFormData((prev) => {
                    return {...prev,[name]:e.target.value}
                  })
                  removeAppliedPromoCode(false)
                }
              }}
              onBlur={(e) => {
                handleBlur();
                onBlur(e);
                removeAppliedPromoCode(false)
              }}
              {...rest}
            />
            <ErrorUI error={error?.message} />
          </>
        )}
      />
    </div>
  );
};

export default PhoneInput;
